import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'store';

import { IntegrationAuthResponse, IntegrationAuthorizeResponse, IntegrationService } from 'interfaces/integrations';
import { actions as integrationActions, selectors as integrationSelectors } from 'store/integration';

export function useIntegrationAuth(service: IntegrationService): [IntegrationAuthResponse, boolean, (options?: Record<string, string>) => Promise<boolean>] {
  const dispatch = useDispatch();
  const authorizeData = useSelector(integrationSelectors.getAuthByService(service));
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const authorizedRef = useRef(authorizeData.authorized);
  authorizedRef.current = authorizeData.authorized;

  const authorize = useCallback(
    async (options?: Record<string, string>): Promise<boolean> => {
      if (!authorizedRef.current) {
        setIsAuthorizing(true);

        let authResponse = await dispatch(integrationActions.authorizeService({ service, options }));
        const auth = authResponse.payload as IntegrationAuthorizeResponse;

        if (!auth.authorized) {
          const childWindow = window.open(auth.authorizationUrl, `${service}_auth_popup`, '_blank');
          let intervalId: NodeJS.Timeout | undefined = undefined;

          await new Promise<boolean>(resolve => {
            intervalId = setInterval(() => {
              try {
                if (childWindow?.closed) {
                  clearInterval(intervalId);
                  resolve(true);
                }
              } catch (e) {
                // do nothing
              }
            }, 1000);
          });

          authResponse = await dispatch(integrationActions.authorizeService({ service, options }));
          setIsAuthorizing(false);
          return (authResponse.payload as IntegrationAuthorizeResponse).authorized;
        } else {
          return true;
        }
      }

      return true;
    },
    [service, dispatch]
  );

  return [authorizeData, isAuthorizing, authorize];
}
