import React from 'react';

// ESLint: 'props' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Library(props: $TSFixMe) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33332 4.99935H1.66666V16.666C1.66666 17.5827 2.41666 18.3327 3.33332 18.3327H15V16.666H3.33332V4.99935ZM16.6667 1.66602H6.66666C5.74999 1.66602 4.99999 2.41602 4.99999 3.33268V13.3327C4.99999 14.2493 5.74999 14.9993 6.66666 14.9993H16.6667C17.5833 14.9993 18.3333 14.2493 18.3333 13.3327V3.33268C18.3333 2.41602 17.5833 1.66602 16.6667 1.66602ZM16.6667 13.3327H6.66666V3.33268H16.6667V13.3327ZM8.33332 7.49935H15V9.16602H8.33332V7.49935ZM8.33332 9.99935H11.6667V11.666H8.33332V9.99935ZM8.33332 4.99935H15V6.66602H8.33332V4.99935Z"
        fill="#587393"
      />
    </svg>
  );
}
