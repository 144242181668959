import React from 'react';

export default function AlphabeticalOrder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 2.08167L12.5007 3.74885C12.9143 4.02456 13.4731 3.91281 13.7488 3.49923C14.0245 3.08566 13.9128 2.52687 13.4992 2.25116L10.5547 0.288136C10.2188 0.0642037 9.78117 0.0642015 9.44526 0.288136L6.50073 2.25116C6.08716 2.52687 5.9754 3.08566 6.25112 3.49923C6.52684 3.91281 7.08562 4.02456 7.4992 3.74885L9.99997 2.08167ZM2.59118 12.579C2.37455 13.2235 2.85405 13.8905 3.53401 13.8905C3.98036 13.8905 4.37206 13.5932 4.49206 13.1633L4.77776 12.1397H7.38921L7.67607 13.1641C7.79632 13.5936 8.18778 13.8905 8.63378 13.8905C9.31391 13.8905 9.79338 13.2231 9.5763 12.5785L7.58778 6.67435C7.44311 6.2448 7.06037 5.94466 6.61617 5.90081V5.89527H6.5033H6.13413H5.66023C5.16872 5.89527 4.73211 6.20913 4.57552 6.67502L2.59118 12.579ZM6.13413 7.65721H6.02886L5.18757 10.6714H6.97809L6.13413 7.65721ZM11.5512 12.924C11.5512 13.4604 11.9861 13.8953 12.5225 13.8953H17.0426C17.4942 13.8953 17.8603 13.5292 17.8603 13.0775C17.8603 12.6259 17.4942 12.2598 17.0426 12.2598H13.9961V12.1544L17.5487 7.44573C17.6754 7.27784 17.7439 7.07325 17.7439 6.86293C17.7439 6.3285 17.3107 5.89527 16.7762 5.89527H12.43C11.9783 5.89527 11.6122 6.26138 11.6122 6.71301C11.6122 7.16463 11.9783 7.53075 12.43 7.53075H15.3045V7.63608L11.7479 12.338C11.6203 12.5067 11.5512 12.7124 11.5512 12.924ZM7.4992 16.2512L9.99997 17.9183L12.5007 16.2512C12.9143 15.9754 13.4731 16.0872 13.7488 16.5008C14.0245 16.9143 13.9128 17.4731 13.4992 17.7488L10.5547 19.7119C10.2188 19.9358 9.78116 19.9358 9.44527 19.7119L6.50074 17.7488C6.08716 17.4731 5.9754 16.9143 6.25112 16.5008C6.52684 16.0872 7.08562 15.9754 7.4992 16.2512Z"
        fill="#587393"
      />
    </svg>
  );
}
