import React from 'react';

export default function InsideRight(props: $TSFixMe) {
  return (
    <svg {...props} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33325 7.00572C7.33325 7.24782 7.24333 7.45534 7.04964 7.63519L1.66797 12.9062C1.51579 13.0584 1.3221 13.1414 1.09383 13.1414C0.637289 13.1414 0.270671 12.7817 0.270671 12.3182C0.270671 12.0899 0.367513 11.8893 0.519694 11.7302L5.36873 7.00572L0.519694 2.28119C0.367513 2.12209 0.270671 1.91457 0.270671 1.69322C0.270671 1.22976 0.637289 0.870056 1.09383 0.870056C1.3221 0.870056 1.51579 0.953064 1.66797 1.10524L7.04964 6.36932C7.24333 6.55609 7.33325 6.76361 7.33325 7.00572Z"
        fill="#587393"
      />
    </svg>
  );
}
