import React from 'react';

export const CheckmarkIcon = ({ color = '#FFFFFF', ...props }: { color?: string; className?: string }) => (
  <svg width="12px" height="11px" viewBox="0 0 12 11" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.8528 10.25C5.24839 10.25 5.5592 10.0997 5.77395 9.78796L11.0353 1.88907C11.1878 1.66084 11.25 1.44375 11.25 1.24335C11.25 0.703402 10.8318 0.302612 10.2667 0.302612C9.8824 0.302612 9.6394 0.441775 9.40205 0.803599L4.83019 7.90648L2.51319 5.10652C2.29844 4.85046 2.06109 4.73913 1.73332 4.73913C1.16254 4.73913 0.75 5.13992 0.75 5.68544C0.75 5.93036 0.823466 6.14189 1.03821 6.38125L3.95425 9.83249C4.19726 10.1164 4.47982 10.25 4.8528 10.25Z"
      fill="inherit"
    />
  </svg>
);
