import React, { useCallback } from 'react';
import { useDispatch } from 'store';
import { twMerge } from 'tailwind-merge';

import { Spinner } from 'assets/images';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { actions as importActions } from 'store/import';

import { IntegrationTitles } from './constants';
import { IntegrationCard } from './elements/IntegrationCard';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';

interface IntegrationItemProps {
  service: IntegrationService;
  className?: string;
}

export function IntegrationItem({ service, className }: IntegrationItemProps): JSX.Element {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [authorizeData, isAuthorizing, authorize] = useIntegrationAuth(service);

  const { name } = IntegrationTitles[service];

  const handleViewFilesClick = useCallback(() => {
    dispatch(importActions.openImportDialog({ source: service }));
  }, [dispatch, service]);

  const handleAuthorize = useCallback(async () => {
    if (await authorize()) {
      handleViewFilesClick();
    }
  }, [authorize, handleViewFilesClick]);

  const isAuthorized = authorizeData.authorized;

  return (
    <>
      <IntegrationCard service={service} className={className} onClick={isAuthorized ? handleViewFilesClick : handleAuthorize}>
        {isAuthorized ? (
          <button
            className={twMerge(
              'flex justify-center flex-initial min-w-40 mt-auto',
              'py-[7px] px-1 rounded-[8px] transition-colors',
              'text-sm font-medium text-electric-400 hover:text-electric-hovered-400 active:text-electric-pressed-400',
              'bg-transparent border border-electric-400 hover:border-electric-hovered-400 active:border-electric-pressed-400'
            )}
            onClick={handleViewFilesClick}
          >
            {t('View Files')}
          </button>
        ) : (
          <button
            className={twMerge(
              'flex justify-center flex-initial min-w-40 mt-auto',
              'py-2 px-1 rounded-[8px] transition-colors',
              'text-sm text-glass-0 bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
            )}
            onClick={handleAuthorize}
          >
            {isAuthorizing ? (
              <Spinner width="20" fill="white" className="animate-spin" />
            ) : (
              `${t('Sign in with')} ${service === IntegrationService.GOOGLE_DRIVE ? name.split(' ')[0] : name}`
            )}
          </button>
        )}
      </IntegrationCard>
    </>
  );
}
