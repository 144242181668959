import React, { createContext, useEffect, useMemo, useRef } from 'react';

import useResizeObserver from 'hooks/useResizeObserver';

interface ViewportContextValue {
  width?: number;
  height?: number;
}
export const ViewportContext = createContext<ViewportContextValue>({});

interface InifiniteLoaderProps {
  className?: string;
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;

  children: JSX.Element | ((viewport: ViewportContextValue) => React.ReactNode);
}

export function InifiniteLoader({ hasMore, isLoading, onLoadMore, className, children }: InifiniteLoaderProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const bottomIndicatorRef = useRef<HTMLDivElement>(null);
  const { width, height } = useResizeObserver({ ref: rootRef });

  const viewport = useMemo(() => ({ width, height }), [width, height]);

  useEffect(() => {
    if (!hasMore || isLoading || !rootRef.current || !bottomIndicatorRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        entry.isIntersecting && onLoadMore();
      },
      {
        root: rootRef.current,
        rootMargin: '1300px 0px'
      }
    );
    observer.observe(bottomIndicatorRef.current);

    return () => observer.disconnect();
  }, [hasMore, isLoading, onLoadMore]);

  return (
    <div className={className} ref={rootRef}>
      {typeof children === 'function' ? children(viewport) : children}
      <div ref={bottomIndicatorRef} />
    </div>
  );
}
