import { CanceledError } from 'axios';
import { uniqBy } from 'lodash';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'store';

import { IntegrationFolder, IntegrationItemRecord, IntegrationService, SORT_BY_FIELD, SORT_ORDER } from 'interfaces/integrations';
import { selectors as integrationSelectors } from 'store/integration';

import { fetchFiles } from '../api';
import { ROOT_FOLDER_ID, SHARED_FOLDER } from '../constants';
import { filterFolders, filterItems, filterItemsByName, sortItems } from '../utils';

function getQueryFilter(folder: string | null, sortBy: SORT_BY_FIELD, sortOrder: SORT_ORDER, filter: string | undefined, search: string | undefined) {
  return {
    folder: search === undefined ? (folder ?? ROOT_FOLDER_ID) : undefined,
    filter: filter,
    sortBy,
    sortOrder,
    search
  };
}

interface UseItemsReturn {
  items: IntegrationItemRecord[];
  folders: IntegrationFolder[];

  isLoading: boolean;
  loadItems: () => Promise<void>;
  hasMore: boolean;
}

export function useItems(service: IntegrationService, folderId: string | null, search?: string): UseItemsReturn {
  const sortOrder = useSelector(integrationSelectors.getSortOrder);
  const sortBy = useSelector(integrationSelectors.getSortBy);
  const filter = useSelector(state => state.integration.filter);

  const [allItems, setAllItems] = useState<IntegrationItemRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const abortControllerRef = useRef(new AbortController());

  const queryFilters = useMemo(() => getQueryFilter(folderId, sortBy, sortOrder, filter, search), [folderId, sortBy, sortOrder, filter, search]);
  const [cursor, setCursor] = useState<string | null | undefined>(null);

  const queryFiltersRef = useRef(queryFilters);
  queryFiltersRef.current = queryFilters;

  const folders = useMemo(
    () => sortItems(filterFolders(allItems), queryFilters.sortBy, queryFilters.sortOrder),
    [allItems, queryFilters.sortBy, queryFilters.sortOrder]
  );

  const items = useMemo<IntegrationItemRecord[]>(() => {
    const filteredItems = sortItems(
      filterItemsByName(filterItems(allItems, queryFilters.folder, queryFilters.filter), search),
      queryFilters.sortBy,
      queryFilters.sortOrder
    );

    return search || (queryFilters.folder && queryFilters.folder !== ROOT_FOLDER_ID) || service !== IntegrationService.GOOGLE_DRIVE
      ? filteredItems
      : [SHARED_FOLDER as IntegrationItemRecord, ...filteredItems];
  }, [service, allItems, queryFilters.folder, queryFilters.sortBy, queryFilters.sortOrder, queryFilters.filter, search]);

  const allItemsLoadedCache = useMemo<Partial<Record<string, boolean>>>(() => ({}), []);

  const loadItems = useCallback(async () => {
    const queryFilters = queryFiltersRef.current;
    const { signal } = abortControllerRef.current;

    setIsLoading(true);

    try {
      const { files: nextItems, cursor: nextCursor } = await fetchFiles(service, { ...queryFilters, cursor, signal });

      if (queryFiltersRef.current === queryFilters) {
        setAllItems(items => {
          if (queryFilters.search !== undefined) return nextItems;
          return uniqBy([...items, ...nextItems.filter(item => item.directory === queryFilters.folder)], 'id');
        });
      }
      setIsLoading(false);

      if (queryFiltersRef.current === queryFilters && !nextCursor && !queryFilters.filter && queryFilters.folder) {
        allItemsLoadedCache[queryFilters.folder] = true;
      }

      if (queryFiltersRef.current === queryFilters) {
        setCursor(nextCursor);
      }
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        if (queryFilters.folder) {
          allItemsLoadedCache[queryFilters.folder] = true;
        }
        setCursor(undefined);
        requestAnimationFrame(() => setIsLoading(false));
      }
    }
  }, [service, cursor, allItemsLoadedCache]);

  useLayoutEffect(() => {
    setCursor(null);

    return () => {
      const latestQueryFilters = queryFiltersRef.current;
      // Sorting changed and not all items loaded yet
      const isSortingChanged = queryFilters.sortBy !== latestQueryFilters.sortBy || queryFilters.sortOrder !== latestQueryFilters.sortOrder;
      // Search query changed
      const isSearchChanged = queryFilters.search !== latestQueryFilters.search;

      if (!isSearchChanged && !isSortingChanged) return;

      if (isSearchChanged || (isSortingChanged && !allItemsLoadedCache[queryFilters.folder ?? ROOT_FOLDER_ID])) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        setCursor(null);
        setIsLoading(false);
        setAllItems([]);

        for (const key in allItemsLoadedCache) {
          delete allItemsLoadedCache[key];
        }
      }
    };
  }, [queryFilters, allItemsLoadedCache]);

  return {
    items,
    folders,

    isLoading,
    loadItems: loadItems,
    hasMore: (search === undefined || !!search) && !isLoading && cursor !== undefined && (!queryFilters.folder || !allItemsLoadedCache[queryFilters.folder])
  };
}
