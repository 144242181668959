import { useRouter } from 'next/router';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import FolderIcon from 'assets/icons/folder';
import SharedFolderIcon from 'assets/icons/folder-shared';
import { IIntegrationFolder } from 'interfaces/integrations';
import { fromNow } from 'utils/dates';

interface FolderRowProps {
  folder: IIntegrationFolder;
  onClick: (folderId: string) => void;
  className?: string;
}

export function FolderRow({ folder, onClick, className }: FolderRowProps) {
  const router = useRouter();

  return (
    <div
      className={twMerge(className, 'cursor-pointer transition-colors duration-75 hover:bg-glass-hovered-0 active:bg-glass-pressed-0')}
      onClick={() => onClick(folder.id)}
    >
      <div className="font-medium text-sm text-glass-700 px-2 py-3">
        <div className="flex items-center gap-2">
          <span className="flex-none shrink-0 translate-y-[-1px]">
            {folder.isShared ? <SharedFolderIcon className="w-5" /> : <FolderIcon className="w-5" />}
          </span>
          <span className="whitespace-nowrap text-sm text-glass-700 truncate">{folder.name}</span>
        </div>
      </div>
      <div className="text-sm text-glass-500 px-2 py-3">{folder.createdTime && fromNow(new Date(folder.createdTime), router.locale ?? '')}</div>
    </div>
  );
}
