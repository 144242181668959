import React from 'react';
const DeleteIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.333 7.5v8.333H6.666V7.5h6.667zm-1.25-5H7.916l-.833.833H4.166V5h11.667V3.333h-2.917l-.833-.833zM15 5.833H5v10c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
