import React from 'react';

export function LoadingRowSkeleton({ className }: { className?: string }) {
  return (
    <div className={className}>
      <div className="px-2 py-3">
        <div className="flex gap-2">
          <div className="w-5 h-5 rounded bg-gray-200 animate-pulse" />
          <div className="w-2/3 h-5 rounded bg-gray-200 animate-pulse" />
        </div>
      </div>
      <div className="px-2 py-3">
        <div className="h-5 rounded bg-gray-200 animate-pulse" />
      </div>
      <div className="px-2 py-3">
        <div className="h-5 rounded bg-gray-200 animate-pulse" />
      </div>
    </div>
  );
}
