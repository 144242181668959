import React from 'react';
export default function Report(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.521 15.8857C5.93115 15.8857 6.23877 15.6807 6.75146 15.2339L9.25635 13.022H13.6655C15.8701 13.022 17.1152 11.7476 17.1152 9.57227V3.88135C17.1152 1.70605 15.8701 0.431641 13.6655 0.431641H4.33447C2.12988 0.431641 0.884766 1.70605 0.884766 3.88135V9.57227C0.884766 11.7549 2.1665 13.022 4.2832 13.022H4.57617V14.8164C4.57617 15.4683 4.92041 15.8857 5.521 15.8857ZM5.87988 14.2451V12.2383C5.87988 11.7915 5.62354 11.5571 5.19873 11.5571H4.37842C3.00146 11.5571 2.34961 10.8613 2.34961 9.52832V3.92529C2.34961 2.59229 3.00146 1.89648 4.37842 1.89648H13.6216C14.9912 1.89648 15.6504 2.59229 15.6504 3.92529V9.52832C15.6504 10.8613 14.9912 11.5571 13.6216 11.5571H9.2124C8.729 11.5571 8.5166 11.6304 8.16504 11.9819L5.87988 14.2451ZM9.04395 8.10742C9.43945 8.10742 9.67383 7.88037 9.68848 7.45557L9.79834 3.93262C9.81299 3.50049 9.4834 3.19287 9.03662 3.19287C8.58252 3.19287 8.26758 3.49316 8.28223 3.92529L8.38477 7.45557C8.39941 7.88037 8.63379 8.10742 9.04395 8.10742ZM9.04395 10.5391C9.5127 10.5391 9.90088 10.1948 9.90088 9.7334C9.90088 9.26465 9.52002 8.92773 9.04395 8.92773C8.56787 8.92773 8.17969 9.27197 8.17969 9.7334C8.17969 10.1948 8.56787 10.5391 9.04395 10.5391Z"
        fill="#587393"
      />
    </svg>
  );
}
