import React from 'react';

export default function FilterIcon(props: $TSFixMe) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4167 3.33228C15.7813 3.33228 16.0677 3.46248 16.3021 3.69686C16.5365 3.95728 16.6667 4.24373 16.6667 4.58228C16.6667 4.92082 16.5365 5.20728 16.3021 5.46769L12.0834 9.68644V15.4416C12.0834 15.7802 11.9532 16.0666 11.7448 16.275C11.5365 16.5094 11.25 16.6396 10.9375 16.6656C10.625 16.7177 10.3386 16.6396 10.0782 16.4312L8.4115 15.2073C8.07296 14.9208 7.91671 14.5823 7.91671 14.1656V9.68644L3.69796 5.46769C3.43754 5.20728 3.33337 4.92082 3.33337 4.58228C3.33337 4.24373 3.43754 3.95728 3.67192 3.69686C3.90629 3.46248 4.21879 3.33228 4.58337 3.33228H15.4167ZM10.8334 9.16561L15.4167 4.58228H4.58337L9.16671 9.16561V14.1656L10.8334 15.4156V9.16561Z"
        fill="#587393"
      />
    </svg>
  );
}
