import React from 'react';

export default function TrashIcon(props: $TSFixMe) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667V7.5c0-.917-.75-1.667-1.667-1.667H6.667C5.75 5.833 5 6.583 5 7.5v8.333zm10-12.5h-2.083l-.592-.591a.84.84 0 00-.583-.242H8.258a.84.84 0 00-.583.242l-.592.591H5a.836.836 0 00-.833.834c0 .458.375.833.833.833h10a.836.836 0 00.833-.833.836.836 0 00-.833-.834z"
        fill="#587393"
      />
    </svg>
  );
}
