import React from 'react';

export default function AIStorySourceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="54" height="52" viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_877_2584)">
        <g opacity="0.6" filter="url(#filter0_f_877_2584)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.6514 35.5796C36.5702 34.9166 37.4162 34.1524 38.1758 33.3055C44.0742 32.911 48.342 27.6149 48.342 21.689C48.342 15.7632 44.0744 10.4672 38.1762 10.0724C35.409 6.98707 31.4957 5 27.091 5C22.6893 5 18.7784 6.98438 16.0114 10.0662C10.0613 10.4071 5.74512 15.7296 5.74512 21.689C5.74512 27.6485 10.0615 32.971 16.0118 33.3118C18.7787 36.3933 22.6895 38.3775 27.091 38.3775C29.7688 38.3775 32.265 37.6431 34.4325 36.374C33.2425 37.3936 32.4884 38.9075 32.4884 40.5974C32.4884 43.6678 34.9775 46.1569 38.0479 46.1569C41.1183 46.1569 43.6074 43.6678 43.6074 40.5974C43.6074 37.527 41.1183 35.038 38.0479 35.038C37.1898 35.038 36.377 35.2324 35.6514 35.5796ZM27.091 35.3775C30.9967 35.3775 34.4818 33.3952 36.7614 30.2948C36.9943 30.3175 37.23 30.3291 37.4682 30.3291C41.8168 30.3291 45.342 26.4608 45.342 21.689C45.342 16.9171 41.8168 13.0488 37.4682 13.0488C37.2301 13.0488 36.9944 13.0604 36.7617 13.0831C34.4821 9.98243 30.9969 8 27.091 8C23.1814 8 19.6931 9.98633 17.4136 13.0923C17.1523 13.0635 16.8872 13.0488 16.619 13.0488C12.2704 13.0488 8.74512 16.9171 8.74512 21.689C8.74512 26.4608 12.2704 30.3291 16.619 30.3291C16.8873 30.3291 17.1525 30.3144 17.4139 30.2856C19.6934 33.3913 23.1815 35.3775 27.091 35.3775ZM40.6074 40.5974C40.6074 42.011 39.4614 43.1569 38.0479 43.1569C36.6343 43.1569 35.4884 42.011 35.4884 40.5974C35.4884 39.1839 36.6343 38.038 38.0479 38.038C39.4614 38.038 40.6074 39.1839 40.6074 40.5974Z"
            fill="url(#paint0_radial_877_2584)"
          />
        </g>
        <path
          d="M21.7539 21.6888C24.6515 21.6888 27.0004 19.3399 27.0004 16.4424C27.0004 19.3399 29.3493 21.6888 32.2468 21.6888C29.3493 21.6888 27.0004 24.0378 27.0004 26.9353C27.0004 24.0378 24.6515 21.6888 21.7539 21.6888Z"
          fill="#587393"
          stroke="#587393"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.7614 30.2948L36.9072 28.8018C36.3808 28.7505 35.8662 28.9801 35.5529 29.4062L36.7614 30.2948ZM17.4139 30.2856L18.6231 29.3981C18.306 28.966 17.7826 28.736 17.2499 28.7946L17.4139 30.2856ZM17.4136 13.0923L17.2496 14.5833C17.7823 14.6419 18.3058 14.4118 18.6229 13.9798L17.4136 13.0923ZM36.7617 13.0831L35.5532 13.9716C35.8665 14.3978 36.381 14.6274 36.9074 14.576L36.7617 13.0831ZM27.091 36.8775C31.5273 36.8775 35.4409 34.623 37.9699 31.1833L35.5529 29.4062C33.5227 32.1674 30.4661 33.8775 27.091 33.8775V36.8775ZM16.2047 31.1731C18.7335 34.6186 22.6504 36.8775 27.091 36.8775V33.8775C23.7127 33.8775 20.6533 32.1641 18.6231 29.3981L16.2047 31.1731ZM16.619 31.8291C16.9422 31.8291 17.2622 31.8113 17.5779 31.7766L17.2499 28.7946C17.0428 28.8174 16.8323 28.8291 16.619 28.8291V31.8291ZM7.24512 21.689C7.24512 27.1551 11.3139 31.8291 16.619 31.8291V28.8291C13.2269 28.8291 10.2451 25.7665 10.2451 21.689H7.24512ZM16.619 11.5488C11.3139 11.5488 7.24512 16.2229 7.24512 21.689H10.2451C10.2451 17.6114 13.2269 14.5488 16.619 14.5488V11.5488ZM17.5776 11.6013C17.2619 11.5666 16.9421 11.5488 16.619 11.5488V14.5488C16.8323 14.5488 17.0426 14.5605 17.2496 14.5833L17.5776 11.6013ZM27.091 6.5C22.6502 6.5 18.7332 8.7591 16.2043 12.2048L18.6229 13.9798C20.6531 11.2136 23.7125 9.5 27.091 9.5V6.5ZM37.9703 12.1946C35.4412 8.75466 31.5275 6.5 27.091 6.5V9.5C30.4662 9.5 33.523 11.2102 35.5532 13.9716L37.9703 12.1946ZM36.9074 14.576C37.0918 14.558 37.2788 14.5488 37.4682 14.5488V11.5488C37.1813 11.5488 36.8971 11.5628 36.6161 11.5902L36.9074 14.576ZM37.4682 14.5488C40.8603 14.5488 43.842 17.6114 43.842 21.689H46.842C46.842 16.2229 42.7733 11.5488 37.4682 11.5488V14.5488ZM43.842 21.689C43.842 25.7665 40.8603 28.8291 37.4682 28.8291V31.8291C42.7733 31.8291 46.842 27.1551 46.842 21.689H43.842ZM37.4682 28.8291C37.2787 28.8291 37.0916 28.8199 36.9072 28.8018L36.6157 31.7877C36.8969 31.8151 37.1812 31.8291 37.4682 31.8291V28.8291Z"
          fill="#587393"
        />
        <path
          d="M38.0477 43.157C39.4613 43.157 40.6072 42.0111 40.6072 40.5976C40.6072 39.184 39.4613 38.0381 38.0477 38.0381C36.6342 38.0381 35.4883 39.184 35.4883 40.5976C35.4883 42.0111 36.6342 43.157 38.0477 43.157Z"
          stroke="#587393"
          strokeWidth="3"
        />
      </g>
      <defs>
        <filter id="filter0_f_877_2584" x="0.745117" y="0" width="52.5967" height="51.1567" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_877_2584" />
        </filter>
        <radialGradient
          id="paint0_radial_877_2584"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.4275 8.42974) rotate(40.882) scale(50.3867 50.1455)"
        >
          <stop stopColor="#FF00E5" />
          <stop offset="1" stopColor="#0094FF" />
        </radialGradient>
        <clipPath id="clip0_877_2584">
          <rect width="54" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
