import React from 'react';

export default function ListeningHeadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="54" height="56" viewBox="0 0 54 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.50996 9.14659C13.1943 6.36401 19.2366 3.15194 27.6437 3.15194C35.9852 3.15194 41.6636 6.3147 45.0545 9.0677C44.8308 9.1874 44.5849 9.33262 44.2911 9.50621C42.4842 10.5737 41.5807 11.1075 41.1262 11.8862C40.8019 12.4418 40.6386 13.0766 40.6545 13.7197C40.6768 14.6211 41.2106 15.5246 42.2781 17.3315L43.8334 19.9641C44.8479 21.6814 45.3552 22.5401 46.0832 22.9897C46.6806 23.3587 47.376 23.5376 48.0773 23.5027C48.9319 23.4602 49.7906 22.9529 51.5079 21.9384C52.4145 21.4028 52.8677 21.135 53.2893 20.5571C53.5614 20.1841 53.8724 19.3979 53.9292 18.9399C54.0171 18.2299 53.9142 17.8771 53.7084 17.1713C53.3812 16.049 52.8601 14.699 52.0302 13.2455C51.3043 11.974 50.5127 10.9678 49.7752 10.1888C49.7209 10.064 49.6453 9.94594 49.5482 9.83998C46.7182 6.75276 39.7075 0.630396 27.6437 0.630396C15.5976 0.630396 8.01251 6.7359 4.90438 9.79308C4.77873 9.91667 4.68446 10.0601 4.62165 10.2135C3.89077 10.989 3.10827 11.9874 2.38998 13.2455C1.56012 14.699 1.03901 16.049 0.711775 17.1713C0.505996 17.8771 0.403107 18.2299 0.491028 18.9399C0.547762 19.3979 0.85881 20.1841 1.13085 20.5571C1.55244 21.135 2.00573 21.4028 2.91231 21.9384C4.62962 22.9529 5.48827 23.4602 6.34288 23.5027C7.04418 23.5376 7.73956 23.3587 8.33698 22.9897C9.06499 22.5401 9.57226 21.6814 10.5868 19.9641L12.1421 17.3315C13.2096 15.5246 13.7434 14.6211 13.7657 13.7197C13.7816 13.0766 13.6183 12.4418 13.294 11.8862C12.8395 11.1075 11.936 10.5737 10.1291 9.50621L10.1291 9.5062C9.897 9.3691 9.69489 9.2497 9.50996 9.14659Z"
          fill="url(#paint0_radial_196_16113)"
        />
      </g>
      <circle cx="27.2037" cy="30.6402" r="24.6421" fill="url(#paint1_linear_196_16113)" />
      <path
        d="M13.7091 18.3192C14.7359 19.3459 18.1014 23.3063 27.2036 23.3063"
        stroke="#1E1A38"
        strokeWidth="2.83674"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6982 18.3192C39.6715 19.3459 36.3059 23.3063 27.2038 23.3063"
        stroke="#1E1A38"
        strokeWidth="2.83674"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="2.64022" cy="2.64022" r="2.64022" transform="matrix(-1 0 0 1 24.2703 10.9854)" fill="#1E1A38" />
      <circle cx="2.64022" cy="2.64022" r="2.64022" transform="matrix(-1 0 0 1 35.5216 10.9852)" fill="#1E1A38" />
      <defs>
        <radialGradient
          id="paint0_radial_196_16113"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.0845 2.62628) rotate(34.14) scale(56.6381 54.7829)"
        >
          <stop stopColor="#FF00E5" />
          <stop offset="0.923423" stopColor="#000AFF" />
        </radialGradient>
        <linearGradient id="paint1_linear_196_16113" x1="27.2037" y1="5.99814" x2="48.7656" y2="53.8155" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCE51" />
          <stop offset="1" stopColor="#FFAE35" />
        </linearGradient>
      </defs>
    </svg>
  );
}
