import React from 'react';
import { CgSpinner } from 'react-icons/cg';

interface Props {
  size?: number;
}

const Loading: React.FC<Props> = ({ size = 30 }) => {
  return <CgSpinner className="animate-spin" size={size} />;
};

export default Loading;
