import React from 'react';
export default function WebIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={20}
      >
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#6877DA"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#32ADE6"
        />
      </g>
      <path
        d="M7.996 8.333A4.165 4.165 0 003.833 12.5c0 2.3 1.863 4.166 4.163 4.166a4.169 4.169 0 004.17-4.166c0-2.3-1.866-4.167-4.17-4.167zm2.887 2.5H9.654a6.52 6.52 0 00-.575-1.483 3.346 3.346 0 011.804 1.483zM8 9.183c.346.5.617 1.054.796 1.65H7.204c.18-.596.45-1.15.796-1.65zm-3.225 4.15a3.434 3.434 0 01-.108-.833c0-.288.041-.567.108-.834h1.408a6.88 6.88 0 00-.058.834c0 .283.025.558.058.833H4.775zm.342.833h1.229c.133.521.325 1.021.575 1.484a3.328 3.328 0 01-1.804-1.484zm1.229-3.333h-1.23A3.328 3.328 0 016.922 9.35a6.52 6.52 0 00-.575 1.483zM8 15.816a5.869 5.869 0 01-.796-1.65h1.592c-.18.596-.45 1.15-.796 1.65zm.975-2.483h-1.95a6.13 6.13 0 01-.067-.833c0-.284.03-.563.067-.834h1.95c.037.271.067.55.067.834 0 .283-.03.558-.067.833zm.104 2.317a6.52 6.52 0 00.575-1.484h1.23a3.346 3.346 0 01-1.805 1.484zm.738-2.317c.033-.275.058-.55.058-.833 0-.284-.025-.559-.058-.834h1.408c.066.267.108.546.108.834 0 .287-.042.566-.108.833H9.817z"
        fill="#fff"
      />
      <path opacity={0.24} d="M9.667 0L15.5 5.833h-4.167c-.92 0-1.666-.746-1.666-1.666V0z" fill="#fff" />
    </svg>
  );
}
