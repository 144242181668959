import React from 'react';
const NewFolder: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.642 5l1.666 1.667h7.359V15H3.333V5h4.309zm.691-1.667h-5c-.916 0-1.658.75-1.658 1.667l-.008 10c0 .917.75 1.667 1.666 1.667h13.334c.916 0 1.666-.75 1.666-1.667V6.667c0-.917-.75-1.667-1.666-1.667H10L8.333 3.333z"
        fill="#587393"
      />
    </svg>
  );
};

export default NewFolder;
