import React from 'react';

export const VoiceoverIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="11.7681" y="4.21191" width="12.4629" height="21.7786" rx="6.23147" fill="url(#paint0_linear_15004_16932)" />
      <path
        d="M24.9285 16.4609V19.8062C24.9285 23.6328 21.8264 26.7349 17.9998 26.7349V26.7349C14.1731 26.7349 11.071 23.6328 11.071 19.8062V16.4609"
        stroke="#D10AFD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0914 30.7058C13.2316 30.0047 13.8472 29.5 14.5622 29.5H21.4367C22.1517 29.5 22.7673 30.0047 22.9075 30.7058L23.0468 31.4019C23.1086 31.7113 22.872 32 22.5565 32H13.4424C13.1269 32 12.8903 31.7113 12.9521 31.4019L13.0914 30.7058Z"
        fill="#D10AFD"
      />
      <path d="M17.9998 30.7505L17.9998 26.7354" stroke="#D10AFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.5596 6.80519C6.67298 6.38228 7.27306 6.38228 7.38645 6.80519C7.70316 7.98646 8.62584 8.90914 9.80712 9.22586C10.23 9.33924 10.23 9.93932 9.80712 10.0527C8.62584 10.3694 7.70316 11.2921 7.38645 12.4734C7.27306 12.8963 6.67298 12.8963 6.5596 12.4734C6.24288 11.2921 5.3202 10.3694 4.13893 10.0527C3.71602 9.93932 3.71602 9.33924 4.13893 9.22586C5.3202 8.90914 6.24288 7.98646 6.5596 6.80519Z"
        fill="#FFDD4F"
      />
      <path
        d="M28.2102 23.5264C28.3236 23.1035 28.9237 23.1035 29.0371 23.5264C29.3538 24.7077 30.2765 25.6303 31.4578 25.947C31.8807 26.0604 31.8807 26.6605 31.4578 26.7739C30.2765 27.0906 29.3538 28.0133 29.0371 29.1946C28.9237 29.6175 28.3236 29.6175 28.2102 29.1946C27.8935 28.0133 26.9708 27.0906 25.7896 26.7739C25.3667 26.6605 25.3667 26.0604 25.7896 25.947C26.9708 25.6303 27.8935 24.7077 28.2102 23.5264Z"
        fill="#FFDD4F"
      />
      <defs>
        <linearGradient id="paint0_linear_15004_16932" x1="13.1434" y1="-4.68677" x2="23.0401" y2="48.4315" gradientUnits="userSpaceOnUse">
          <stop offset="0.153596" stopColor="#D10AFD" />
          <stop offset="0.590858" stopColor="#6617E5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
