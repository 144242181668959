import React from 'react';

export default function ScanSourceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7406 11C11.7809 11 12.7469 10.461 13.2934 9.57581L14.1684 8.15824C14.2291 8.05988 14.3365 8 14.4521 8L25.5476 8C25.6632 8 25.7705 8.05988 25.8312 8.15824L26.7063 9.57581C27.2527 10.461 28.2188 11 29.2591 11L32 11C33.1046 11 34 11.8954 34 13L34 27.6246C34 28.6011 33.3316 29.3341 32.5321 29.4685C24.0223 30.8994 15.9777 30.8994 7.46786 29.4685C6.66844 29.3341 6 28.6011 6 27.6246L6 13C6 11.8954 6.89543 11 8 11L10.7406 11ZM29.2591 8L28.3841 6.58244C27.7769 5.59884 26.7035 5 25.5476 5H14.4521C13.2962 5 12.2228 5.59884 11.6156 6.58244L10.7406 8H8C5.23858 8 3 10.2386 3 13V27.6246C3 29.9823 4.64535 32.036 6.9704 32.427C15.8096 33.9132 24.1904 33.9132 33.0296 32.427C35.3546 32.036 37 29.9823 37 27.6246V13C37 10.2386 34.7614 8 32 8H29.2591ZM24.5 20C24.5 22.4853 22.4853 24.5 20 24.5C17.5147 24.5 15.5 22.4853 15.5 20C15.5 17.5147 17.5147 15.5 20 15.5C22.4853 15.5 24.5 17.5147 24.5 20ZM27.5 20C27.5 24.1421 24.1421 27.5 20 27.5C15.8579 27.5 12.5 24.1421 12.5 20C12.5 15.8579 15.8579 12.5 20 12.5C24.1421 12.5 27.5 15.8579 27.5 20Z"
        fill="#587393"
      />
    </svg>
  );
}
