import React from 'react';

export default function SortIcon(props: $TSFixMe) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2432_16187)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33337 4.83864C2.33337 4.41107 2.67999 4.06445 3.10756 4.06445H11.3656C11.7932 4.06445 12.1398 4.41107 12.1398 4.83864C12.1398 5.26622 11.7932 5.61283 11.3656 5.61283L3.10756 5.61283C2.67999 5.61283 2.33337 5.26622 2.33337 4.83864ZM11.8505 12.0331C12.1528 11.7308 12.643 11.7308 12.9453 12.0331L14.2044 13.2921V4.83864C14.2044 4.41107 14.551 4.06445 14.9786 4.06445C15.4061 4.06445 15.7527 4.41107 15.7527 4.83864V13.2921L17.0117 12.0331C17.3141 11.7308 17.8042 11.7308 18.1066 12.0331C18.4089 12.3354 18.4089 12.8256 18.1066 13.128L15.526 15.7086C15.2236 16.0109 14.7334 16.0109 14.4311 15.7086L11.8505 13.128C11.5481 12.8256 11.5481 12.3354 11.8505 12.0331ZM2.33338 9.99991C2.33338 9.57233 2.67999 9.22572 3.10757 9.22572L9.30108 9.22572C9.72865 9.22572 10.0753 9.57233 10.0753 9.99991C10.0753 10.4275 9.72865 10.7741 9.30108 10.7741L3.10757 10.7741C2.67999 10.7741 2.33338 10.4275 2.33338 9.99991ZM3.10757 14.387C2.67999 14.387 2.33338 14.7336 2.33338 15.1612C2.33338 15.5887 2.67999 15.9354 3.10757 15.9354H7.23658C7.66415 15.9354 8.01077 15.5887 8.01077 15.1612C8.01077 14.7336 7.66415 14.387 7.23658 14.387H3.10757Z"
          fill="#587393"
        />
      </g>
      <defs>
        <clipPath id="clip0_2432_16187">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
