import React from 'react';
const OpenOriginalIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.833 3.333H4.167C3.242 3.333 2.5 4.083 2.5 5v10c0 .916.742 1.666 1.667 1.666H7.5V15H4.167V6.666h11.666V15H12.5v1.666h3.333c.917 0 1.667-.75 1.667-1.666V5c0-.917-.742-1.667-1.667-1.667zm-5.833 5l-3.333 3.333h2.5v5h1.666v-5h2.5L10 8.333z"
        fill="#587393"
      />
    </svg>
  );
};

export default OpenOriginalIcon;
