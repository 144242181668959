export const getImportParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    service: searchParams.get('importService'),
    filename: searchParams.get('importFilename')
  };
};

export const cleanupImportParams = () => {
  const url = new URL(window.location.href);

  url.searchParams.delete('importService');
  url.searchParams.delete('importFilename');

  window.history.replaceState({}, '', url);
};
