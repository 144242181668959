import React from 'react';

export function LoadingCardSkeleton() {
  return (
    <div className="bg-glass-200 p-3 h-[156px] box-border flex flex-col gap-2 rounded-lg">
      <div className="flex justify-between w-full gap-1.5 shrink-0">
        <div className="h-5 w-full rounded bg-gray-200 animate-pulse" />
        <div className="h-5 w-5 rounded bg-gray-200 animate-pulse" />
      </div>
      <div className="bg-gray-200 animate-pulse rounded-sm h-full overflow-hidden"></div>
    </div>
  );
}
