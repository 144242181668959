import React from 'react';

export default function Diamond(props: $TSFixMe) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9776_28403)">
        <path
          id="diamond-background"
          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
          fill="#283750"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18.72C14.8159 18.72 18.72 14.8159 18.72 10C18.72 5.18408 14.8159 1.28 10 1.28C5.18408 1.28 1.28 5.18408 1.28 10C1.28 14.8159 5.18408 18.72 10 18.72ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="url(#paint0_linear_9776_28403)"
        />
        <path d="M6.45455 5.36011L7.04545 8.89695H3.5L6.45455 5.36011Z" fill="url(#paint1_linear_9776_28403)" />
        <path d="M13.544 5.36011L16.4986 8.89695H12.9531L13.544 5.36011Z" fill="url(#paint2_linear_9776_28403)" />
        <path d="M7.04688 8.89695H12.956L10.0014 5.36011L7.04688 8.89695Z" fill="url(#paint3_linear_9776_28403)" />
        <path d="M6.45312 5.36011H9.99858L7.04403 8.89695L6.45312 5.36011Z" fill="url(#paint4_linear_9776_28403)" />
        <path d="M10 5.36011H13.5455L12.9545 8.89695L10 5.36011Z" fill="url(#paint5_linear_9776_28403)" />
        <path d="M3.5 8.89697H7.04545L10 16.5601L3.5 8.89697Z" fill="url(#paint6_linear_9776_28403)" />
        <path d="M16.5 8.89697H12.9545L10 16.5601L16.5 8.89697Z" fill="url(#paint7_linear_9776_28403)" />
        <path d="M7.04688 8.89697H12.956L10.0014 16.5601L7.04688 8.89697Z" fill="url(#paint8_linear_9776_28403)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_9776_28403" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F18001" />
          <stop offset="1" stopColor="#FFB036" />
        </linearGradient>
        <linearGradient id="paint1_linear_9776_28403" x1="4.91227" y1="7.12853" x2="6.98188" y2="8.98448" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E26001" />
          <stop offset="1" stopColor="#FD9B00" />
        </linearGradient>
        <linearGradient id="paint2_linear_9776_28403" x1="15.0386" y1="7.12011" x2="12.9543" y2="8.89507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E26001" />
          <stop offset="1" stopColor="#FD9B00" />
        </linearGradient>
        <linearGradient id="paint3_linear_9776_28403" x1="10.0014" y1="5.36011" x2="10.0014" y2="8.89695" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDF5C9" />
          <stop offset="1" stopColor="#FDDE67" />
        </linearGradient>
        <linearGradient id="paint4_linear_9776_28403" x1="8.22585" y1="5.36011" x2="7.01975" y2="8.89874" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC8517" />
          <stop offset="1" stopColor="#FFCB0F" />
        </linearGradient>
        <linearGradient id="paint5_linear_9776_28403" x1="11.7727" y1="5.36011" x2="13.0053" y2="8.89827" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC8517" />
          <stop offset="1" stopColor="#FFCB0F" />
        </linearGradient>
        <linearGradient id="paint6_linear_9776_28403" x1="5.21364" y1="8.89697" x2="9.98325" y2="16.5705" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B02B01" />
          <stop offset="1" stopColor="#FFA100" />
        </linearGradient>
        <linearGradient id="paint7_linear_9776_28403" x1="14.8691" y1="8.89697" x2="10.0169" y2="16.5708" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B02B01" />
          <stop offset="1" stopColor="#FFA100" />
        </linearGradient>
        <linearGradient id="paint8_linear_9776_28403" x1="10.0014" y1="8.89697" x2="10.0014" y2="16.5601" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9519" />
          <stop offset="1" stopColor="#FFD058" />
        </linearGradient>
        <clipPath id="clip0_9776_28403">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
