import React from 'react';
export default function PdfFileIcon(props: $TSFixMe) {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="30" rx="2" fill="#CF0F15" />
      <path
        d="M4.89626 18V15.48H6.36326C7.63226 15.48 8.66726 14.958 8.66726 13.59C8.66726 12.258 7.67726 11.7 6.36326 11.7H3.71726V18H4.89626ZM4.89626 12.699H6.36326C7.13726 12.699 7.45226 13.041 7.45226 13.59C7.45226 14.139 7.11926 14.481 6.36326 14.481H4.89626V12.699ZM11.8846 18C14.0536 18 15.1966 16.758 15.1966 14.859C15.1966 12.807 13.9816 11.7 11.8576 11.7H9.62562V18H11.8846ZM10.8046 12.699H11.7946C13.2436 12.699 13.9906 13.5 13.9906 14.859C13.9906 16.317 13.1266 17.001 11.8036 17.001H10.8046V12.699ZM17.5216 18V15.354H20.5366V14.355H17.5216V12.699H20.7076V11.7H16.3426V18H17.5216Z"
        fill="white"
      />
    </svg>
  );
}
