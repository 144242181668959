import React from 'react';
const DownloadMp3: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9474_126414)">
        <path
          d="M5.85156 18.7344C4.23438 18.7344 3.42969 17.9219 3.42969 16.2891V4.4375C3.42969 2.8125 4.24219 1.98438 5.85156 1.98438H9.5625C10.4375 1.98438 10.9297 2.11719 11.5234 2.72656L15.8281 7.10938C16.4531 7.75 16.5703 8.1875 16.5703 9.19531V16.2891C16.5703 17.9141 15.7656 18.7344 14.1484 18.7344H5.85156ZM5.90625 17.4766H14.0859C14.8984 17.4766 15.3125 17.0469 15.3125 16.2656V9.25H10.8594C9.88281 9.25 9.39062 8.76562 9.39062 7.78125V3.24219H5.91406C5.10156 3.24219 4.6875 3.6875 4.6875 4.46094V16.2656C4.6875 17.0469 5.10156 17.4766 5.90625 17.4766ZM11 8.07031H15.0703L10.5703 3.48438V7.63281C10.5703 7.94531 10.6875 8.07031 11 8.07031Z"
          fill="#587393"
        />
        <rect x="1" y="10" width="12" height="6" rx="1" fill="#587393" />
        <path
          d="M2.04111 15V11.4771H3.06162L3.91367 13.8672H3.96006L4.80967 11.4771H5.82773V15H5.0416V12.7563H4.99033L4.18955 15H3.6793L2.87852 12.7563H2.82969V15H2.04111ZM6.26025 15V11.4771H7.77637C8.56494 11.4771 9.07275 11.9702 9.07275 12.7246V12.7295C9.07275 13.4839 8.56494 13.9673 7.77637 13.9673H7.15625V15H6.26025ZM7.56152 12.1631H7.15625V13.2935H7.56152C7.94238 13.2935 8.16699 13.0957 8.16699 12.7295V12.7246C8.16699 12.3584 7.94238 12.1631 7.56152 12.1631ZM10.6308 15.0879C9.79824 15.0879 9.24648 14.6484 9.19521 13.9819L9.19277 13.9502H10.0473L10.0521 13.9771C10.0814 14.2114 10.2963 14.3848 10.6308 14.3848C10.9628 14.3848 11.1703 14.2017 11.1703 13.9404V13.9355C11.1703 13.6523 10.9506 13.4961 10.56 13.4961H10.1791V12.8882H10.5551C10.8993 12.8882 11.1093 12.7246 11.1093 12.4756V12.4707C11.1093 12.2168 10.9286 12.0557 10.6259 12.0557C10.3158 12.0557 10.1156 12.2241 10.0912 12.4829L10.0888 12.5073H9.27578L9.27822 12.4731C9.32461 11.814 9.84219 11.3892 10.6259 11.3892C11.4291 11.3892 11.9418 11.7725 11.9418 12.3486V12.3535C11.9418 12.7954 11.6122 13.0762 11.2143 13.1567V13.1738C11.7416 13.2275 12.081 13.5205 12.081 13.9868V13.9917C12.081 14.6606 11.517 15.0879 10.6308 15.0879Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9474_126414">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadMp3;
