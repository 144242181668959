import React from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';

export const ContactSupportAlertBanner = () => {
  const { t } = useTranslation('common');

  return (
    <p className="bg-pageBackground px-12 py-2 text-center font-ABCDiatype text-xs font-medium text-glass-700">
      {t('Contact Support Banner')}{' '}
      <a className="underline" href="tel:+1-747-302-4454">
        (747) 302-4454
      </a>
      .
    </p>
  );
};
