import React from 'react';

// ESLint: 'props' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function OutlinedFolder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.6415 5.00065L9.30817 6.66732H16.6665V15.0007H3.33317V5.00065H7.6415ZM8.33317 3.33398H3.33317C2.4165 3.33398 1.67484 4.08398 1.67484 5.00065L1.6665 15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V6.66732C18.3332 5.75065 17.5832 5.00065 16.6665 5.00065H9.99984L8.33317 3.33398Z"
        fill="#587393"
      />
    </svg>
  );
}

export default OutlinedFolder;
