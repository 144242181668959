import React from 'react';
import { useMemo } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';

import { IRecord } from '../../../interfaces';
import MoveToModalView from './MoveToModalView';

interface GroupMoveToModalProps {
  folders: IRecord[];
  currentFolderId: string;
  filesToMove: number;
  foldersToMove: number;
  onCancel: () => void;
  onSubmit: (parentFolderId: string) => void;
}

export const GroupMoveToModal: React.FC<GroupMoveToModalProps> = props => {
  const { folders, currentFolderId, filesToMove, foldersToMove, onCancel, onSubmit } = props;
  const { t } = useTranslation('common');

  const options = folders.map((folder: IRecord) => ({
    id: folder.id,
    title: folder.title
  }));

  if (currentFolderId) {
    options.unshift({
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      id: null,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      title: t('My Library')
    });
  }

  const title = useMemo(() => {
    const files = t('files', { count: filesToMove });
    const folders = t('folders', { count: foldersToMove });

    if (filesToMove && foldersToMove) {
      return t('Move files and folders', { files, folders });
    }

    if (filesToMove) {
      return t('Move files', { files });
    }

    if (foldersToMove) {
      return t('Move folders', { folders });
    }

    return '';
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToMove, foldersToMove]);

  return <MoveToModalView title={title} options={options} onCancel={onCancel} onSubmit={onSubmit} />;
};

export default GroupMoveToModal;
