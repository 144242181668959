import React from 'react';
const DotsVerticalIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 6.66732C10.9167 6.66732 11.6667 5.91732 11.6667 5.00065C11.6667 4.08398 10.9167 3.33398 10 3.33398C9.08337 3.33398 8.33337 4.08398 8.33337 5.00065C8.33337 5.91732 9.08337 6.66732 10 6.66732ZM10 8.33398C9.08337 8.33398 8.33337 9.08398 8.33337 10.0007C8.33337 10.9173 9.08337 11.6673 10 11.6673C10.9167 11.6673 11.6667 10.9173 11.6667 10.0007C11.6667 9.08398 10.9167 8.33398 10 8.33398ZM10 13.334C9.08337 13.334 8.33337 14.084 8.33337 15.0007C8.33337 15.9173 9.08337 16.6673 10 16.6673C10.9167 16.6673 11.6667 15.9173 11.6667 15.0007C11.6667 14.084 10.9167 13.334 10 13.334Z"
        fill="#587393"
      />
    </svg>
  );
};

export default DotsVerticalIcon;
