import React from 'react';

export default function UpToDateTick(props: $TSFixMe) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.32995 13.2285L4.43828 10.3368C4.11328 10.0118 3.58828 10.0118 3.26328 10.3368C2.93828 10.6618 2.93828 11.1868 3.26328 11.5118L6.74661 14.9952C7.07162 15.3202 7.59661 15.3202 7.92162 14.9952L16.7383 6.17852C17.0633 5.85352 17.0633 5.32852 16.7383 5.00352C16.4133 4.67852 15.8883 4.67852 15.5633 5.00352L7.32995 13.2285Z"
        fill="#0FA51E"
      />
    </svg>
  );
}
