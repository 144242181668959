import React from 'react';

export default function NoResultsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90228 51.0485C8.04415 34.379 8.61508 26.0442 17.5606 17.1206C26.5062 8.19706 34.7787 7.65082 51.3238 6.55835C59.5955 6.01217 69.1594 5.6521 79.9998 5.6521C90.8401 5.6521 100.404 6.01217 108.676 6.55835C125.221 7.65082 133.493 8.19706 142.439 17.1206C151.384 26.0442 151.955 34.379 153.097 51.0486C153.669 59.3958 154.049 68.9783 154.049 79.7017C154.049 89.8061 153.712 98.8976 153.194 106.897C152.062 124.398 151.497 133.148 142.555 142.112C133.613 151.076 124.802 151.668 107.179 152.851C99.1007 153.394 89.9888 153.751 79.9998 153.751C70.0108 153.751 60.8989 153.394 52.8203 152.851C35.1976 151.668 26.3863 151.076 17.4446 142.112C8.50298 133.148 7.93706 124.398 6.80522 106.897C6.28786 98.8976 5.9502 89.8061 5.9502 79.7017C5.9502 68.9783 6.3305 59.3957 6.90228 51.0485Z"
        fill="#F5F4FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.2563 80.4255C88.9586 90.1456 106.325 88.5387 116.045 76.8365C125.765 65.1342 124.158 47.768 112.456 38.0479C100.754 28.3278 83.3875 29.9347 73.6674 41.6369C63.9473 53.3391 65.5541 70.7054 77.2563 80.4255ZM72.187 86.5286C87.2599 99.0484 109.628 96.9787 122.148 81.9058C134.668 66.833 132.598 44.4646 117.525 31.9448C102.452 19.425 80.0841 21.4946 67.5642 36.5675C55.0444 51.6404 57.1141 74.0087 72.187 86.5286Z"
        fill="url(#paint0_linear_15275_29419)"
      />
      <path
        d="M65.3572 85.3869C66.7828 83.808 69.1999 83.6346 70.8363 84.9938L73.968 87.5951C75.5946 88.9461 75.8756 91.3355 74.6068 93.027L49.4515 126.563C46.7376 130.182 41.548 130.792 38.0688 127.902C34.6095 125.029 34.2382 119.853 37.2518 116.515L65.3572 85.3869Z"
        fill="url(#paint1_linear_15275_29419)"
      />
      <path d="M83.397 47.7772L106.316 70.6961" stroke="#D4D3DB" strokeWidth="6.61157" strokeLinecap="round" />
      <path d="M106.316 47.7772L83.3969 70.6961" stroke="#D4D3DB" strokeWidth="6.61157" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_15275_29419" x1="24.0162" y1="-78.0705" x2="83.7197" y2="316.534" gradientUnits="userSpaceOnUse">
          <stop offset="0.294092" stopColor="#E4E2EA" />
          <stop offset="0.868705" stopColor="#706E80" />
        </linearGradient>
        <linearGradient id="paint1_linear_15275_29419" x1="50.9293" y1="81.1383" x2="64.8466" y2="135.655" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E5E3EB" />
          <stop offset="0.609912" stopColor="#C0BEC9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
