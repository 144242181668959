import { CanvasIntegrationData } from 'components/settings/Integrations/canvas';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';

import { IntegrationService } from 'interfaces/integrations';
import * as faro from 'lib/observability';
import { getIntegration } from 'lib/speechify';
import { actions as integrationActions } from 'store/integration';

interface UseCanvasDataReturn {
  canvasData: CanvasIntegrationData | null;
  setCanvasData: (data: CanvasIntegrationData | null) => void;
  isLoading: boolean;
}

export function useCanvasData({ cache } = { cache: false }): UseCanvasDataReturn {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const storedCanvasData = useSelector(state => state.integration[IntegrationService.CANVAS]);
  const [canvasData, setCanvasData] = useState<CanvasIntegrationData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const setCanvasDataHandler = useCallback(
    (data: CanvasIntegrationData | null) => {
      setCanvasData(data);
      dispatch(integrationActions.setCanvasData(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (cache && storedCanvasData !== undefined) return;
    if (!isLoading) return;

    const fetchCanvasCreds = async () => {
      if (!user?.uid) return;

      setIsLoading(true);

      try {
        const data = await getIntegration(user.uid, 'lms.canvas');
        setCanvasDataHandler((data as CanvasIntegrationData) ?? null);
      } catch (e) {
        faro.logError(new Error(`fetchCanvasCreds error: ${e}`));
      }
    };
    fetchCanvasCreds().then(() => {
      setIsLoading(false);
    });
  }, [user?.uid, cache, isLoading, canvasData, storedCanvasData, setCanvasDataHandler]);

  if (cache && storedCanvasData !== undefined) {
    return {
      canvasData: storedCanvasData ?? canvasData,
      setCanvasData: setCanvasDataHandler,
      isLoading: false
    };
  }

  return {
    canvasData: isLoading ? (storedCanvasData ?? canvasData) : canvasData,
    setCanvasData: setCanvasDataHandler,
    isLoading
  };
}
