import React from 'react';
export default function Arc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9622 34.2332L20.7328 26.303C17.8529 25.6917 14.9551 23.9109 13.3162 21.7444L9.37329 30.0307C11.5644 31.8886 14.195 33.3488 16.9622 34.2332Z"
        fill="#1A007F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6979 21.5068C30.8036 23.8277 28.173 25.5491 25.3584 26.2198L29.1172 34.1262C31.8606 33.2002 34.4258 31.7044 36.6407 29.7872L32.6979 21.5068Z"
        fill="#4E000A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37307 30.0308L7.40163 34.1739C6.39809 36.2811 7.15223 38.875 9.21868 39.9731C11.4098 41.1365 14.1057 40.2284 15.1627 38.0143L16.9619 34.2333C14.1835 33.3382 11.6058 31.9106 9.37307 30.0308Z"
        fill="#1A007F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5244 12.4609C40.2447 12.4051 39.9597 12.3766 39.6746 12.3766C37.6183 12.3766 35.8321 13.8409 35.4295 15.8561C35.0138 17.9336 34.04 19.8687 32.698 21.5129L36.6349 29.7992C40.269 26.6473 42.9531 22.3558 43.915 17.5537C44.3841 15.2032 42.864 12.9239 40.5244 12.4609Z"
        fill="#FF9396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3585 26.2199C24.5153 26.4217 23.6543 26.5286 22.7933 26.5286C22.1223 26.5286 21.4275 26.4514 20.7327 26.303C17.8528 25.6917 14.955 23.9109 13.3161 21.7444C12.9064 21.2042 12.5738 20.6403 12.3422 20.0646C11.4456 17.8505 8.9219 16.7821 6.707 17.6725C4.49209 18.5688 3.42324 21.0915 4.31395 23.3055C5.32936 25.8222 7.12266 28.1312 9.37319 30.0307C11.6041 31.9105 14.1795 33.3375 16.9561 34.2332C18.8622 34.8446 20.8396 35.1829 22.7873 35.1829C24.9488 35.1829 27.0805 34.8089 29.1114 34.1263L25.3585 26.2199Z"
        fill="#002DC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7133 34.156L36.6349 29.7873L32.698 21.5069L32.692 21.5129C32.692 21.5129 32.692 21.5069 32.698 21.5069L26.9737 9.46927C26.2575 7.96456 24.7332 7.00119 23.0664 7C21.3978 7 19.8777 7.96159 19.1592 9.46927L13.322 21.7444C14.9609 23.9109 17.8587 25.6916 20.7387 26.303L22.627 22.338C22.8051 21.964 23.3396 21.964 23.5177 22.338L25.3644 26.2199L29.1233 34.1263L30.97 38.0083C31.6855 39.516 33.2134 40.4805 34.8832 40.4776C35.2692 40.4776 35.6551 40.4241 36.0352 40.3173C38.642 39.5991 39.8771 36.6015 38.7133 34.156Z"
        fill="#FF536A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9622 34.2332L20.7328 26.303C17.8529 25.6917 14.9551 23.9109 13.3162 21.7444L9.37329 30.0307C11.5644 31.8886 14.195 33.3488 16.9622 34.2332Z"
        fill="#1A007F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6979 21.5068C30.8036 23.8277 28.173 25.5491 25.3584 26.2198L29.1172 34.1262C31.8606 33.2002 34.4258 31.7044 36.6407 29.7872L32.6979 21.5068Z"
        fill="#4E000A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37307 30.0308L7.40163 34.1739C6.39809 36.2811 7.15223 38.875 9.21868 39.9731C11.4098 41.1365 14.1057 40.2284 15.1627 38.0143L16.9619 34.2333C14.1835 33.3382 11.6058 31.9106 9.37307 30.0308Z"
        fill="#1A007F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5244 12.4609C40.2447 12.4051 39.9597 12.3766 39.6747 12.3766C37.6183 12.3766 35.8321 13.8409 35.4295 15.8561C35.0139 17.9336 34.04 19.8687 32.698 21.5129L36.6349 29.7992C40.269 26.6473 42.9531 22.3558 43.915 17.5537C44.3841 15.2032 42.864 12.9239 40.5244 12.4609Z"
        fill="#FF9396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3585 26.2199C24.5153 26.4217 23.6543 26.5286 22.7933 26.5286C22.1222 26.5286 21.4275 26.4514 20.7327 26.303C17.8528 25.6917 14.955 23.9109 13.3161 21.7444C12.9064 21.2042 12.5738 20.6403 12.3422 20.0646C11.4456 17.8505 8.9219 16.7821 6.707 17.6725C4.49209 18.5688 3.42324 21.0915 4.31395 23.3055C5.32936 25.8222 7.12266 28.1312 9.37319 30.0307C11.6041 31.9105 14.1795 33.3375 16.9561 34.2332C18.8622 34.8446 20.8396 35.1829 22.7873 35.1829C24.9488 35.1829 27.0805 34.8089 29.1114 34.1263L25.3585 26.2199Z"
        fill="#002DC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7133 34.156L36.6349 29.7873L32.698 21.5069L32.692 21.5129C32.692 21.5129 32.692 21.5069 32.698 21.5069L26.9737 9.46927C26.2575 7.96456 24.7332 7.00119 23.0664 7C21.3978 7 19.8777 7.96159 19.1592 9.46927L13.322 21.7444C14.9609 23.9109 17.8587 25.6916 20.7387 26.303L22.627 22.3379C22.8051 21.964 23.3396 21.964 23.5177 22.3379L25.3644 26.2199L29.1232 34.1263L30.97 38.0083C31.6855 39.516 33.2134 40.4805 34.8832 40.4776C35.2692 40.4776 35.6551 40.4241 36.0352 40.3173C38.642 39.5991 39.8771 36.6015 38.7133 34.156Z"
        fill="#FF536A"
      />
    </svg>
  );
}
