import React from 'react';
export default function TxtFileIcon(props: $TSFixMe) {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="30" rx="2" fill="#0457B9" />
      <path
        d="M6.72382 18V12.699H8.82082V11.7H3.43882V12.699H5.54482V18H6.72382ZM10.2724 18L11.6764 16.056C11.7754 15.912 11.9014 15.732 12.0004 15.561C12.0904 15.732 12.1984 15.903 12.2974 16.038L13.6834 18H15.1324L12.6574 14.697L14.8894 11.7H13.4944L12.2794 13.455C12.1894 13.572 12.0994 13.698 12.0184 13.851C11.9374 13.698 11.8474 13.572 11.7664 13.455L10.5244 11.7H9.09335L11.3524 14.715L8.87735 18H10.2724ZM18.4526 18V12.699H20.5496V11.7H15.1676V12.699H17.2736V18H18.4526Z"
        fill="white"
      />
    </svg>
  );
}
