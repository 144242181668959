import { IRecord, ItemActionType } from 'interfaces';
import { useRouter } from 'next/router';
import React from 'react';

import { fromNow } from 'utils/dates';

import ItemCard from './Card';
import CarouselSlider from './components/CarouselSlider';

interface RecentlyListenedProps {
  recentlyListenedItems: IRecord[];
  itemWidth?: string;
  isMobile: boolean;
  folderIds: string[];
  playbackSpeed: number;
  handleItemClick: (item: IRecord, actionType: ItemActionType, location?: string) => void;
}

export const RecentlyListened = ({ recentlyListenedItems, itemWidth, isMobile, folderIds, playbackSpeed, handleItemClick }: RecentlyListenedProps) => {
  const router = useRouter();

  const sliderItems = recentlyListenedItems.map((item: IRecord) => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    // ESLint: Unexpected any & Unexpected any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
    const timeAgo = (item.createdAt as any)?._seconds ? fromNow(new Date((item.createdAt as any)._seconds * 1000), router.locale) : '';

    return (
      <ItemCard
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        coverImagePath={item.coverImagePath}
        key={item.id}
        id={item.id}
        moveTargets={isMobile ? [] : folderIds}
        onAction={handleItemClick}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        progressPercent={item.progressPercent}
        preventDrag={true}
        recordType={item.recordType}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        sourceURL={item.sourceURL}
        status={item.status}
        timeAgo={timeAgo}
        onRecentlyListened
        title={item.title}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        wordCount={item.wordCount}
        wpm={(playbackSpeed || 1) * 200}
        width={itemWidth}
      />
    );
  });

  return (
    <div className="flex border-glass-300">
      <div className="w-full pt-5 pl-5">
        <CarouselSlider items={sliderItems} />
      </div>
    </div>
  );
};

export default RecentlyListened;
