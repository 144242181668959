import React from 'react';

export default function DocxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="56" viewBox="0 0 42 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.608594 5.16667C0.608594 2.92761 2.42371 1.1125 4.66276 1.1125H23.7298C24.805 1.1125 25.8362 1.53963 26.5965 2.29994L40.1962 15.8996C40.9565 16.6599 41.3836 17.6911 41.3836 18.7663V51.8333C41.3836 54.0724 39.5685 55.8875 37.3294 55.8875H4.66276C2.42371 55.8875 0.608594 54.0724 0.608594 51.8333V5.16667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M29.2001 49.4941H27.6367L29.7717 46.6008L27.8117 43.8008H29.4217L30.2034 44.9908C30.3201 45.1658 30.4251 45.3408 30.5417 45.5624C30.6584 45.3408 30.7751 45.1658 30.8917 44.9908L31.7084 43.8008H33.2717L31.2534 46.5541L33.3301 49.4941H31.7084L30.8217 48.1174C30.7284 47.9774 30.6001 47.7674 30.4834 47.5808C30.3784 47.7674 30.2617 47.9658 30.1567 48.1174L29.2001 49.4941Z"
        fill="#007AFF"
      />
      <path
        d="M24.9676 49.7033C23.1593 49.7033 22.0859 48.5366 22.0859 46.6466C22.0859 44.7449 23.3809 43.6133 25.0376 43.6133C26.6943 43.6133 27.5926 44.6166 27.7326 45.9466H26.2859C26.2393 45.3049 25.8426 44.7449 25.0376 44.7449C24.2093 44.7449 23.5326 45.4216 23.5326 46.6583C23.5326 47.7899 24.1276 48.5833 25.0026 48.5833C25.8659 48.5833 26.2743 47.9999 26.3443 47.3233H27.7909C27.5926 48.7699 26.6593 49.7033 24.9676 49.7033Z"
        fill="#007AFF"
      />
      <path
        d="M18.5885 49.7033C16.8735 49.7033 15.6719 48.5599 15.6719 46.6699C15.6719 44.8033 16.8852 43.6133 18.5885 43.6133C20.3035 43.6133 21.5169 44.8033 21.5169 46.6699C21.5169 48.5599 20.3035 49.7033 18.5885 49.7033ZM18.5885 48.5833C19.4752 48.5833 20.0702 47.8833 20.0702 46.6699C20.0702 45.4333 19.4635 44.7449 18.5885 44.7449C17.7252 44.7449 17.1185 45.4333 17.1185 46.6699C17.1185 47.8833 17.7019 48.5833 18.5885 48.5833Z"
        fill="#007AFF"
      />
      <path
        d="M11.629 49.7048C10.124 49.7048 9.02734 48.6781 9.02734 46.6715C9.02734 44.6298 10.3223 43.6148 11.6523 43.6148C12.539 43.6148 13.0873 44.0698 13.414 44.6181C13.3673 44.4081 13.3673 44.2331 13.3673 44.0581V41.3281H14.7907V49.4948H13.3673V49.2615C13.3673 49.0631 13.3673 48.9115 13.414 48.7131C13.1923 49.1331 12.679 49.7048 11.629 49.7048ZM11.9673 48.5848C12.819 48.5848 13.449 47.8031 13.449 46.6598C13.449 45.4931 12.819 44.7465 11.9673 44.7465C11.1157 44.7465 10.474 45.5281 10.474 46.6598C10.474 47.8381 11.1157 48.5848 11.9673 48.5848Z"
        fill="#007AFF"
      />
      <path
        d="M26.2727 12.1667V1.97871L40.5148 16.2208H30.3268C28.0878 16.2208 26.2727 14.4057 26.2727 12.1667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7773 21.6042C10.7773 20.8995 11.3487 20.3281 12.0535 20.3281H29.9229C30.6277 20.3281 31.199 20.8995 31.199 21.6042V23.1348C31.199 23.8396 30.6277 24.411 29.9229 24.411H12.0535C11.3487 24.411 10.7773 23.8396 10.7773 23.1348V21.6042ZM10.7773 27.7175C10.7773 27.0127 11.3487 26.4414 12.0535 26.4414H29.9229C30.6277 26.4414 31.199 27.0127 31.199 27.7175V29.2481C31.199 29.9529 30.6277 30.5242 29.9229 30.5242H12.0535C11.3487 30.5242 10.7773 29.9529 10.7773 29.2481V27.7175ZM12.0535 32.5781C11.3487 32.5781 10.7773 33.1495 10.7773 33.8542V35.3848C10.7773 36.0896 11.3487 36.661 12.0535 36.661H25.8385C26.5433 36.661 27.1147 36.0896 27.1147 35.3848V33.8542C27.1147 33.1495 26.5433 32.5781 25.8385 32.5781H12.0535Z"
        fill="#007AFF"
      />
    </svg>
  );
}
