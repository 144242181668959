import React from 'react';

export default function PdfIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="56" viewBox="0 0 42 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.6125 5.16667C0.6125 2.92761 2.42761 1.1125 4.66667 1.1125H23.7337C24.8089 1.1125 25.8401 1.53963 26.6004 2.29994L40.2001 15.8996C40.9604 16.6599 41.3875 17.6911 41.3875 18.7663V51.8333C41.3875 54.0724 39.5724 55.8875 37.3333 55.8875H4.66667C2.42761 55.8875 0.6125 54.0724 0.6125 51.8333V5.16667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M26.2766 12.1667V1.97871L40.5187 16.2208H30.3307C28.0917 16.2208 26.2766 14.4057 26.2766 12.1667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M32.1678 30.2991C30.4248 28.4352 25.6656 29.1946 24.5261 29.3326C22.8503 27.6757 21.7109 25.6737 21.3086 24.9835C21.9119 23.1195 22.314 21.2556 22.3811 19.2538C22.3811 17.5279 21.7109 15.6641 19.8338 15.6641C19.1636 15.6641 18.5602 16.0781 18.225 16.6304C17.4207 18.0803 17.7557 20.9797 19.0294 23.9481C18.2921 26.0881 17.6217 28.1591 15.7448 31.818C13.8009 32.6461 9.7119 34.5792 9.3767 36.6504C9.24267 37.2715 9.44371 37.893 9.91293 38.3761C10.3822 38.7904 10.9854 38.9974 11.5888 38.9974C14.0689 38.9974 16.4821 35.4769 18.1579 32.5081C19.5656 32.025 21.7777 31.3346 23.9898 30.9204C26.604 33.2675 28.8831 33.6126 30.0898 33.6126C31.6985 33.6126 32.3019 32.9223 32.5029 32.3009C32.8379 31.6108 32.6369 30.8513 32.1678 30.2991ZM30.4918 31.4728C30.4248 31.956 29.8217 32.4391 28.7491 32.163C27.4754 31.818 26.336 31.1966 25.3305 30.3681C26.2019 30.2299 28.1457 30.0229 29.5535 30.2991C30.0898 30.4371 30.626 30.7823 30.4918 31.4728ZM19.2976 17.2517C19.4316 17.0446 19.6328 16.9066 19.8338 16.9066C20.4371 16.9066 20.5711 17.6659 20.5711 18.2873C20.5041 19.7372 20.2359 21.1867 19.7668 22.5674C18.7612 19.806 18.9624 17.8729 19.2976 17.2517ZM19.1636 30.6443C19.6998 29.5398 20.4372 27.6067 20.7053 26.7783C21.3085 27.8136 22.314 29.0564 22.8503 29.6087C22.8503 29.6778 20.7723 30.0919 19.1636 30.6443ZM15.2086 33.4057C13.6669 36.0289 12.058 37.6857 11.1866 37.6857C11.0526 37.6857 10.9184 37.6167 10.7844 37.5477C10.5832 37.4095 10.5162 37.2025 10.5832 36.9264C10.7844 35.9599 12.5272 34.6482 15.2086 33.4057Z"
        fill="#FF3B30"
      />
      <path
        d="M28.3284 44.94V49.5017H26.9051V44.94H25.7734V43.8083H26.9051V43.155C26.9051 41.8017 27.6401 41.125 28.7368 41.125C29.0984 41.125 29.3551 41.1833 29.4951 41.23V42.35C29.4018 42.315 29.2618 42.2917 29.0984 42.2917C28.6668 42.2917 28.3284 42.5367 28.3284 43.0383V43.8083H29.5184V44.94H28.3284Z"
        fill="#FF3B30"
      />
      <path
        d="M21.9532 49.7087C20.4482 49.7087 19.3516 48.682 19.3516 46.6754C19.3516 44.6337 20.6466 43.6187 21.9766 43.6187C22.8632 43.6187 23.4116 44.0737 23.7382 44.622C23.6916 44.412 23.6916 44.237 23.6916 44.062V41.332H25.1149V49.4987H23.6916V49.2654C23.6916 49.067 23.6916 48.9154 23.7382 48.717C23.5166 49.137 23.0032 49.7087 21.9532 49.7087ZM22.2916 48.5887C23.1432 48.5887 23.7732 47.807 23.7732 46.6637C23.7732 45.497 23.1432 44.7504 22.2916 44.7504C21.4399 44.7504 20.7982 45.532 20.7982 46.6637C20.7982 47.842 21.4399 48.5887 22.2916 48.5887Z"
        fill="#FF3B30"
      />
      <path
        d="M14.4702 51.885H13.0469V43.8117H14.4702V44.0683C14.4702 44.2433 14.4585 44.395 14.4235 44.6283C14.7852 44.0217 15.3919 43.625 16.2902 43.625C17.7952 43.625 18.7869 44.885 18.7869 46.6933C18.7869 48.5367 17.6785 49.715 16.2319 49.715C15.2752 49.715 14.7852 49.3067 14.4235 48.7233C14.4585 48.9567 14.4702 49.1083 14.4702 49.2717V51.885ZM15.8819 48.595C16.7569 48.595 17.3402 47.8133 17.3402 46.67C17.3402 45.55 16.7919 44.7567 15.8819 44.7567C15.0302 44.7567 14.4119 45.4917 14.4119 46.67C14.4119 47.8133 15.0185 48.595 15.8819 48.595Z"
        fill="#FF3B30"
      />
    </svg>
  );
}
