import * as React from 'react';

function EpubIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={20}
      >
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#6877DA"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#34C759"
        />
        <path opacity={0.24} d="M9.667 0L15.5 5.833h-4.167c-.92 0-1.666-.746-1.666-1.666V0z" fill="#fff" />
        <path
          d="M7.227 10.61h2.86a.304.304 0 00.226-.096.32.32 0 00.1-.237.314.314 0 00-.1-.232.298.298 0 00-.226-.101h-2.86a.303.303 0 00-.23.101.321.321 0 00-.095.232c0 .093.032.172.095.237a.31.31 0 00.23.097zm0 1.242h2.174a.305.305 0 00.226-.097.341.341 0 00.095-.24.316.316 0 00-.095-.229.298.298 0 00-.226-.101H7.227a.303.303 0 00-.23.101.316.316 0 00-.095.228c0 .093.032.173.095.241a.31.31 0 00.23.097zM5.793 17c-.45 0-.79-.121-1.024-.363-.233-.24-.349-.59-.349-1.052V9.23c0-.467.116-.822.35-1.064.235-.245.579-.367 1.03-.367h4.39c.45 0 .792.122 1.028.367.235.242.353.597.353 1.064v4.573c0 .216-.036.387-.107.51a1.322 1.322 0 01-.28.338c-.172.166-.28.348-.324.545a.894.894 0 00.045.545.717.717 0 00.354.38.762.762 0 01.222.177.406.406 0 01.094.274c0 .121-.04.223-.119.304a.401.401 0 01-.3.123H5.793zm.152-.861h4.208a1.42 1.42 0 01-.177-.65 1.537 1.537 0 01.148-.689.772.772 0 01-.119.008H5.973c-.224 0-.4.064-.526.19a.642.642 0 00-.184.473c0 .2.057.36.172.482.115.124.285.185.51.185zm-.185-2.04a.263.263 0 00.259-.266v-5.02a.25.25 0 00-.078-.186.25.25 0 00-.366 0 .256.256 0 00-.074.186v5.02a.256.256 0 00.259.266z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default EpubIcon;
