import { IItemState, ItemActionType } from 'interfaces';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { RootState, useDispatch, useSelector } from 'store';
import { carryParams, isItemPage } from 'utils';

import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { actions as itemActions } from 'store/item';
import { actions as libraryActions } from 'store/library';
import { actions as onboardingActions } from 'store/onboarding';
import { logSegmentEvent } from 'utils/analytics';

import ConfirmModal from './ConfirmModal';

export const ConfirmItemActionModal: React.FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const { confirmItemAction, confirmItem, onboardingDemoDocId } = useSelector<IItemState>((state: RootState) => state.item);

  const handleCancel = () => {
    dispatch(itemActions.setConfirmItem(undefined));
  };

  const handleConfirm = () => {
    switch (confirmItemAction) {
      case ItemActionType.Archive:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        dispatch(libraryActions.archiveItem({ itemId: confirmItem.id }));
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (confirmItem.id === onboardingDemoDocId) {
          dispatch(onboardingActions.setReaderOnboardingCompleted(true));
        }
        if (isItemPage()) {
          navigate(carryParams('/'));
        }
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        logSegmentEvent('web_app_document_archived', { type: confirmItem.recordType.toLowerCase() });
        break;

      case ItemActionType.ArchiveFolder:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        dispatch(libraryActions.archiveItem({ itemId: confirmItem.id, isFolder: true }));
        logSegmentEvent('web_app_folder_archived', {});
        break;

      case ItemActionType.Delete:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        dispatch(libraryActions.deleteItem(confirmItem.id));
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (confirmItem.id === onboardingDemoDocId) {
          dispatch(onboardingActions.setReaderOnboardingCompleted(true));
        }
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        logSegmentEvent('web_app_document_deleted', { type: confirmItem.recordType.toLowerCase() });
        break;

      case ItemActionType.DeleteFolder:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        dispatch(libraryActions.deleteItem(confirmItem.id));
        logSegmentEvent('web_app_folder_deleted', {});
        break;
    }

    // redirect to root folder if current folder is deleted / archived
    switch (confirmItemAction) {
      case ItemActionType.ArchiveFolder:
      case ItemActionType.DeleteFolder:
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (confirmItem.id === router.query.folder?.toString()) {
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          navigate(carryParams('/', { folder: undefined }), undefined, { shallow: true });
        }
        break;
    }

    dispatch(itemActions.setConfirmItem(undefined));
  };

  const { title, description } = useMemo(() => {
    if (!confirmItemAction) {
      return {
        title: '',
        description: ''
      };
    }

    switch (confirmItemAction) {
      case ItemActionType.Archive:
      case ItemActionType.Delete:
        return {
          title: t('are_you_sure', { verb: t('delete') }),
          description: `${t('Delete')} ${t('Item')}`
        };

      case ItemActionType.ArchiveFolder:
      case ItemActionType.DeleteFolder:
        return {
          title: t('are_you_sure_folder', { verb: t('delete') }),
          description: `${t('Delete')} ${t('Folder')}`
        };

      default:
        return {
          title: t('Please Confirm'),
          description: t('are_you_sure', { verb: confirmItemAction })
        };
    }
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmItemAction]);

  if (confirmItem && confirmItemAction) {
    return <ConfirmModal id={confirmItem.id} title={title} description={description} onConfirm={handleConfirm} onCancel={handleCancel} />;
  } else return null;
};

export default ConfirmItemActionModal;
