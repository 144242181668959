import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

import { IntegrationFile, IntegrationService } from 'interfaces/integrations';
import { fromNow } from 'utils/dates';

import { useFileAction } from '../hooks/useFileAction';
import { FileIcon } from './FileIcon';
import { ImportButton } from './ImportButton';

interface FileRowProps {
  service: IntegrationService;
  item: IntegrationFile;
  className?: string;
}

export function FileRow({ service, item, className }: FileRowProps) {
  const router = useRouter();
  const [importState, handleClick] = useFileAction(service, item);

  const disabled = !item.importable;

  return (
    <div
      className={twMerge(
        className,
        'cursor-pointer transition-colors duration-75 hover:bg-glass-hovered-0 active:bg-glass-pressed-0',
        disabled && 'hover:bg-white active:bg-white cursor-default'
      )}
      onClick={handleClick}
    >
      <div className={twMerge('font-medium text-sm text-glass-700 px-2 py-3', disabled && 'text-glass-400')}>
        <div className="flex gap-2">
          <FileIcon mimeType={item.mimeType} className="-mt-px shrink-0 w-5 h-5" />
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">{item.name}</span>
        </div>
      </div>
      <div className={twMerge('text-sm text-glass-500 px-2 py-3', disabled && 'text-glass-400')}>
        {fromNow(new Date(item.createdTime), router.locale ?? '')}
      </div>
      <div className="px-2 py-3 text-center">
        <ImportButton isImporting={importState === 'importing'} isImported={importState === 'imported'} importable={item.importable} onClick={handleClick} />
      </div>
    </div>
  );
}
