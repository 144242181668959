import React from 'react';
export const QRCodeForGetApp = (props: $TSFixMe) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180px" height="180px" viewBox="0 0 1023 1023" fill="none" {...props}>
    <path d="M372 0H341V31H372V0Z" fill={'black'} />
    <path d="M403 0H372V31H403V0Z" fill={'black'} />
    <path d="M434 0H403V31H434V0Z" fill={'black'} />
    <path d="M465 0H434V31H465V0Z" fill={'black'} />
    <path d="M496 0H465V31H496V0Z" fill={'black'} />
    <path d="M558 0H527V31H558V0Z" fill={'black'} />
    <path d="M589 0H558V31H589V0Z" fill={'black'} />
    <path d="M620 0H589V31H620V0Z" fill={'black'} />
    <path d="M682 0H651V31H682V0Z" fill={'black'} />
    <path d="M713 0H682V31H713V0Z" fill={'black'} />
    <path d="M744 0H713V31H744V0Z" fill={'black'} />
    <path d="M310 31H279V62H310V31Z" fill={'black'} />
    <path d="M341 31H310V62H341V31Z" fill={'black'} />
    <path d="M403 31H372V62H403V31Z" fill={'black'} />
    <path d="M434 31H403V62H434V31Z" fill={'black'} />
    <path d="M465 31H434V62H465V31Z" fill={'black'} />
    <path d="M496 31H465V62H496V31Z" fill={'black'} />
    <path d="M589 31H558V62H589V31Z" fill={'black'} />
    <path d="M651 31H620V62H651V31Z" fill={'black'} />
    <path d="M744 31H713V62H744V31Z" fill={'black'} />
    <path d="M775 31H744V62H775V31Z" fill={'black'} />
    <path d="M403 62H372V93H403V62Z" fill={'black'} />
    <path d="M434 62H403V93H434V62Z" fill={'black'} />
    <path d="M496 62H465V93H496V62Z" fill={'black'} />
    <path d="M527 62H496V93H527V62Z" fill={'black'} />
    <path d="M589 62H558V93H589V62Z" fill={'black'} />
    <path d="M620 62H589V93H620V62Z" fill={'black'} />
    <path d="M651 62H620V93H651V62Z" fill={'black'} />
    <path d="M682 62H651V93H682V62Z" fill={'black'} />
    <path d="M713 62H682V93H713V62Z" fill={'black'} />
    <path d="M775 62H744V93H775V62Z" fill={'black'} />
    <path d="M403 93H372V124H403V93Z" fill={'black'} />
    <path d="M434 93H403V124H434V93Z" fill={'black'} />
    <path d="M465 93H434V124H465V93Z" fill={'black'} />
    <path d="M496 93H465V124H496V93Z" fill={'black'} />
    <path d="M527 93H496V124H527V93Z" fill={'black'} />
    <path d="M558 93H527V124H558V93Z" fill={'black'} />
    <path d="M589 93H558V124H589V93Z" fill={'black'} />
    <path d="M620 93H589V124H620V93Z" fill={'black'} />
    <path d="M651 93H620V124H651V93Z" fill={'black'} />
    <path d="M682 93H651V124H682V93Z" fill={'black'} />
    <path d="M713 93H682V124H713V93Z" fill={'black'} />
    <path d="M744 93H713V124H744V93Z" fill={'black'} />
    <path d="M341 124H310V155H341V124Z" fill={'black'} />
    <path d="M465 124H434V155H465V124Z" fill={'black'} />
    <path d="M589 124H558V155H589V124Z" fill={'black'} />
    <path d="M620 124H589V155H620V124Z" fill={'black'} />
    <path d="M651 124H620V155H651V124Z" fill={'black'} />
    <path d="M682 124H651V155H682V124Z" fill={'black'} />
    <path d="M775 124H744V155H775V124Z" fill={'black'} />
    <path d="M279 155H248V186H279V155Z" fill={'black'} />
    <path d="M372 155H341V186H372V155Z" fill={'black'} />
    <path d="M403 155H372V186H403V155Z" fill={'black'} />
    <path d="M434 155H403V186H434V155Z" fill={'black'} />
    <path d="M465 155H434V186H465V155Z" fill={'black'} />
    <path d="M496 155H465V186H496V155Z" fill={'black'} />
    <path d="M527 155H496V186H527V155Z" fill={'black'} />
    <path d="M589 155H558V186H589V155Z" fill={'black'} />
    <path d="M620 155H589V186H620V155Z" fill={'black'} />
    <path d="M682 155H651V186H682V155Z" fill={'black'} />
    <path d="M775 155H744V186H775V155Z" fill={'black'} />
    <path d="M279 186H248V217H279V186Z" fill={'black'} />
    <path d="M341 186H310V217H341V186Z" fill={'black'} />
    <path d="M403 186H372V217H403V186Z" fill={'black'} />
    <path d="M465 186H434V217H465V186Z" fill={'black'} />
    <path d="M527 186H496V217H527V186Z" fill={'black'} />
    <path d="M589 186H558V217H589V186Z" fill={'black'} />
    <path d="M651 186H620V217H651V186Z" fill={'black'} />
    <path d="M713 186H682V217H713V186Z" fill={'black'} />
    <path d="M775 186H744V217H775V186Z" fill={'black'} />
    <path d="M341 217H310V248H341V217Z" fill={'black'} />
    <path d="M496 217H465V248H496V217Z" fill={'black'} />
    <path d="M527 217H496V248H527V217Z" fill={'black'} />
    <path d="M558 217H527V248H558V217Z" fill={'black'} />
    <path d="M589 217H558V248H589V217Z" fill={'black'} />
    <path d="M682 217H651V248H682V217Z" fill={'black'} />
    <path d="M713 217H682V248H713V217Z" fill={'black'} />
    <path d="M744 217H713V248H744V217Z" fill={'black'} />
    <path d="M775 217H744V248H775V217Z" fill={'black'} />
    <path d="M31 248H0V279H31V248Z" fill={'black'} />
    <path d="M124 248H93V279H124V248Z" fill={'black'} />
    <path d="M186 248H155V279H186V248Z" fill={'black'} />
    <path d="M217 248H186V279H217V248Z" fill={'black'} />
    <path d="M279 248H248V279H279V248Z" fill={'black'} />
    <path d="M310 248H279V279H310V248Z" fill={'black'} />
    <path d="M403 248H372V279H403V248Z" fill={'black'} />
    <path d="M434 248H403V279H434V248Z" fill={'black'} />
    <path d="M465 248H434V279H465V248Z" fill={'black'} />
    <path d="M527 248H496V279H527V248Z" fill={'black'} />
    <path d="M589 248H558V279H589V248Z" fill={'black'} />
    <path d="M682 248H651V279H682V248Z" fill={'black'} />
    <path d="M744 248H713V279H744V248Z" fill={'black'} />
    <path d="M775 248H744V279H775V248Z" fill={'black'} />
    <path d="M806 248H775V279H806V248Z" fill={'black'} />
    <path d="M868 248H837V279H868V248Z" fill={'black'} />
    <path d="M62 279H31V310H62V279Z" fill={'black'} />
    <path d="M93 279H62V310H93V279Z" fill={'black'} />
    <path d="M341 279H310V310H341V279Z" fill={'black'} />
    <path d="M434 279H403V310H434V279Z" fill={'black'} />
    <path d="M465 279H434V310H465V279Z" fill={'black'} />
    <path d="M527 279H496V310H527V279Z" fill={'black'} />
    <path d="M558 279H527V310H558V279Z" fill={'black'} />
    <path d="M651 279H620V310H651V279Z" fill={'black'} />
    <path d="M682 279H651V310H682V279Z" fill={'black'} />
    <path d="M775 279H744V310H775V279Z" fill={'black'} />
    <path d="M806 279H775V310H806V279Z" fill={'black'} />
    <path d="M837 279H806V310H837V279Z" fill={'black'} />
    <path d="M868 279H837V310H868V279Z" fill={'black'} />
    <path d="M992 279H961V310H992V279Z" fill={'black'} />
    <path d="M1023 279H992V310H1023V279Z" fill={'black'} />
    <path d="M93 310H62V341H93V310Z" fill={'black'} />
    <path d="M124 310H93V341H124V310Z" fill={'black'} />
    <path d="M155 310H124V341H155V310Z" fill={'black'} />
    <path d="M186 310H155V341H186V310Z" fill={'black'} />
    <path d="M217 310H186V341H217V310Z" fill={'black'} />
    <path d="M248 310H217V341H248V310Z" fill={'black'} />
    <path d="M310 310H279V341H310V310Z" fill={'black'} />
    <path d="M341 310H310V341H341V310Z" fill={'black'} />
    <path d="M434 310H403V341H434V310Z" fill={'black'} />
    <path d="M465 310H434V341H465V310Z" fill={'black'} />
    <path d="M527 310H496V341H527V310Z" fill={'black'} />
    <path d="M589 310H558V341H589V310Z" fill={'black'} />
    <path d="M620 310H589V341H620V310Z" fill={'black'} />
    <path d="M868 310H837V341H868V310Z" fill={'black'} />
    <path d="M899 310H868V341H899V310Z" fill={'black'} />
    <path d="M961 310H930V341H961V310Z" fill={'black'} />
    <path d="M1023 310H992V341H1023V310Z" fill={'black'} />
    <path d="M31 341H0V372H31V341Z" fill={'black'} />
    <path d="M124 341H93V372H124V341Z" fill={'black'} />
    <path d="M186 341H155V372H186V341Z" fill={'black'} />
    <path d="M310 341H279V372H310V341Z" fill={'black'} />
    <path d="M372 341H341V372H372V341Z" fill={'black'} />
    <path d="M434 341H403V372H434V341Z" fill={'black'} />
    <path d="M527 341H496V372H527V341Z" fill={'black'} />
    <path d="M589 341H558V372H589V341Z" fill={'black'} />
    <path d="M620 341H589V372H620V341Z" fill={'black'} />
    <path d="M651 341H620V372H651V341Z" fill={'black'} />
    <path d="M682 341H651V372H682V341Z" fill={'black'} />
    <path d="M713 341H682V372H713V341Z" fill={'black'} />
    <path d="M775 341H744V372H775V341Z" fill={'black'} />
    <path d="M837 341H806V372H837V341Z" fill={'black'} />
    <path d="M899 341H868V372H899V341Z" fill={'black'} />
    <path d="M930 341H899V372H930V341Z" fill={'black'} />
    <path d="M1023 341H992V372H1023V341Z" fill={'black'} />
    <path d="M62 372H31V403H62V372Z" fill={'black'} />
    <path d="M124 372H93V403H124V372Z" fill={'black'} />
    <path d="M155 372H124V403H155V372Z" fill={'black'} />
    <path d="M217 372H186V403H217V372Z" fill={'black'} />
    <path d="M248 372H217V403H248V372Z" fill={'black'} />
    <path d="M310 372H279V403H310V372Z" fill={'black'} />
    <path d="M372 372H341V403H372V372Z" fill={'black'} />
    <path d="M403 372H372V403H403V372Z" fill={'black'} />
    <path d="M465 372H434V403H465V372Z" fill={'black'} />
    <path d="M496 372H465V403H496V372Z" fill={'black'} />
    <path d="M527 372H496V403H527V372Z" fill={'black'} />
    <path d="M589 372H558V403H589V372Z" fill={'black'} />
    <path d="M651 372H620V403H651V372Z" fill={'black'} />
    <path d="M713 372H682V403H713V372Z" fill={'black'} />
    <path d="M775 372H744V403H775V372Z" fill={'black'} />
    <path d="M806 372H775V403H806V372Z" fill={'black'} />
    <path d="M837 372H806V403H837V372Z" fill={'black'} />
    <path d="M930 372H899V403H930V372Z" fill={'black'} />
    <path d="M992 372H961V403H992V372Z" fill={'black'} />
    <path d="M31 403H0V434H31V403Z" fill={'black'} />
    <path d="M248 403H217V434H248V403Z" fill={'black'} />
    <path d="M310 403H279V434H310V403Z" fill={'black'} />
    <path d="M372 403H341V434H372V403Z" fill={'black'} />
    <path d="M434 403H403V434H434V403Z" fill={'black'} />
    <path d="M465 403H434V434H465V403Z" fill={'black'} />
    <path d="M496 403H465V434H496V403Z" fill={'black'} />
    <path d="M589 403H558V434H589V403Z" fill={'black'} />
    <path d="M651 403H620V434H651V403Z" fill={'black'} />
    <path d="M775 403H744V434H775V403Z" fill={'black'} />
    <path d="M837 403H806V434H837V403Z" fill={'black'} />
    <path d="M899 403H868V434H899V403Z" fill={'black'} />
    <path d="M992 403H961V434H992V403Z" fill={'black'} />
    <path d="M1023 403H992V434H1023V403Z" fill={'black'} />
    <path d="M93 434H62V465H93V434Z" fill={'black'} />
    <path d="M124 434H93V465H124V434Z" fill={'black'} />
    <path d="M186 434H155V465H186V434Z" fill={'black'} />
    <path d="M217 434H186V465H217V434Z" fill={'black'} />
    <path d="M279 434H248V465H279V434Z" fill={'black'} />
    <path d="M310 434H279V465H310V434Z" fill={'black'} />
    <path d="M372 434H341V465H372V434Z" fill={'black'} />
    <path d="M403 434H372V465H403V434Z" fill={'black'} />
    <path d="M496 434H465V465H496V434Z" fill={'black'} />
    <path d="M527 434H496V465H527V434Z" fill={'black'} />
    <path d="M620 434H589V465H620V434Z" fill={'black'} />
    <path d="M713 434H682V465H713V434Z" fill={'black'} />
    <path d="M744 434H713V465H744V434Z" fill={'black'} />
    <path d="M775 434H744V465H775V434Z" fill={'black'} />
    <path d="M806 434H775V465H806V434Z" fill={'black'} />
    <path d="M837 434H806V465H837V434Z" fill={'black'} />
    <path d="M868 434H837V465H868V434Z" fill={'black'} />
    <path d="M899 434H868V465H899V434Z" fill={'black'} />
    <path d="M961 434H930V465H961V434Z" fill={'black'} />
    <path d="M992 434H961V465H992V434Z" fill={'black'} />
    <path d="M31 465H0V496H31V465Z" fill={'black'} />
    <path d="M93 465H62V496H93V465Z" fill={'black'} />
    <path d="M124 465H93V496H124V465Z" fill={'black'} />
    <path d="M155 465H124V496H155V465Z" fill={'black'} />
    <path d="M248 465H217V496H248V465Z" fill={'black'} />
    <path d="M434 465H403V496H434V465Z" fill={'black'} />
    <path d="M527 465H496V496H527V465Z" fill={'black'} />
    <path d="M558 465H527V496H558V465Z" fill={'black'} />
    <path d="M651 465H620V496H651V465Z" fill={'black'} />
    <path d="M682 465H651V496H682V465Z" fill={'black'} />
    <path d="M713 465H682V496H713V465Z" fill={'black'} />
    <path d="M744 465H713V496H744V465Z" fill={'black'} />
    <path d="M775 465H744V496H775V465Z" fill={'black'} />
    <path d="M837 465H806V496H837V465Z" fill={'black'} />
    <path d="M899 465H868V496H899V465Z" fill={'black'} />
    <path d="M930 465H899V496H930V465Z" fill={'black'} />
    <path d="M992 465H961V496H992V465Z" fill={'black'} />
    <path d="M62 496H31V527H62V496Z" fill={'black'} />
    <path d="M93 496H62V527H93V496Z" fill={'black'} />
    <path d="M155 496H124V527H155V496Z" fill={'black'} />
    <path d="M186 496H155V527H186V496Z" fill={'black'} />
    <path d="M217 496H186V527H217V496Z" fill={'black'} />
    <path d="M248 496H217V527H248V496Z" fill={'black'} />
    <path d="M279 496H248V527H279V496Z" fill={'black'} />
    <path d="M341 496H310V527H341V496Z" fill={'black'} />
    <path d="M434 496H403V527H434V496Z" fill={'black'} />
    <path d="M465 496H434V527H465V496Z" fill={'black'} />
    <path d="M496 496H465V527H496V496Z" fill={'black'} />
    <path d="M558 496H527V527H558V496Z" fill={'black'} />
    <path d="M651 496H620V527H651V496Z" fill={'black'} />
    <path d="M682 496H651V527H682V496Z" fill={'black'} />
    <path d="M713 496H682V527H713V496Z" fill={'black'} />
    <path d="M744 496H713V527H744V496Z" fill={'black'} />
    <path d="M775 496H744V527H775V496Z" fill={'black'} />
    <path d="M837 496H806V527H837V496Z" fill={'black'} />
    <path d="M899 496H868V527H899V496Z" fill={'black'} />
    <path d="M992 496H961V527H992V496Z" fill={'black'} />
    <path d="M31 527H0V558H31V527Z" fill={'black'} />
    <path d="M62 527H31V558H62V527Z" fill={'black'} />
    <path d="M248 527H217V558H248V527Z" fill={'black'} />
    <path d="M279 527H248V558H279V527Z" fill={'black'} />
    <path d="M341 527H310V558H341V527Z" fill={'black'} />
    <path d="M372 527H341V558H372V527Z" fill={'black'} />
    <path d="M434 527H403V558H434V527Z" fill={'black'} />
    <path d="M558 527H527V558H558V527Z" fill={'black'} />
    <path d="M744 527H713V558H744V527Z" fill={'black'} />
    <path d="M775 527H744V558H775V527Z" fill={'black'} />
    <path d="M868 527H837V558H868V527Z" fill={'black'} />
    <path d="M930 527H899V558H930V527Z" fill={'black'} />
    <path d="M992 527H961V558H992V527Z" fill={'black'} />
    <path d="M217 558H186V589H217V558Z" fill={'black'} />
    <path d="M248 558H217V589H248V558Z" fill={'black'} />
    <path d="M279 558H248V589H279V558Z" fill={'black'} />
    <path d="M310 558H279V589H310V558Z" fill={'black'} />
    <path d="M341 558H310V589H341V558Z" fill={'black'} />
    <path d="M372 558H341V589H372V558Z" fill={'black'} />
    <path d="M403 558H372V589H403V558Z" fill={'black'} />
    <path d="M558 558H527V589H558V558Z" fill={'black'} />
    <path d="M651 558H620V589H651V558Z" fill={'black'} />
    <path d="M682 558H651V589H682V558Z" fill={'black'} />
    <path d="M806 558H775V589H806V558Z" fill={'black'} />
    <path d="M961 558H930V589H961V558Z" fill={'black'} />
    <path d="M992 558H961V589H992V558Z" fill={'black'} />
    <path d="M1023 558H992V589H1023V558Z" fill={'black'} />
    <path d="M62 589H31V620H62V589Z" fill={'black'} />
    <path d="M124 589H93V620H124V589Z" fill={'black'} />
    <path d="M155 589H124V620H155V589Z" fill={'black'} />
    <path d="M186 589H155V620H186V589Z" fill={'black'} />
    <path d="M248 589H217V620H248V589Z" fill={'black'} />
    <path d="M279 589H248V620H279V589Z" fill={'black'} />
    <path d="M310 589H279V620H310V589Z" fill={'black'} />
    <path d="M403 589H372V620H403V589Z" fill={'black'} />
    <path d="M465 589H434V620H465V589Z" fill={'black'} />
    <path d="M527 589H496V620H527V589Z" fill={'black'} />
    <path d="M713 589H682V620H713V589Z" fill={'black'} />
    <path d="M744 589H713V620H744V589Z" fill={'black'} />
    <path d="M775 589H744V620H775V589Z" fill={'black'} />
    <path d="M806 589H775V620H806V589Z" fill={'black'} />
    <path d="M837 589H806V620H837V589Z" fill={'black'} />
    <path d="M868 589H837V620H868V589Z" fill={'black'} />
    <path d="M992 589H961V620H992V589Z" fill={'black'} />
    <path d="M1023 589H992V620H1023V589Z" fill={'black'} />
    <path d="M62 620H31V651H62V620Z" fill={'black'} />
    <path d="M124 620H93V651H124V620Z" fill={'black'} />
    <path d="M155 620H124V651H155V620Z" fill={'black'} />
    <path d="M186 620H155V651H186V620Z" fill={'black'} />
    <path d="M217 620H186V651H217V620Z" fill={'black'} />
    <path d="M248 620H217V651H248V620Z" fill={'black'} />
    <path d="M341 620H310V651H341V620Z" fill={'black'} />
    <path d="M372 620H341V651H372V620Z" fill={'black'} />
    <path d="M434 620H403V651H434V620Z" fill={'black'} />
    <path d="M496 620H465V651H496V620Z" fill={'black'} />
    <path d="M558 620H527V651H558V620Z" fill={'black'} />
    <path d="M651 620H620V651H651V620Z" fill={'black'} />
    <path d="M744 620H713V651H744V620Z" fill={'black'} />
    <path d="M775 620H744V651H775V620Z" fill={'black'} />
    <path d="M806 620H775V651H806V620Z" fill={'black'} />
    <path d="M992 620H961V651H992V620Z" fill={'black'} />
    <path d="M1023 620H992V651H1023V620Z" fill={'black'} />
    <path d="M93 651H62V682H93V651Z" fill={'black'} />
    <path d="M341 651H310V682H341V651Z" fill={'black'} />
    <path d="M403 651H372V682H403V651Z" fill={'black'} />
    <path d="M434 651H403V682H434V651Z" fill={'black'} />
    <path d="M496 651H465V682H496V651Z" fill={'black'} />
    <path d="M620 651H589V682H620V651Z" fill={'black'} />
    <path d="M682 651H651V682H682V651Z" fill={'black'} />
    <path d="M775 651H744V682H775V651Z" fill={'black'} />
    <path d="M806 651H775V682H806V651Z" fill={'black'} />
    <path d="M837 651H806V682H837V651Z" fill={'black'} />
    <path d="M868 651H837V682H868V651Z" fill={'black'} />
    <path d="M961 651H930V682H961V651Z" fill={'black'} />
    <path d="M992 651H961V682H992V651Z" fill={'black'} />
    <path d="M1023 651H992V682H1023V651Z" fill={'black'} />
    <path d="M31 682H0V713H31V682Z" fill={'black'} />
    <path d="M62 682H31V713H62V682Z" fill={'black'} />
    <path d="M93 682H62V713H93V682Z" fill={'black'} />
    <path d="M217 682H186V713H217V682Z" fill={'black'} />
    <path d="M248 682H217V713H248V682Z" fill={'black'} />
    <path d="M310 682H279V713H310V682Z" fill={'black'} />
    <path d="M341 682H310V713H341V682Z" fill={'black'} />
    <path d="M372 682H341V713H372V682Z" fill={'black'} />
    <path d="M465 682H434V713H465V682Z" fill={'black'} />
    <path d="M527 682H496V713H527V682Z" fill={'black'} />
    <path d="M620 682H589V713H620V682Z" fill={'black'} />
    <path d="M682 682H651V713H682V682Z" fill={'black'} />
    <path d="M713 682H682V713H713V682Z" fill={'black'} />
    <path d="M806 682H775V713H806V682Z" fill={'black'} />
    <path d="M837 682H806V713H837V682Z" fill={'black'} />
    <path d="M868 682H837V713H868V682Z" fill={'black'} />
    <path d="M899 682H868V713H899V682Z" fill={'black'} />
    <path d="M930 682H899V713H930V682Z" fill={'black'} />
    <path d="M961 682H930V713H961V682Z" fill={'black'} />
    <path d="M1023 682H992V713H1023V682Z" fill={'black'} />
    <path d="M62 713H31V744H62V713Z" fill={'black'} />
    <path d="M93 713H62V744H93V713Z" fill={'black'} />
    <path d="M186 713H155V744H186V713Z" fill={'black'} />
    <path d="M310 713H279V744H310V713Z" fill={'black'} />
    <path d="M465 713H434V744H465V713Z" fill={'black'} />
    <path d="M496 713H465V744H496V713Z" fill={'black'} />
    <path d="M620 713H589V744H620V713Z" fill={'black'} />
    <path d="M651 713H620V744H651V713Z" fill={'black'} />
    <path d="M682 713H651V744H682V713Z" fill={'black'} />
    <path d="M899 713H868V744H899V713Z" fill={'black'} />
    <path d="M930 713H899V744H930V713Z" fill={'black'} />
    <path d="M992 713H961V744H992V713Z" fill={'black'} />
    <path d="M1023 713H992V744H1023V713Z" fill={'black'} />
    <path d="M31 744H0V775H31V744Z" fill={'black'} />
    <path d="M93 744H62V775H93V744Z" fill={'black'} />
    <path d="M124 744H93V775H124V744Z" fill={'black'} />
    <path d="M186 744H155V775H186V744Z" fill={'black'} />
    <path d="M217 744H186V775H217V744Z" fill={'black'} />
    <path d="M310 744H279V775H310V744Z" fill={'black'} />
    <path d="M403 744H372V775H403V744Z" fill={'black'} />
    <path d="M465 744H434V775H465V744Z" fill={'black'} />
    <path d="M527 744H496V775H527V744Z" fill={'black'} />
    <path d="M558 744H527V775H558V744Z" fill={'black'} />
    <path d="M682 744H651V775H682V744Z" fill={'black'} />
    <path d="M744 744H713V775H744V744Z" fill={'black'} />
    <path d="M775 744H744V775H775V744Z" fill={'black'} />
    <path d="M806 744H775V775H806V744Z" fill={'black'} />
    <path d="M837 744H806V775H837V744Z" fill={'black'} />
    <path d="M868 744H837V775H868V744Z" fill={'black'} />
    <path d="M899 744H868V775H899V744Z" fill={'black'} />
    <path d="M930 744H899V775H930V744Z" fill={'black'} />
    <path d="M992 744H961V775H992V744Z" fill={'black'} />
    <path d="M279 775H248V806H279V775Z" fill={'black'} />
    <path d="M310 775H279V806H310V775Z" fill={'black'} />
    <path d="M434 775H403V806H434V775Z" fill={'black'} />
    <path d="M465 775H434V806H465V775Z" fill={'black'} />
    <path d="M558 775H527V806H558V775Z" fill={'black'} />
    <path d="M620 775H589V806H620V775Z" fill={'black'} />
    <path d="M651 775H620V806H651V775Z" fill={'black'} />
    <path d="M682 775H651V806H682V775Z" fill={'black'} />
    <path d="M744 775H713V806H744V775Z" fill={'black'} />
    <path d="M775 775H744V806H775V775Z" fill={'black'} />
    <path d="M899 775H868V806H899V775Z" fill={'black'} />
    <path d="M930 775H899V806H930V775Z" fill={'black'} />
    <path d="M1023 775H992V806H1023V775Z" fill={'black'} />
    <path d="M310 806H279V837H310V806Z" fill={'black'} />
    <path d="M527 806H496V837H527V806Z" fill={'black'} />
    <path d="M558 806H527V837H558V806Z" fill={'black'} />
    <path d="M589 806H558V837H589V806Z" fill={'black'} />
    <path d="M713 806H682V837H713V806Z" fill={'black'} />
    <path d="M775 806H744V837H775V806Z" fill={'black'} />
    <path d="M837 806H806V837H837V806Z" fill={'black'} />
    <path d="M899 806H868V837H899V806Z" fill={'black'} />
    <path d="M930 806H899V837H930V806Z" fill={'black'} />
    <path d="M961 806H930V837H961V806Z" fill={'black'} />
    <path d="M992 806H961V837H992V806Z" fill={'black'} />
    <path d="M279 837H248V868H279V837Z" fill={'black'} />
    <path d="M310 837H279V868H310V837Z" fill={'black'} />
    <path d="M341 837H310V868H341V837Z" fill={'black'} />
    <path d="M372 837H341V868H372V837Z" fill={'black'} />
    <path d="M403 837H372V868H403V837Z" fill={'black'} />
    <path d="M434 837H403V868H434V837Z" fill={'black'} />
    <path d="M527 837H496V868H527V837Z" fill={'black'} />
    <path d="M558 837H527V868H558V837Z" fill={'black'} />
    <path d="M589 837H558V868H589V837Z" fill={'black'} />
    <path d="M651 837H620V868H651V837Z" fill={'black'} />
    <path d="M682 837H651V868H682V837Z" fill={'black'} />
    <path d="M713 837H682V868H713V837Z" fill={'black'} />
    <path d="M775 837H744V868H775V837Z" fill={'black'} />
    <path d="M899 837H868V868H899V837Z" fill={'black'} />
    <path d="M992 837H961V868H992V837Z" fill={'black'} />
    <path d="M1023 837H992V868H1023V837Z" fill={'black'} />
    <path d="M310 868H279V899H310V868Z" fill={'black'} />
    <path d="M403 868H372V899H403V868Z" fill={'black'} />
    <path d="M434 868H403V899H434V868Z" fill={'black'} />
    <path d="M496 868H465V899H496V868Z" fill={'black'} />
    <path d="M589 868H558V899H589V868Z" fill={'black'} />
    <path d="M651 868H620V899H651V868Z" fill={'black'} />
    <path d="M775 868H744V899H775V868Z" fill={'black'} />
    <path d="M806 868H775V899H806V868Z" fill={'black'} />
    <path d="M837 868H806V899H837V868Z" fill={'black'} />
    <path d="M868 868H837V899H868V868Z" fill={'black'} />
    <path d="M899 868H868V899H899V868Z" fill={'black'} />
    <path d="M930 868H899V899H930V868Z" fill={'black'} />
    <path d="M992 868H961V899H992V868Z" fill={'black'} />
    <path d="M1023 868H992V899H1023V868Z" fill={'black'} />
    <path d="M279 899H248V930H279V899Z" fill={'black'} />
    <path d="M310 899H279V930H310V899Z" fill={'black'} />
    <path d="M341 899H310V930H341V899Z" fill={'black'} />
    <path d="M372 899H341V930H372V899Z" fill={'black'} />
    <path d="M465 899H434V930H465V899Z" fill={'black'} />
    <path d="M496 899H465V930H496V899Z" fill={'black'} />
    <path d="M527 899H496V930H527V899Z" fill={'black'} />
    <path d="M558 899H527V930H558V899Z" fill={'black'} />
    <path d="M589 899H558V930H589V899Z" fill={'black'} />
    <path d="M620 899H589V930H620V899Z" fill={'black'} />
    <path d="M713 899H682V930H713V899Z" fill={'black'} />
    <path d="M837 899H806V930H837V899Z" fill={'black'} />
    <path d="M899 899H868V930H899V899Z" fill={'black'} />
    <path d="M930 899H899V930H930V899Z" fill={'black'} />
    <path d="M992 899H961V930H992V899Z" fill={'black'} />
    <path d="M372 930H341V961H372V930Z" fill={'black'} />
    <path d="M496 930H465V961H496V930Z" fill={'black'} />
    <path d="M527 930H496V961H527V930Z" fill={'black'} />
    <path d="M589 930H558V961H589V930Z" fill={'black'} />
    <path d="M620 930H589V961H620V930Z" fill={'black'} />
    <path d="M651 930H620V961H651V930Z" fill={'black'} />
    <path d="M713 930H682V961H713V930Z" fill={'black'} />
    <path d="M775 930H744V961H775V930Z" fill={'black'} />
    <path d="M806 930H775V961H806V930Z" fill={'black'} />
    <path d="M899 930H868V961H899V930Z" fill={'black'} />
    <path d="M961 930H930V961H961V930Z" fill={'black'} />
    <path d="M1023 930H992V961H1023V930Z" fill={'black'} />
    <path d="M403 961H372V992H403V961Z" fill={'black'} />
    <path d="M434 961H403V992H434V961Z" fill={'black'} />
    <path d="M465 961H434V992H465V961Z" fill={'black'} />
    <path d="M558 961H527V992H558V961Z" fill={'black'} />
    <path d="M589 961H558V992H589V961Z" fill={'black'} />
    <path d="M620 961H589V992H620V961Z" fill={'black'} />
    <path d="M651 961H620V992H651V961Z" fill={'black'} />
    <path d="M682 961H651V992H682V961Z" fill={'black'} />
    <path d="M744 961H713V992H744V961Z" fill={'black'} />
    <path d="M775 961H744V992H775V961Z" fill={'black'} />
    <path d="M279 992H248V1023H279V992Z" fill={'black'} />
    <path d="M403 992H372V1023H403V992Z" fill={'black'} />
    <path d="M465 992H434V1023H465V992Z" fill={'black'} />
    <path d="M496 992H465V1023H496V992Z" fill={'black'} />
    <path d="M527 992H496V1023H527V992Z" fill={'black'} />
    <path d="M620 992H589V1023H620V992Z" fill={'black'} />
    <path d="M682 992H651V1023H682V992Z" fill={'black'} />
    <path d="M713 992H682V1023H713V992Z" fill={'black'} />
    <path d="M775 992H744V1023H775V992Z" fill={'black'} />
    <path d="M837 992H806V1023H837V992Z" fill={'black'} />
    <path d="M899 992H868V1023H899V992Z" fill={'black'} />
    <path d="M930 992H899V1023H930V992Z" fill={'black'} />
    <path d="M992 992H961V1023H992V992Z" fill={'black'} />
    <path d="M184.45 0H32.55H0V32.55V184.45V217H32.55H184.45H217V184.45V32.55V0H184.45ZM184.45 184.45H32.55V32.55H184.45V184.45Z" fill={'black'} />
    <path d="M990.45 0H838.55H806V32.55V184.45V217H838.55H990.45H1023V184.45V32.55V0H990.45ZM990.45 184.45H838.55V32.55H990.45V184.45Z" fill={'black'} />
    <path d="M184.45 806H32.55H0V838.55V990.45V1023H32.55H184.45H217V990.45V838.55V806H184.45ZM184.45 990.45H32.55V838.55H184.45V990.45Z" fill={'black'} />
    <path d="M155 62H62V155H155V62Z" fill={'black'} />
    <path d="M961 62H868V155H961V62Z" fill={'black'} />
    <path d="M155 868H62V961H155V868Z" fill={'black'} />
  </svg>
);
