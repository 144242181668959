import React from 'react';
const DownloadVoiceOver: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="20" height="20" rx="4" fill="url(#paint0_linear_10239_5002)" />
      <path
        d="M9.99966 11.6667C11.1063 11.6667 11.9997 10.7733 11.9997 9.66667V5.66667C11.9997 4.56 11.1063 3.66667 9.99966 3.66667C8.893 3.66667 7.99967 4.56 7.99967 5.66667V9.66667C7.99967 10.7733 8.893 11.6667 9.99966 11.6667ZM13.9397 9.66667C13.613 9.66667 13.3397 9.90667 13.2863 10.2333C13.013 11.8 11.6463 13 9.99966 13C8.353 13 6.98633 11.8 6.713 10.2333C6.65967 9.90667 6.38633 9.66667 6.05967 9.66667C5.653 9.66667 5.333 10.0267 5.393 10.4267C5.71967 12.4267 7.31967 13.9933 9.333 14.28V15.6667C9.333 16.0333 9.633 16.3333 9.99966 16.3333C10.3663 16.3333 10.6663 16.0333 10.6663 15.6667V14.28C12.6797 13.9933 14.2797 12.4267 14.6063 10.4267C14.673 10.0267 14.3463 9.66667 13.9397 9.66667Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_10239_5002" x1="-3.2" y1="-4.55479e-07" x2="22.9411" y2="5.19217" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0D22F3" />
          <stop offset="1" stopColor="#7B00F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DownloadVoiceOver;
