import { useEffect } from 'react';

type ElementOrRef = React.RefObject<Element> | Element | null;

export const useOnClickOutside = (ref: ElementOrRef | ElementOrRef[] | null, handler: (e: MouseEvent | TouchEvent) => void) => {
  useEffect(() => {
    const checkIfClickedOutside = (r: ElementOrRef, event: MouseEvent | TouchEvent): boolean => {
      if (!r) return false;

      const targetElement = event.target as Node | null;
      const element = r instanceof Element ? r : r.current;

      return !!element && !!targetElement && !element.contains(targetElement);
    };

    const listener = (event: MouseEvent | TouchEvent) => {
      const refs = Array.isArray(ref) ? ref : [ref];
      const isOutside = refs.every(r => checkIfClickedOutside(r, event));

      if (isOutside) handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
