import React from 'react';

function DocsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask0_14737_13482" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="20">
        <path
          d="M0.5 1.66667C0.5 0.746192 1.24619 0 2.16667 0H8.97631C9.41834 0 9.84226 0.175595 10.1548 0.488155L15.0118 5.34518C15.3244 5.65774 15.5 6.08166 15.5 6.52369V18.3333C15.5 19.2538 14.7538 20 13.8333 20H2.16667C1.24619 20 0.5 19.2538 0.5 18.3333V1.66667Z"
          fill="#6877DA"
        />
      </mask>
      <g mask="url(#mask0_14737_13482)">
        <path
          d="M0.5 1.66667C0.5 0.746192 1.24619 0 2.16667 0H8.97631C9.41834 0 9.84226 0.175595 10.1548 0.488155L15.0118 5.34518C15.3244 5.65774 15.5 6.08166 15.5 6.52369V18.3333C15.5 19.2538 14.7538 20 13.8333 20H2.16667C1.24619 20 0.5 19.2538 0.5 18.3333V1.66667Z"
          fill="#007AFF"
        />
        <path opacity="0.24" d="M9.66699 0L15.5003 5.83333H11.3337C10.4132 5.83333 9.66699 5.08714 9.66699 4.16667V0Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.83398 9.58464C3.83398 9.35452 4.02053 9.16797 4.25065 9.16797H11.7507C11.9808 9.16797 12.1673 9.35452 12.1673 9.58464V10.418C12.1673 10.6481 11.9808 10.8346 11.7507 10.8346H4.25065C4.02053 10.8346 3.83398 10.6481 3.83398 10.418V9.58464ZM3.83398 12.0846C3.83398 11.8545 4.02053 11.668 4.25065 11.668H11.7507C11.9808 11.668 12.1673 11.8545 12.1673 12.0846V12.918C12.1673 13.1481 11.9808 13.3346 11.7507 13.3346H4.25065C4.02053 13.3346 3.83398 13.1481 3.83398 12.918V12.0846ZM4.25065 14.168C4.02053 14.168 3.83398 14.3545 3.83398 14.5846V15.418C3.83398 15.6481 4.02053 15.8346 4.25065 15.8346H10.084C10.3141 15.8346 10.5007 15.6481 10.5007 15.418V14.5846C10.5007 14.3545 10.3141 14.168 10.084 14.168H4.25065Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default DocsIcon;
