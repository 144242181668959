import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Spinner } from 'assets/images';
import { CanvasIntegrationTestVariant } from 'config/constants/featureDefinitions';
import { FeatureNameEnum, useFeatureVariant } from 'hooks/useFeatureFlags';
import { IntegrationService } from 'interfaces/integrations';
import { actions as importActions } from 'store/import';

import { IntegrationIcons, IntegrationTitles } from './constants';
import { useCanvasData } from './hooks/useCanvasData';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';
import { ImportSourceCard } from './ImportSourceCard';

export function CanvasIntegrationCard() {
  const dispatch = useDispatch();
  const [authorizeData, isAuthorizing, authorize] = useIntegrationAuth(IntegrationService.CANVAS);

  const canvasIntegrationVariant = useFeatureVariant(FeatureNameEnum.CANVAS_INTEGRATION);
  const { canvasData } = useCanvasData();

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (isAuthorizing || canvasIntegrationVariant.isLoading || canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.CONTROL) return;

      e.stopPropagation();

      if (canvasData) {
        await authorize({ apiBaseUrl: canvasData.apiBaseUrl, accessToken: canvasData.token });
      }

      dispatch(importActions.openImportDialog({ source: IntegrationService.CANVAS }));
    },
    [canvasIntegrationVariant, canvasData, isAuthorizing, authorize, dispatch]
  );

  const isAuthorized = canvasIntegrationVariant.isLoading
    ? false
    : canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.ENABLED
      ? authorizeData.authorized
      : false;

  return (
    <ImportSourceCard
      icon={IntegrationIcons[IntegrationService.CANVAS]}
      title={IntegrationTitles[IntegrationService.CANVAS].name}
      buttonType={isAuthorized ? 'outline' : 'solid'}
      buttonText={isAuthorized ? 'View Files' : isAuthorizing ? <Spinner width="20" fill="white" className="animate-spin inline-block" /> : 'Connect'}
      onClick={handleClick}
    />
  );
}
