import React from 'react';
export default function PdfIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
        fill="#FF3B30"
      />
      <path opacity={0.24} d="M9.667 0L15.5 5.833h-4.167c-.92 0-1.666-.746-1.666-1.666V0z" fill="#fff" />
      <path
        d="M12.785 12.94c-.747-.8-2.787-.474-3.275-.415-.718-.71-1.206-1.568-1.379-1.864.259-.799.431-1.598.46-2.456 0-.74-.287-1.538-1.092-1.538-.287 0-.546.177-.69.414-.344.621-.2 1.864.345 3.136-.316.917-.603 1.805-1.407 3.373-.833.355-2.586 1.184-2.73 2.071a.805.805 0 00.23.74c.201.177.46.266.719.266 1.062 0 2.097-1.509 2.815-2.781a18.632 18.632 0 012.5-.68c1.12 1.005 2.096 1.153 2.614 1.153.689 0 .947-.296 1.034-.562.143-.296.057-.621-.144-.858zm-.718.502c-.029.207-.287.414-.747.296a3.898 3.898 0 01-1.465-.77c.373-.059 1.206-.147 1.81-.029.23.06.46.207.402.503zM7.269 7.347c.058-.088.144-.148.23-.148.259 0 .316.326.316.592a6.675 6.675 0 01-.345 1.835c-.43-1.184-.344-2.012-.2-2.279zm-.057 5.74c.23-.473.546-1.302.66-1.657.26.444.69.977.92 1.213 0 .03-.89.207-1.58.444zm-1.695 1.184c-.66 1.124-1.35 1.834-1.724 1.834a.4.4 0 01-.172-.06c-.086-.059-.115-.147-.086-.266.086-.414.833-.976 1.982-1.508z"
        fill="#fff"
      />
    </svg>
  );
}
