import ViewListIcon from '@heroicons/react/outline/ViewListIcon';
import { ViewType } from 'interfaces';
import React, { useCallback } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';

import { CardViewIcon } from 'assets/icons/itemView';
import { useTranslation } from 'hooks/useTypedTranslation';
import { actions as libraryActions } from 'store/library';

export function ViewTypeFilter() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const viewType = useSelector(state => state.library.viewType) ?? ViewType.GRID;

  const handleViewTypeChange = useCallback(
    (viewType: ViewType) => {
      dispatch(libraryActions.setViewType(viewType));
    },
    [dispatch]
  );

  return (
    <div className="flex items-center gap-1">
      <button
        className={twMerge(
          'p-2 transition-colors rounded-[8px]',
          viewType === ViewType.LIST ? 'bg-glass-200' : 'bg-glass-0 hover:bg-glass-hovered-0 active:bg-glass-pressed-0'
        )}
        onClick={() => handleViewTypeChange(ViewType.LIST)}
        disabled={viewType === ViewType.LIST}
        data-tip
        data-tooltip-id="list-view-tooltip"
      >
        <ViewListIcon aria-hidden="true" className="h-5 w-5 text-glass-400" />
      </button>
      <button
        className={twMerge(
          'p-2 transition-colors rounded-[8px]',
          viewType === ViewType.GRID ? 'bg-glass-200' : 'bg-glass-0 hover:bg-glass-hovered-0 active:bg-glass-pressed-0'
        )}
        onClick={() => handleViewTypeChange(ViewType.GRID)}
        disabled={viewType === ViewType.GRID}
        data-tip
        data-tooltip-id="grid-view-tooltip"
      >
        <CardViewIcon aria-hidden="true" className="h-5 w-5 text-glass-400" />
      </button>
      {viewType !== ViewType.GRID && (
        <ReactTooltip id="grid-view-tooltip" place="top" variant="dark" className="!p-2 !leading-4 !border-x-0 !border-t-0">
          <span className="text-xs text-glass-1 font-ABCDiatype">{t('Grid view')}</span>
        </ReactTooltip>
      )}
      {viewType !== ViewType.LIST && (
        <ReactTooltip id="list-view-tooltip" place="top" variant="dark" className="!p-2 !leading-4 !border-x-0 !border-t-0">
          <span className="text-xs text-glass-1 font-ABCDiatype">{t('List view')}</span>
        </ReactTooltip>
      )}
    </div>
  );
}
