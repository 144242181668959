import React from 'react';
export default function TxtIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={20}
      >
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#6877DA"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#5856D6"
        />
        <path
          d="M11.934 9L12 11.21h-.546c-.089-.424-.205-.746-.349-.964a1.085 1.085 0 00-.619-.46c-.272-.084-.648-.138-1.129-.16l-.294-.011v6.066c0 .181.024.324.072.427.048.1.146.177.294.233.149.051.375.094.68.127V17H5.891v-.532c.304-.033.53-.076.679-.127.148-.056.244-.133.288-.233.048-.103.072-.246.072-.427V9.615l-.288.011c-.48.022-.857.076-1.13.16a1.12 1.12 0 00-.624.455c-.14.218-.254.541-.342.97H4L4.066 9h7.868z"
          fill="#fff"
        />
        <path opacity={0.24} d="M9.667 0L15.5 5.833h-4.167c-.92 0-1.666-.746-1.666-1.666V0z" fill="#fff" />
      </g>
    </svg>
  );
}
