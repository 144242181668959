import React from 'react';
const MoveToIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.666 5H10L8.333 3.333h-5c-.917 0-1.667.75-1.667 1.667v10c0 .916.75 1.666 1.667 1.666h13.334c.916 0 1.666-.75 1.666-1.666V6.666c0-.916-.75-1.666-1.666-1.666zm0 10H3.333V5h4.308l1.667 1.666h7.358V15zm-6.533-5H6.667v1.666h3.466l-1.325 1.325 1.184 1.175 3.341-3.325L9.992 7.5 8.816 8.675 10.133 10z"
        fill="#587393"
      />
    </svg>
  );
};

export default MoveToIcon;
