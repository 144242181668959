import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';

import { auth } from 'lib/speechify';
import { actions as integrationActions } from 'store/integration';

import { useAvailableIntegrations } from './hooks/useAvailableIntegrations';
import { IntegrationItem } from './IntegrationItem';

interface IntegrationsProps {
  className?: string;
  itemClassName?: string;
}

function Integrations({ className, itemClassName }: IntegrationsProps): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const hasAuths = useSelector(state => !isEmpty(state.integration.auths));
  const userUid = auth.currentUser?.uid;

  useEffect(() => {
    // Need to check auth.currentUser?.uid as well because we need to grab user token when fetching integrations
    if (user?.uid && userUid) {
      dispatch(integrationActions.fetchAuths());
    }
  }, [dispatch, user?.uid, userUid]);

  const enabledIntegrations = useAvailableIntegrations();
  const gridSize = enabledIntegrations.length;

  return (
    <div
      className={twMerge(
        `grid gap-4 font-ABCDiatype`,
        className,
        // Cannot use dynamic className as tailwind doesn't include such styles
        // e.g. `grid-cols-${gridSize}` will not work (unless same class appeared somewhere in other place)
        gridSize === 1 && 'grid-cols-1',
        gridSize === 2 && 'grid-cols-2',
        gridSize === 3 && 'grid-cols-3',
        gridSize > 3 && 'grid-cols-4'
      )}
    >
      {hasAuths
        ? enabledIntegrations.map(service => (
            <div
              className={twMerge('rounded-[10px] border border-solid hover:border-glass-hovered-300 flex items-center justify-center cursor-pointer')}
              key={service}
            >
              <IntegrationItem key={service} service={service} className={twMerge('w-full h-full p-4', itemClassName)} />
            </div>
          ))
        : Array(3)
            .fill(null)
            .map((_, idx) => (
              <div className="p-4 animate-pulse rounded-[10px] bg-gray-200 dark:bg-gray-700" key={`integrations_loading_${idx}`}>
                <div className="h-5 py-2 flex justify-between items-center mb-2">
                  <div className="h-5 bg-gray-100 dark:bg-gray-500 w-11/12"></div>
                  <div className="h-5 w-4 bg-gray-100 dark:bg-gray-500"></div>
                </div>
                <div className="h-36 bg-gray-100 dark:bg-gray-500"></div>
              </div>
            ))}
    </div>
  );
}

export default React.memo(Integrations);
