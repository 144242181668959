import { isFunction } from 'lodash';
import { useEffect, useRef } from 'react';

export function useInterval(cb: () => void, delay: number | null) {
  const savedCallback = useRef(() => {});
  // ESLint: 'clearIntervalRef' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearIntervalRef = useRef(() => {});

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current && isFunction(savedCallback.current)) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [cb, delay]);
}
