import React from 'react';
export default function Opera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24 4C12.947 4 4 12.947 4 24C4 35.053 12.947 44 24 44C35.053 44 44 35.053 44 24C44 12.947 35.053 4 24 4ZM24 39C18.737 39 14 32.104 14 24C14 15.896 18.737 9 24 9C29.263 9 34 15.896 34 24C34 32.104 29.263 39 24 39Z"
        fill="#FF1744"
      />
      <path
        d="M36.5639 8.445C34.4129 6.972 31.8899 6 29.2629 6C25.3149 6 21.7389 7.98 19.0969 11.079C20.5729 9.77 22.2549 9 23.9999 9C29.2629 9 33.9999 15.896 33.9999 24C33.9999 32.104 29.2629 39 23.9999 39C22.2549 39 20.5729 38.23 19.0969 36.921C21.7389 40.02 25.3149 42 29.2629 42C31.8889 42 34.4129 41.028 36.5629 39.556C41.0969 35.891 43.9999 30.29 43.9999 24C43.9999 17.711 41.0969 12.11 36.5639 8.445Z"
        fill="#D50000"
      />
    </svg>
  );
}
