import React from 'react';

export default function TextSourceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.4142 13.7977L30.0001 8H21.5001V32L25.6962 32.8392C26.1636 32.9327 26.5001 33.3431 26.5001 33.8198V34C26.5001 34.5523 26.0523 35 25.5001 35H14.5001C13.9478 35 13.5001 34.5523 13.5001 34V33.8198C13.5001 33.3431 13.8365 32.9327 14.3039 32.8392L18.5001 32V8H10L9.58593 13.7976C9.53755 14.4751 8.97382 15 8.29461 15C7.57962 15 7 14.4204 7 13.7054V6.5C7 5.67157 7.67157 5 8.5 5H31.5001C31.8979 5 32.2794 5.15804 32.5607 5.43934C32.842 5.72064 33.0001 6.10218 33.0001 6.5V13.7054C33.0001 14.4204 32.4205 15 31.7055 15C31.0263 15 30.4626 14.4751 30.4142 13.7977Z"
        fill="#587393"
      />
    </svg>
  );
}
