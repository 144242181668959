import { DragOverlay } from '@dnd-kit/core';
import { XIcon } from '@heroicons/react/outline';
import ConfirmModal from 'components/dashboard/ConfirmModal';
import Draggable from 'components/dashboard/Draggable';
import { GroupMoveToModal, ItemCard, ItemCardPlaceholder, ItemRow } from 'components/library';
import { IRecord, ItemActionType, ItemGroupActionType, ItemType, ViewType } from 'interfaces';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { addDocumentElementClass, carryParams, isDescendant, removeDocumentElementClass } from 'utils';

import ListeningHeadIcon from 'assets/icons/listening-head';
import { CanvasIntegrationTestVariant } from 'config/constants/featureDefinitions';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import useMediaQuery from 'hooks/useMediaQuery';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { fetchArchivedItems } from 'lib/speechify';
import { selectedItemsStoreActions, useSelectedItemsStore } from 'modules/library/store/selectedItemsStore';
import { selectors as authSelectors } from 'store/auth';
import { actions as importActions } from 'store/import';
import { actions as libraryActions } from 'store/library';
import { fromNow } from 'utils/dates';
import { itemTypeFilterFn } from 'utils/filter';
import { listenedItemFilterFn, recentlyListenedSortFn, sortItemFn } from 'utils/sort';

import useResizeObserver from '../../../hooks/useResizeObserver';
import { logSegmentEvent } from '../../../utils/analytics';
import { Dropzone } from '../import/Dropzone';
import { FilesProgress } from '../import/FilesProgress';
import { useCanvasData } from '../import/hooks/useCanvasData';
import { ImportDialog } from '../import/ImportDialog';
import Integrations from '../import/Integrations';
import { SuggestedItems } from '../import/SuggestedItems';
import CarouselSlider from './components/CarouselSlider';
import FoldersNavigation from './FoldersNavigation';
import Header from './Header';
import RecentlyListened from './RecentlyListened';
import RowOverlay from './RowOverlay';
import TrashSubHeader from './TrashSubHeader';

const { addSelectedItem, removeSelectedItem, addSelectedFolder, removeSelectedFolder, resetSelectedItemsStore } = selectedItemsStoreActions;

interface ItemsProps {
  defaultViewType: ViewType;
  displayRangeEnd: number;
  displayRangeStart: number;
  folders: IRecord[];
  isLoading: boolean;
  isSyncing: boolean;
  items: IRecord[];
  onItemAction: (item: IRecord, actionType: ItemActionType, location?: string) => void;
  processingId?: string | null;
}

export const Items: React.FC<ItemsProps> = ({
  defaultViewType: fallbackViewType = ViewType.GRID,
  displayRangeEnd,
  displayRangeStart,
  folders,
  isLoading,
  isSyncing,
  items,
  onItemAction,
  processingId
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const activeDragTargetId = useSelector(state => state.mainPage.activeDragTargetId);
  // ESLint: The 'cacheFolderIds' logical expression could make the dependencies of useMemo Hook (at line 335) change on every render. Move it inside the useMemo callback. Alternatively, wrap the initialization of 'cacheFolderIds' in its own useMemo() Hook.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cacheFolderIds = useSelector(state => state.library.cacheFolderIds) || [];
  const playbackSpeed = useSelector(authSelectors.getPlaybackSpeed);
  const user = useSelector(state => state.auth.user);
  const defaultViewType = useSelector(state => state.library.viewType) ?? fallbackViewType;
  const suggestedItems = useSelector(state => state.integration.suggestedItems) || [];
  const suggestionsDisabled = useSelector(state => state.integration.suggestionDisabled || !!state.library.currentFolderId);

  // state
  const [archivedItems, setArchivedItems] = useState<IRecord[]>([]);
  const [folderId, setFolderId] = useState<string | undefined>();

  const { canvasData } = useCanvasData({ cache: true });
  const isCanvasFolder = useMemo(() => {
    if (!canvasData) return false;

    const foldersMap = Object.fromEntries(folders.map(folder => [folder.id, folder])) as Partial<Record<string, IRecord>>;
    const isCanvasFolderOrChild = (folderId: string): boolean => {
      const folder = foldersMap[folderId];
      if (!folder) return false;
      return (folder.title === 'Canvas' && !folder.parentFolderId) || (!!folder.parentFolderId && isCanvasFolderOrChild(folder.parentFolderId));
    };

    return folderId ? isCanvasFolderOrChild(folderId) : false;
  }, [canvasData, folders, folderId]);
  const hideCanvasNotification = useSelector(state => state.library.hideCanvasNotification);

  const [isMoveSelectedModalOpen, setIsMoveSelectedModalOpen] = useState<boolean>(false);
  const [isSelectedDeleteConfirmModalOpen, setIsSelectedDeleteConfirmModalOpen] = useState<boolean>(false);
  const [itemWidth, setItemWidth] = useState<string | undefined>(undefined);
  const { selectedFolders: selectedFolderIds, selectedItems: selectedItemIds } = useSelectedItemsStore(store => store);
  const [selectedItemType, setSelectedItemType] = useState<string>('all');
  const [selectedSort, setSelectedSort] = useState<string>('added');
  const [showEmptyStateDelayed, setShowEmptyStateDelayed] = useState(false);
  const [viewType, setViewType] = useState<ViewType>(defaultViewType);

  const aiStoryFlowVariant = useFeatureVariant(FeatureNameEnum.AI_STORY_FLOW);
  const aiStoryEnabled = isFeatureVariantReady(aiStoryFlowVariant) && aiStoryFlowVariant.variant;

  const showAIStory = useMemo(() => {
    return !folderId && aiStoryEnabled;
  }, [folderId, aiStoryEnabled]);

  // view type defaults to list on mobile
  const { matches: isMobile } = useMediaQuery('(max-width: 640px)');
  const isTrash = folderId === 'trash';

  const ulRef = useRef<HTMLUListElement>(null);
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const rootRef = useRef<HTMLDivElement>(null);
  const offsetTop = useMemo(() => {
    return rootRef.current ? rootRef.current.offsetTop : 0;
  }, [width, height]); // eslint-disable-line react-hooks/exhaustive-deps

  const skeletonTimeoutRef = useRef<NodeJS.Timeout>();

  // Features
  const canvasIntegrationVariant = useFeatureVariant(FeatureNameEnum.CANVAS_INTEGRATION);
  const isCanvasIntegrationEnabled =
    isFeatureVariantReady(canvasIntegrationVariant) && canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.ENABLED;

  useEffect(() => {
    if (viewType === ViewType.LIST) {
      setItemWidth('265px');
    }
    // INFO: depending on items helps to initialize itemWidth in the case the dependency of width does not work
    // (width does not change because of no items in new users)
  }, [items, width, viewType]);

  useEffect(() => {
    (async () => {
      isTrash && setArchivedItems(await fetchArchivedItems());
    })();
  }, [isTrash]);

  useEffect(() => {
    setFolderId(router.query.folder?.toString());
    resetSelectedItemsStore();
  }, [router.query.folder]);

  useEffect(() => {
    setSelectedSort(router.query.sort?.toString() || 'added');
    dispatch(libraryActions.setItemSort(router.query.sort?.toString() || 'added'));
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.sort]);

  useEffect(() => {
    setSelectedItemType(router.query.type?.toString() || 'all');
    dispatch(libraryActions.setItemsFilterType(router.query.type?.toString() || 'all'));
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.type]);

  useEffect(() => {
    setViewType((router.query.view?.toString() as ViewType) || defaultViewType);
    // ESLint: React Hook useEffect has a missing dependency: 'defaultViewType'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.view]);

  useEffect(() => {
    if (isMobile) {
      setViewType(ViewType.LIST);
      navigate(carryParams('/', { view: ViewType.LIST }), undefined, { shallow: true });
    }
    // ESLint: React Hook useEffect has a missing dependency: 'navigate'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const handleFolderClick = (folder?: string | null) => {
    if (!folder) {
      navigate(carryParams('/'), undefined, { shallow: true });
    }
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    navigate(carryParams('/', { folder }), undefined, { shallow: true });
  };

  // items
  const recordItems = useMemo(() => items?.filter(item => item.id !== processingId && item.type === ItemType.Record) || [], [items, processingId]);

  const filteredItems = useMemo(() => {
    return recordItems.filter(itemTypeFilterFn(selectedItemType, folderId)).sort(sortItemFn(selectedSort)).slice(displayRangeStart, displayRangeEnd);
  }, [recordItems, folderId, selectedItemType, selectedSort, displayRangeStart, displayRangeEnd]);

  useEffect(() => {
    if (router.query.type != 'all') {
      dispatch(libraryActions.setCurrentPage(0));
    }
    // ESLint: React Hook useEffect has missing dependencies: 'dispatch' and 'router.query.type'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MAX_RECENTLY_LISTENED_ITEMS = 9;
  const recentItems = useMemo(() => recordItems.filter(listenedItemFilterFn).sort(recentlyListenedSortFn).slice(0, MAX_RECENTLY_LISTENED_ITEMS), [recordItems]);

  // folders
  const folderIds = useMemo(() => folders?.map((target: IRecord) => target.id), [folders]);

  const mainFolders = useMemo(
    () =>
      folders
        ?.filter((item: IRecord) => !item.parentFolderId)
        .reverse()
        .sort(sortItemFn(selectedSort)) || [],
    [folders, selectedSort]
  );

  const childFolders = useMemo(
    () =>
      folders
        ?.filter((item: IRecord) => item.parentFolderId === folderId)
        .reverse()
        .sort(sortItemFn(selectedSort)),
    [folders, folderId, selectedSort]
  );

  // If we are inside any folder we need to show the child folders
  // Otherwise only the main folders
  const subFolders = folderId ? childFolders : mainFolders;

  const handleCanvasClick = () => {
    dispatch(libraryActions.hideAddNew());
  };

  const handleViewTypeChange = (viewType: ViewType) => {
    dispatch(libraryActions.setViewType(viewType));
    setViewType(viewType);
  };

  const handleItemClick = (item: IRecord, actionType: ItemActionType, location?: string) => {
    switch (actionType) {
      case ItemActionType.Select:
        addSelectedItem(item.id);
        break;

      case ItemActionType.Unselect:
        removeSelectedItem(item.id);
        break;

      default:
        onItemAction(item, actionType, location);
    }
  };

  const handleFolderAction = (folder: IRecord, actionType: ItemActionType, location?: string) => {
    switch (actionType) {
      case ItemActionType.Select:
        addSelectedFolder(folder.id);
        break;

      case ItemActionType.Unselect:
        removeSelectedFolder(folder.id);
        break;

      default:
        onItemAction(folder, actionType, location);
    }
  };

  const handleSelectedItemsAction = (actionType: ItemGroupActionType) => {
    switch (actionType) {
      case ItemGroupActionType.Move:
        setIsMoveSelectedModalOpen(true);
        break;

      case ItemGroupActionType.Delete:
        setIsSelectedDeleteConfirmModalOpen(true);
        break;
    }
  };

  const handleMoveSelectedModalClose = () => {
    setIsMoveSelectedModalOpen(false);
  };

  const handleMoveSelectedModalSubmit = (parentFolderId: string) => {
    const folderName = parentFolderId === null ? t('My Library') : folders.find(folder => folder.id === parentFolderId)?.title;

    dispatch(
      libraryActions.moveItems({
        itemIds: selectedItemIds,
        folderIds: selectedFolderIds,
        parentFolderId,
        folderName: folderName ?? ''
      })
    );

    logSegmentEvent('web_app_files_bulk_operation', {
      files: selectedItemIds.length,
      folders: selectedFolderIds.length,
      totalItems: selectedItemIds.length + selectedFolderIds.length,
      operation: 'move'
    });

    resetSelectedItemsStore();
    setIsMoveSelectedModalOpen(false);
  };

  const handleSelectedDeleteConfirm = () => {
    dispatch(libraryActions.archiveItems([...selectedItemIds, ...selectedFolderIds]));

    // ESLint: 'itemId' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedItemIds.forEach(itemId => {
      logSegmentEvent('web_app_document_archived', {});
    });

    // ESLint: 'folderId' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedFolderIds.forEach(folderId => {
      logSegmentEvent('web_app_folder_archived', {});
    });

    logSegmentEvent('web_app_files_bulk_operation', {
      files: selectedItemIds.length,
      folders: selectedFolderIds.length,
      totalItems: selectedItemIds.length + selectedFolderIds.length,
      operation: 'delete'
    });

    resetSelectedItemsStore();
    setIsSelectedDeleteConfirmModalOpen(false);
  };

  const handleSelectedDeleteCancel = () => {
    setIsSelectedDeleteConfirmModalOpen(false);
  };

  const handleUnselectAll = () => {
    resetSelectedItemsStore();
  };

  const handleCanvasNotificationHide = () => {
    dispatch(libraryActions.hideCanvasNotification());
  };

  const handleViewCanvasFiles = () => {
    dispatch(importActions.openImportDialog({ source: IntegrationService.CANVAS }));
  };

  const selectedDeleteConfirmationTitle = useMemo(() => {
    const files = t('files', { count: selectedItemIds.length });
    const folders = t('folders', { count: selectedFolderIds.length });

    if (selectedItemIds.length && selectedFolderIds.length) {
      return t('Delete files and folders', { files, folders });
    }

    if (selectedItemIds.length) {
      return t('Delete files', { files });
    }

    if (selectedFolderIds.length) {
      return t('Delete folders', { folders });
    }

    return '';
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderIds.length, selectedItemIds.length]);

  const selectedDeleteConfirmationText = useMemo(() => {
    const files = t('files', { count: selectedItemIds.length });
    const folders = t('folders', { count: selectedFolderIds.length });

    if (selectedItemIds.length && selectedFolderIds.length) {
      return t('Are you sure you want to delete files and folders with its contents?', { files, folders });
    }

    if (selectedItemIds.length) {
      return t('Are you sure you want to delete files?', { files });
    }

    if (selectedFolderIds.length) {
      return t('Are you sure you want to delete folders and its contents?', { folders });
    }

    return '';
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderIds.length, selectedItemIds.length]);

  const ItemComponent = viewType === ViewType.LIST ? ItemRow : ItemCard;
  const itemsToShow = isTrash ? archivedItems : filteredItems;
  const dragItem = itemsToShow.find(item => item.id === activeDragTargetId);

  const showSkeleton = useMemo(
    () => isSyncing && !isTrash && itemsToShow.length === 0 && cacheFolderIds.indexOf(folderId || null) === -1,
    [cacheFolderIds, folderId, isSyncing, isTrash, itemsToShow.length]
  );

  const showEmptyState = useMemo(() => {
    return recentItems.length === 0 && itemsToShow.length === 0 && !isLoading && !isSyncing && !processingId && !isTrash && !showSkeleton;
  }, [recentItems.length, itemsToShow.length, isLoading, isSyncing, processingId, isTrash, showSkeleton]);

  useEffect(() => {
    if (showSkeleton) {
      if (skeletonTimeoutRef.current) clearTimeout(skeletonTimeoutRef.current);

      addDocumentElementClass('skeleton');
    } else {
      skeletonTimeoutRef.current = removeDocumentElementClass('skeleton', 3000);
    }
  }, [showSkeleton]);

  useEffect(() => {
    if (showEmptyState) {
      const timer = setTimeout(() => {
        setShowEmptyStateDelayed(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setShowEmptyStateDelayed(false);
    }
  }, [showEmptyState]);

  const isInitialState = showEmptyStateDelayed && !folderId && subFolders.length === 0;
  const isOnlyIntegration = Object.keys(IntegrationService).length === 1;

  const hasItems = recentItems.length > 0 || itemsToShow.length > 0;
  const suggestionsVisible = !suggestionsDisabled && suggestedItems.length > 0 && hasItems;

  return (
    <div className="rounded-md" ref={rootRef}>
      <div className="bg-white" ref={ref} onClick={handleCanvasClick} style={{ minHeight: `calc(100vh - ${offsetTop}px)` }}>
        <Fragment>
          {/* Header */}
          {(!showEmptyStateDelayed || subFolders.length > 0 || folderId) && (
            <>
              <Header
                // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                folderId={folderId}
                folders={folders}
                isSkeletonVisible={showSkeleton}
                isSyncing={isSyncing}
                selectedItemsCount={selectedItemIds.length}
                selectedFoldersCount={selectedFolderIds.length}
                handleFolderClick={handleFolderClick}
                onItemAction={onItemAction}
                setViewType={handleViewTypeChange}
                viewType={viewType}
                onSelectedItemsAction={handleSelectedItemsAction}
                onUnselectAll={handleUnselectAll}
              />

              {/* Recently Listened slider */}
              {!folderId && recentItems.length > 0 && (
                <RecentlyListened
                  recentlyListenedItems={recentItems}
                  itemWidth={itemWidth}
                  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'boolean'.
                  isMobile={isMobile}
                  folderIds={folderIds}
                  handleItemClick={handleItemClick}
                  playbackSpeed={playbackSpeed}
                />
              )}
            </>
          )}

          {!hideCanvasNotification && isCanvasIntegrationEnabled && isCanvasFolder && (
            <div className="py-4 pl-5 pr-12 bg-yellow-200 rounded-[8px] mt-4 mx-5 flex gap-2 items-center relative">
              <XIcon
                className="absolute right-2 top-2 m-0.5 float-right block h-4 w-4 cursor-pointer text-glass-500"
                aria-hidden="true"
                onClick={handleCanvasNotificationHide}
              />
              <div className="text-glass-700 w-full">
                <h2 className="font-bold text-lg mb-1">{t('This folder will no longer auto-update')}</h2>
                <div className="text-sm">{t('Canvas is now among your connections. Select files you want to listen and add them to your library')}</div>
              </div>
              <div className="grow-0 shrink-0">
                <button
                  className={twMerge(
                    'text-sm font-medium text-electric-400',
                    'inline-flex h-10 px-4 items-center',
                    'rounded-[8px] bg-glass-0 border transition-colors',
                    'border-border-primary-cta hover:border-border-primary-cta-hovered active:border-border-primary-cta-pressed'
                  )}
                  onClick={handleViewCanvasFiles}
                >
                  {t('Import Files')}
                </button>
              </div>
            </div>
          )}

          {showSkeleton && recentItems.length === 0 && !folderId && (
            <div className="flex border-glass-300">
              <div className="w-full pt-5 pl-5">
                <CarouselSlider
                  items={new Array(4).fill('').map((_, index) => (
                    <div className="mr-1 p-4 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" key={index}>
                      <div className="h-5 py-2 flex justify-between items-center mb-2">
                        <div className="h-5 bg-gray-100 dark:bg-gray-500 w-11/12"></div>
                        <div className="h-5 w-4 bg-gray-100 dark:bg-gray-500"></div>
                      </div>
                      <div className="h-36 bg-gray-100 dark:bg-gray-500"></div>
                    </div>
                  ))}
                />
              </div>
            </div>
          )}

          {/* Folders Navigation */}
          {subFolders.length > 0 && (
            <FoldersNavigation
              selectedFolderIds={selectedFolderIds}
              selectedFileIds={selectedItemIds}
              folders={subFolders}
              itemWidth={itemWidth}
              handleFolderClick={handleFolderClick}
              onItemAction={handleFolderAction}
            />
          )}

          {showSkeleton && subFolders.length === 0 && (
            <div className="flex flex-col pl-5 pb-0 pt-5 pr-5">
              <div className="flex-none font-ABCDiatype text-lg font-bold text-glass-700 mb-4">Folders</div>
              <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {new Array(4).fill('').map((_, index) => (
                  <div className="mr-2 h-12 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" key={index} />
                ))}
              </ul>
            </div>
          )}

          {/* Trash warning message*/}
          {isTrash && <TrashSubHeader archivedItemsCount={archivedItems.length} setArchivedItems={setArchivedItems} />}

          {/* Helper titles for items view */}
          {(itemsToShow.length > 0 || showSkeleton || suggestionsVisible) && (
            <>
              <div className="flex flex-col p-5 pb-0">
                <div className="flex-none font-ABCDiatype text-lg font-bold text-glass-700 mb-4">{t('Files')}</div>
              </div>

              {viewType === ViewType.LIST && itemsToShow.length > 0 && (
                <div className=" border-b mx-8 border-glass-300 grid grid-cols-[4fr_1fr_1fr_1fr] pb-2">
                  <div className="flex-none pl-2 font-ABCDiatype text-sm font-medium text-glass-700 leading-5">{t('Name')}</div>
                  <div className="flex-none font-ABCDiatype text-sm font-medium text-glass-700 leading-5">{t('Duration')}</div>
                  <div className="flex-none font-ABCDiatype text-sm font-medium text-glass-700 leading-5">{t('Progress')}</div>
                  <div className="flex-none font-ABCDiatype text-sm font-medium text-glass-700 leading-5 ">{t('Date Added')}</div>
                </div>
              )}
            </>
          )}

          {viewType === ViewType.GRID && hasItems && <SuggestedItems className="px-0 mx-5 mb-3 h-[52px] rounded-[8px] overflow-hidden" />}
          {/* Items */}
          {!showEmptyStateDelayed && (
            <ul
              ref={ulRef}
              className={
                viewType === ViewType.LIST
                  ? 'flex flex-col pb-5'
                  : 'grid grid-cols-1 gap-5 px-6 pl-5 pr-5 pb-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
              }
            >
              {viewType === ViewType.LIST && hasItems && <SuggestedItems />}
              {processingId && viewType === ViewType.GRID && !isLoading && <ItemCardPlaceholder />}

              {showSkeleton &&
                viewType === ViewType.LIST &&
                Array.from(Array(20).keys()).map((_, index) => (
                  <li className="group flex items-center mr-8 animate-pulse" key={index}>
                    <div className="flex cursor-pointer items-center mx-2 opacity-0 group-hover:opacity-100"></div>
                    <div className="cursor-pointer grid grid-cols-[4fr_1fr_1fr_1fr] w-full py-3 border-b border-glass-300 hover:bg-gray-50">
                      <div className="flex gap-1 items-center">
                        <div className="h-6 mx-4 rounded-md bg-gray-200 dark:bg-gray-700 flex-1" />
                      </div>
                      <div>
                        <div className="h-6 mr-2 rounded-md bg-gray-200 dark:bg-gray-700" />
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="h-6 ml-2 mr-2 rounded-md bg-gray-200 dark:bg-gray-700 flex-1" />
                      </div>
                      <div className="flex items-center">
                        <div className="h-6 ml-2 rounded-md bg-gray-200 dark:bg-gray-700 flex-1" />
                      </div>
                    </div>
                  </li>
                ))}

              {showSkeleton &&
                viewType === ViewType.GRID &&
                Array.from(Array(20).keys()).map((_, index) => (
                  <div className="mr-1 p-4 animate-pulse rounded-md bg-gray-200 dark:bg-gray-700" key={index}>
                    <div className="h-5 py-2 flex justify-between items-center mb-2">
                      <div className="h-5 bg-gray-100 dark:bg-gray-500 w-11/12"></div>
                      <div className="h-5 w-4 bg-gray-100 dark:bg-gray-500"></div>
                    </div>
                    <div className="h-36 bg-gray-100 dark:bg-gray-500"></div>
                  </div>
                ))}

              {itemsToShow.map((item: IRecord) => {
                // ESLint: Unexpected any & Unexpected any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
                const timeAgo = (item.createdAt as any)?._seconds ? fromNow(new Date((item.createdAt as any)._seconds * 1000), router.locale ?? '') : '';

                return (
                  <Draggable
                    id={`library-item-${item.id}-drag`}
                    key={item.id}
                    moveTargets={isMobile ? [] : folderIds}
                    disabled={(isMobile ? [] : folderIds).length === 0 || selectedItemIds.length > 0 || selectedFolderIds.length > 0 || isTrash}
                  >
                    <ItemComponent
                      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                      coverImagePath={item.coverImagePath}
                      id={item.id}
                      selected={selectedItemIds.includes(item.id)}
                      selectOnClick={selectedItemIds.length > 0}
                      selectionAvailable={!isTrash}
                      moveTargets={isMobile ? [] : folderIds}
                      preventDrag={selectedItemIds.length > 0 || selectedFolderIds.length > 0}
                      onAction={handleItemClick}
                      isTrash={isTrash}
                      // @ts-expect-error TS(2322): Type 'false | IRecord[]' is not assignable to type... Remove this comment to see the full error message
                      archivedItems={isTrash && archivedItems}
                      // @ts-expect-error TS(2322): Type 'false | Dispatch<SetStateAction<IRecord[]>>'... Remove this comment to see the full error message
                      setArchivedItems={isTrash && setArchivedItems}
                      // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                      progressPercent={item.progressPercent}
                      recordType={item.recordType}
                      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                      sourceURL={item.sourceURL}
                      status={item.status}
                      timeAgo={timeAgo}
                      title={item.title}
                      // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                      wordCount={item.wordCount}
                      wpm={(playbackSpeed || 1) * 200}
                    />
                  </Draggable>
                );
              })}
            </ul>
          )}

          <DragOverlay>{dragItem && <RowOverlay kind={dragItem.recordType} title={dragItem.title} />}</DragOverlay>

          {showEmptyStateDelayed && (
            <div className={twMerge('flex flex-col items-center justify-center px-5 font-ABCDiatype pt-5', isInitialState ? 'min-[1440px]:p-10' : '!pt-2')}>
              {isInitialState && user && (
                <div className="max-w-[480px] text-center text-glass-700 pb-4 mt-9">
                  <h1 className="text-2xl font-bold mb-1">Welcome to Speechify, {user.displayName}</h1>
                  <p className="text-base font-medium">We are truly very happy to have you onboard! Add your first document and dive into listening.</p>
                </div>
              )}
              <div
                className={twMerge(
                  'w-full mt-4',
                  isInitialState && !isOnlyIntegration && 'max-w-[820px]',
                  isInitialState && isOnlyIntegration && `flex flex-wrap justify-center lg:flex-nowrap gap-4 max-w-[1000px]`
                )}
              >
                <div className="w-full">
                  <Dropzone fullWidth={!isInitialState || !isOnlyIntegration} autoHeight={!isInitialState} />
                </div>
                {showAIStory && (
                  <div
                    className="flex items-center p-4 rounded-lg mt-8"
                    style={{
                      background: 'radial-gradient(81.17% 215.06% at -34.74% -67.98%, #FFD3F8 3.38%, #F8F1FF 71.16%, #F1F4F9 100%)'
                    }}
                  >
                    <div className="flex-shrink-0 mr-4">
                      <ListeningHeadIcon className="w-12 h-12" />
                    </div>
                    <div className="flex-grow">
                      <h3 className="text-lg font-semibold">Create Your Reading</h3>
                      <p className="text-sm text-gray-600">Create your own AI story</p>
                    </div>
                    <button
                      className="px-4 py-2 bg-white rounded-md text-sm font-semibold transition-colors"
                      onClick={() => {
                        dispatch(importActions.openImportDialog());
                        dispatch(importActions.setImportSource('aiStory'));
                      }}
                    >
                      Create AI Story
                    </button>
                  </div>
                )}
                {isInitialState && (
                  <Integrations className={twMerge(isOnlyIntegration ? 'w-72 shrink-0' : 'pt-8')} itemClassName={twMerge(isOnlyIntegration && 'pt-9 pb-8')} />
                )}
              </div>
            </div>
          )}

          {isMoveSelectedModalOpen && (selectedItemIds.length > 0 || selectedFolderIds.length > 0) && (
            <GroupMoveToModal
              filesToMove={selectedItemIds.length}
              foldersToMove={selectedFolderIds.length}
              folders={folders.filter(folder => {
                const selectedFolders = selectedFolderIds.map(folderId => folders.find(folder => folder.id === folderId));

                return (
                  !selectedFolderIds.includes(folder.id) &&
                  selectedFolders.every(selectedFolder => !isDescendant(folders, selectedFolder, folder)) &&
                  folder.id !== folderId
                );
              })}
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              currentFolderId={folderId}
              onCancel={handleMoveSelectedModalClose}
              onSubmit={handleMoveSelectedModalSubmit}
            />
          )}

          {isSelectedDeleteConfirmModalOpen && (selectedItemIds.length > 0 || selectedFolderIds.length > 0) && (
            <ConfirmModal
              description={selectedDeleteConfirmationText}
              id={'delete-selected-items'}
              onCancel={handleSelectedDeleteCancel}
              onConfirm={handleSelectedDeleteConfirm}
              title={selectedDeleteConfirmationTitle}
            />
          )}

          <ImportDialog />
          <FilesProgress />
        </Fragment>
      </div>
    </div>
  );
};

export default Items;
