import { RecordType } from 'interfaces';
import React from 'react';

import RowIcon from './components/RowIcon';

export type RowOverlayProps = {
  kind: RecordType;
  title: string;
};

export default function RowOverlay(props: RowOverlayProps) {
  return (
    <div className="bg-white flex items-center p-2 rounded max-w-xs border border-glass-300 shadow-sm cursor-move">
      <div>
        <RowIcon kind={props.kind} />
      </div>
      <span className="ml-2 line-clamp-1 flex-1 font-ABCDiatype font-normal text-sm leading-5">{props.title}</span>
    </div>
  );
}
