import React from 'react';

export default function SuggestionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.93132 6.86163L6.02723 6.89712C7.45158 7.42418 8.5746 8.5472 9.10165 9.97155L9.13714 10.0675C9.27049 10.4278 9.61412 10.6671 9.99837 10.6671C10.3826 10.6671 10.7263 10.4278 10.8596 10.0675L10.8951 9.97155C11.4221 8.54719 12.5452 7.42418 13.9695 6.89713L14.0654 6.86163C14.4258 6.72828 14.665 6.38466 14.665 6.00041C14.665 5.61615 14.4258 5.27253 14.0654 5.13918L13.9695 5.10369C12.5452 4.57663 11.4221 3.45362 10.8951 2.02927L10.8596 1.93336C10.7263 1.57298 10.3826 1.33374 9.99837 1.33374C9.61412 1.33374 9.27049 1.57298 9.13714 1.93336L9.10165 2.02927C8.5746 3.45362 7.45158 4.57663 6.02723 5.10369L5.93132 5.13918C5.57095 5.27253 5.33171 5.61615 5.33171 6.00041C5.33171 6.38466 5.57095 6.72828 5.93132 6.86163Z"
        fill="url(#paint0_radial_15650_158416)"
      />
      <path
        d="M3.99837 9.33374C3.7788 9.33374 3.58244 9.47045 3.50624 9.67638L3.48596 9.73118C3.18479 10.5451 2.54307 11.1868 1.72915 11.488L1.67434 11.5083C1.46842 11.5845 1.33171 11.7808 1.33171 12.0004C1.33171 12.22 1.46842 12.4163 1.67434 12.4925L1.72915 12.5128C2.54307 12.814 3.18479 13.4557 3.48596 14.2696L3.50624 14.3244C3.58244 14.5304 3.7788 14.6671 3.99837 14.6671C4.21795 14.6671 4.4143 14.5304 4.4905 14.3244L4.51078 14.2696C4.81196 13.4557 5.45368 12.814 6.2676 12.5128L6.3224 12.4925C6.52833 12.4163 6.66504 12.22 6.66504 12.0004C6.66504 11.7808 6.52833 11.5845 6.3224 11.5083L6.2676 11.488C5.45368 11.1868 4.81196 10.5451 4.51078 9.73118L4.4905 9.67638C4.4143 9.47045 4.21795 9.33374 3.99837 9.33374Z"
        fill="url(#paint1_radial_15650_158416)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15650_158416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.3317 -0.666258) rotate(135.385) scale(35.1204)"
        >
          <stop offset="0.224035" stopColor="#EA6AFF" />
          <stop offset="0.664903" stopColor="#6B78FC" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_15650_158416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.3317 -0.666258) rotate(135.385) scale(35.1204)"
        >
          <stop offset="0.224035" stopColor="#EA6AFF" />
          <stop offset="0.664903" stopColor="#6B78FC" />
        </radialGradient>
      </defs>
    </svg>
  );
}
