import React from 'react';
function OpenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.1 18.18H3.6c-.917 0-1.667-.75-1.667-1.667V3.179c0-.916.75-1.666 1.667-1.666h6.666l5 5v5H13.6V7.346H9.433V3.179H3.6v13.334h7.5v1.666zm3.333-.284v-1.867l2.458 2.459 1.175-1.175-2.458-2.467h1.867v-1.667h-4.709v4.717h1.667z"
        fill="#587393"
      />
    </svg>
  );
}

export default OpenIcon;
