import React from 'react';

const ShareIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.166 5.833h-3.333V7.5h3.333c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5h-3.333v1.666h3.333c2.3 0 4.167-1.866 4.167-4.166 0-2.3-1.866-4.167-4.166-4.167zm-5 6.667H5.833a2.507 2.507 0 01-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h3.333V5.833H5.833A4.168 4.168 0 001.666 10c0 2.3 1.867 4.166 4.167 4.166h3.333V12.5zm-2.5-3.334h6.667v1.667H6.666V9.166z"
        fill="#587393"
      />
    </svg>
  );
};

export default ShareIcon;
