import React from 'react';

export default function SharedFolder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="14" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 14.1046 0.89543 15 2 15L16 15C17.1046 15 18 14.1046 18 13V3.99596C18 2.89139 17.1046 1.99596 16 1.99596L7.77866 1.99596L6.91953 0.820101C6.54295 0.304693 5.94297 0 5.30465 0H2C0.895432 0 0 0.895431 0 2V13ZM8.46432 8.5C9.29275 8.5 9.96432 7.82843 9.96432 7C9.96432 6.17157 9.29275 5.5 8.46432 5.5C7.6359 5.5 6.96432 6.17157 6.96432 7C6.96432 7.82843 7.6359 8.5 8.46432 8.5ZM8.46432 8.5C7.66868 8.5 6.90561 8.81607 6.34301 9.37868C5.82769 9.89399 5.51921 10.5775 5.47098 11.3001C5.46363 11.4103 5.55387 11.5 5.66432 11.5H11.2643C11.3748 11.5 11.465 11.4103 11.4577 11.3001C11.4094 10.5775 11.101 9.89399 10.5856 9.37868C10.023 8.81607 9.25997 8.5 8.46432 8.5ZM14.4643 7C14.4643 7.82843 13.7928 8.5 12.9643 8.5C12.1359 8.5 11.4643 7.82843 11.4643 7C11.4643 6.17157 12.1359 5.5 12.9643 5.5C13.7928 5.5 14.4643 6.17157 14.4643 7ZM12.9645 8.5C12.4354 8.50007 11.9171 8.64005 11.4615 8.90387C11.3573 8.96425 11.3408 9.10443 11.4152 9.19913C11.8889 9.80133 12.1661 10.5351 12.2087 11.3005C12.2149 11.4106 12.3038 11.5 12.4141 11.5H15.7645C15.875 11.5 15.9652 11.4103 15.9578 11.3001C15.9096 10.5775 15.6011 9.89399 15.0858 9.37868C14.5232 8.81607 13.7601 8.5 12.9645 8.5Z"
        fill="#587393"
      />
    </svg>
  );
}
