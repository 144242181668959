import React from 'react';
export default function Checked() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.01668 10.5C5.35576 10.5 5.62217 10.3712 5.80624 10.104L10.3159 3.33351C10.4467 3.13789 10.5 2.95181 10.5 2.78004C10.5 2.31723 10.1416 1.97369 9.65716 1.97369C9.32777 1.97369 9.11948 2.09298 8.91604 2.40311L4.99731 8.49129L3.0113 6.09133C2.82723 5.87185 2.62379 5.77642 2.34284 5.77642C1.85361 5.77642 1.5 6.11995 1.5 6.58754C1.5 6.79748 1.56297 6.97879 1.74704 7.18395L4.2465 10.1422C4.45479 10.3855 4.69699 10.5 5.01668 10.5Z"
        fill="#587393"
      />
    </svg>
  );
}
