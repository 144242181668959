import React from 'react';

export default function WebSourceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1372 6.60987C22.3516 2.39546 29.1845 2.39547 33.3989 6.60987C37.6134 10.8243 37.6134 17.6572 33.3989 21.8716L31.1731 24.0974C30.5873 24.6832 29.6376 24.6832 29.0518 24.0974C28.466 23.5116 28.466 22.5619 29.0518 21.9761L31.2776 19.7503C34.3205 16.7074 34.3205 11.774 31.2776 8.73119C28.2348 5.68836 23.3014 5.68836 20.2585 8.73119L18.0327 10.957C17.4469 11.5428 16.4972 11.5428 15.9114 10.957C15.3256 10.3712 15.3256 9.42149 15.9114 8.83571L18.1372 6.60987ZM26.1006 13.9005C26.6863 14.4863 26.6861 15.4361 26.1003 16.0218L16.0214 26.0973C15.4356 26.683 14.4858 26.6828 13.9001 26.0969C13.3144 25.511 13.3146 24.5613 13.9005 23.9756L23.9793 13.9001C24.5652 13.3144 25.5149 13.3146 26.1006 13.9005ZM10.9401 18.0365C11.5259 17.4507 11.5259 16.5009 10.9401 15.9152C10.3543 15.3294 9.40454 15.3294 8.81876 15.9152L6.59893 18.135C2.38573 22.3482 2.38572 29.1791 6.59892 33.3923C10.8121 37.6055 17.6431 37.6055 21.8563 33.3923L24.0761 31.1725C24.6619 30.5867 24.6619 29.637 24.0761 29.0512C23.4903 28.4654 22.5406 28.4654 21.9548 29.0512L19.7349 31.271C16.6933 34.3126 11.7619 34.3126 8.72024 31.271C5.67862 28.2294 5.67862 23.2979 8.72025 20.2563L10.9401 18.0365Z"
        fill="#587393"
      />
    </svg>
  );
}
