import { useDroppable } from '@dnd-kit/core';
import { IRecord, ItemActionType, UUID } from 'interfaces';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import Folder from 'assets/icons/folder';
import { useNavigate } from 'hooks/useNavigate';

import ItemActionMenu from './ActionMenu';

interface FoldersNavigationProps {
  folders: IRecord[];
  itemWidth?: string;
  selectedFolderIds: UUID[];
  selectedFileIds: UUID[];
  handleFolderClick: (folderId?: string) => void;
  onItemAction: (item: IRecord, actionType: ItemActionType, location?: string) => void;
}

// ESLint: 'itemWidth' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FoldersNavigation = ({ folders, itemWidth, handleFolderClick, onItemAction, selectedFolderIds, selectedFileIds }: FoldersNavigationProps) => {
  const navigate = useNavigate();
  const handleFolderAction = (folder: IRecord, actionType: ItemActionType) => {
    switch (actionType) {
      case ItemActionType.Open:
        navigate(`/?folder=${folder.id}`);
        break;
      default:
        onItemAction(folder, actionType);
    }
    onItemAction(folder, actionType);
  };

  const handleSelectionChange = (folder: IRecord, selected: $TSFixMe) => {
    onItemAction(folder, selected ? ItemActionType.Unselect : ItemActionType.Select);
  };

  return (
    <div className="flex flex-col pl-5 pb-0 pt-5 pr-5">
      <div className="flex-none font-ABCDiatype text-lg font-bold text-glass-700 mb-4">Folders</div>
      <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {folders.map(item => {
          const selected = selectedFolderIds.includes(item.id);
          const selectionStarted = selectedFolderIds.length > 0 || selectedFileIds.length > 0;
          return (
            <FolderTile
              id={item.id}
              onClick={() => {
                if (selectionStarted) {
                  return handleSelectionChange(item, selected);
                }
                return handleFolderClick(item.id);
              }}
              title={item.title}
              selected={selected}
              handleSelectionChange={() => handleSelectionChange(item, selected)}
              selectionStarted={selectionStarted}
              key={item.id}
              handleFolderAction={(actionType: $TSFixMe) => handleFolderAction(item, actionType)}
            />
          );
        })}
      </ul>
    </div>
  );
};

// ESLint: 'width' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FolderTile = ({ id, onClick, width, title, handleFolderAction, handleSelectionChange, selected, selectionStarted }: $TSFixMe) => {
  const { isOver, setNodeRef } = useDroppable({ id: `library-item-folder-nav-${id}-drop` });
  return (
    <div
      onClick={onClick}
      ref={setNodeRef}
      className={twMerge(
        'group flex cursor-pointer items-center justify-between rounded-md border border-glass-200 bg-glass-200  py-2 pl-4 pr-3 hover:bg-[#E8EFFF]',
        isOver && 'border-glass-400 border bg-[#E8EFFF]',
        selected && 'bg-[#E8EFFF]'
      )}
    >
      <div data-tip data-for={`tooltip-nav-folder-${id}`} className="relative flex flex-row items-center w-full gap-2">
        <div className="flex items-center w-5">
          <Folder
            className={
              selectionStarted ? 'h-0 w-0 min-w-0 opacity-0' : 'h-5 w-5 min-w-[20px] group-hover:h-0 group-hover:w-0 group-hover:opacity-0 group-hover:min-w-0'
            }
          />

          <input
            className={twMerge(
              'relative cursor-pointer border-2 border-glass-400 outline-none  focus:ring-0 hover:bg-glass-200 ',
              selectionStarted ? ' h-4 w-4 opacity-100' : ' h-0 w-0  opacity-0 group-hover:h-4 group-hover:w-4 group-hover:opacity-100'
            )}
            type="checkbox"
            checked={selected}
            onClick={e => e.stopPropagation()}
            onChange={handleSelectionChange}
          />
        </div>
        <div className=" line-clamp-1 flex-1 font-ABCDiatype text-sm font-medium leading-5 text-glass-700">{title}</div>
      </div>
      <div className={twMerge('mt-1 items-center visible')}>
        <ItemActionMenu isFolder={true} onAction={handleFolderAction} />
      </div>
    </div>
  );
};

export default FoldersNavigation;
