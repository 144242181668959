import React from 'react';

export default function EmptyFolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90326 51.0486C8.04512 34.3791 8.61606 26.0443 17.5616 17.1207C26.5071 8.19712 34.7797 7.65089 51.3248 6.55841C59.5964 6.01223 69.1604 5.65216 80.0008 5.65216C90.8411 5.65216 100.405 6.01223 108.677 6.55841C125.222 7.65089 133.494 8.19712 142.44 17.1207C151.385 26.0443 151.956 34.3791 153.098 51.0486C153.67 59.3958 154.05 68.9783 154.05 79.7017C154.05 89.8062 153.713 98.8976 153.195 106.897C152.063 124.398 151.498 133.148 142.556 142.112C133.614 151.076 124.803 151.668 107.18 152.851C99.1016 153.394 89.9898 153.751 80.0008 153.751C70.0118 153.751 60.8999 153.394 52.8213 152.851C35.1986 151.668 26.3873 151.076 17.4456 142.112C8.50396 133.148 7.93804 124.398 6.80619 106.897C6.28884 98.8976 5.95117 89.8062 5.95117 79.7017C5.95117 68.9783 6.33148 59.3958 6.90326 51.0486Z"
        fill="#F5F4FA"
      />
      <path
        d="M132.284 109.002L125.815 47.2004C125.511 44.2951 122.91 42.1864 120.004 42.4905L73.0712 47.4028C71.5815 47.5587 70.0956 47.0757 68.9823 46.0737L58.8348 36.9409C57.7214 35.9389 56.2355 35.4559 54.7458 35.6118L28.9666 38.31C26.0613 38.6141 23.9526 41.2158 24.2567 44.1211L31.938 117.511C32.3562 121.506 35.9335 124.405 39.9283 123.987L127.574 114.814C130.479 114.509 132.588 111.908 132.284 109.002Z"
        fill="url(#paint0_linear_14789_126114)"
      />
      <path
        d="M141.401 58.2241C143.192 58.0337 144.645 59.6492 144.266 61.4095L134.041 108.992C133.365 112.134 130.746 114.485 127.55 114.819L40.3057 123.928C41.9266 123.203 43.1587 121.765 43.6024 120.012L43.6223 119.992L54.1061 71.1644C54.5858 68.9303 56.446 67.2568 58.7183 67.0152L141.401 58.2241Z"
        fill="url(#paint1_linear_14789_126114)"
      />
      <defs>
        <linearGradient id="paint0_linear_14789_126114" x1="-130.094" y1="24.5133" x2="272.279" y2="363.337" gradientUnits="userSpaceOnUse">
          <stop offset="0.294092" stopColor="#E4E2EA" />
          <stop offset="0.868705" stopColor="#706E80" />
        </linearGradient>
        <linearGradient id="paint1_linear_14789_126114" x1="88.0987" y1="146.914" x2="23.895" y2="117.875" gradientUnits="userSpaceOnUse">
          <stop offset="0.1041" stopColor="#E7E6EC" />
          <stop offset="0.695866" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
