import { useMemo } from 'react';

import { OneDriveIntegrationTestVariant } from 'config/constants/featureDefinitions';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import { IntegrationService } from 'interfaces/integrations';

export function useAvailableIntegrations(): IntegrationService[] {
  const oneDriveIntegrationVariant = useFeatureVariant(FeatureNameEnum.ONE_DRIVE_INTEGRATION);
  const isOneDriveIntegrationEnabled =
    isFeatureVariantReady(oneDriveIntegrationVariant) && oneDriveIntegrationVariant.variant === OneDriveIntegrationTestVariant.ENABLED;
  const enabledIntegrations = useMemo(
    () => Object.values(IntegrationService).filter(service => service !== IntegrationService.ONE_DRIVE || isOneDriveIntegrationEnabled),
    [isOneDriveIntegrationEnabled]
  );

  return enabledIntegrations;
}
