import Loading from 'components/elements/Loading';
import React from 'react';

export const ItemCardPlaceholder = () => {
  return (
    <li className="relative col-span-1 flex select-none flex-col items-center rounded-lg bg-gray-200 shadow-md hover:shadow" style={{ minHeight: 256 }}>
      <div className="mt-32">
        <Loading />
      </div>
    </li>
  );
};

export default ItemCardPlaceholder;
