import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';

import SuggestionIcon from 'assets/icons/integrations/suggestion';
import { useInterval } from 'hooks/useInterval';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationFile } from 'interfaces/integrations';
import { auth } from 'lib/speechify';
import { actions as integrationActions } from 'store/integration';

import { IntegrationTitles } from './constants';
import { FileIcon } from './elements/FileIcon';
import { useFileAction } from './hooks/useFileAction';

function SuggestedItem({ item }: { item: IntegrationFile }) {
  const { t } = useTranslation('common');
  const [importState, handleClick] = useFileAction(item.service, item);

  return (
    <div
      style={{ backgroundImage: 'radial-gradient(58.67% 180.56% at 0% 0%,#EEE8FF 3.38%,#F1F4F9 100%)' }}
      className={twMerge(
        'flex items-center gap-2 min-h-11 h-full px-2 cursor-pointer',
        'bg-[#F1F4F9] bg-no-repeat hover:bg-[-30rem_0] transition-background duration-300 ease-in-out'
      )}
      onClick={handleClick}
    >
      <div className="relative w-5 flex justify-center">
        {item.imageUrl ? (
          <img src={item.imageUrl} className="w-4 max-h-5 rounded-[1px] outline outline-1 outline-glass-300" />
        ) : (
          <FileIcon mimeType={item.mimeType} className="-mt-px shrink-0 w-4 h-5" />
        )}
        <SuggestionIcon className="absolute -top-2 -left-1.5" />
      </div>
      <div className="text-glass-700 font-medium">{item.name}</div>
      <div className="rounded-full w-[3px] h-[3px] bg-text-secondary" />
      <div className="text-xs text-text-secondary">
        {t('Suggestion from')} {IntegrationTitles[item.service].name}
      </div>
      {importState === 'importing' ? (
        <div className="ml-auto mr-2.5 inline-block align-middle bg-glass-300 rounded-xl h-1 w-10 mt-[-2px] overflow-hidden">
          <div className="bg-electric-400 animate-indeterminiteProgress rounded-xl h-1" />
        </div>
      ) : (
        <button
          className={twMerge(
            'ml-auto px-2.5 py-1.5',
            'font-bold text-electric-400 hover:text-electric-hovered-400 active:text-electric-pressed-400',
            'transition-colors duration-75'
          )}
        >
          {t('Import')}
        </button>
      )}
    </div>
  );
}

export function SuggestedItems({ className }: { className?: string }) {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const userUid = user?.uid && auth.currentUser?.uid;
  const suggestedItems = useSelector(state => state.integration.suggestedItems);
  const importedItems = useSelector(state => state.integration.importedItems);
  const suggestionsDisabled = useSelector(state => state.integration.suggestionDisabled || !!state.library.currentFolderId);

  useEffect(() => {
    if (userUid && !suggestionsDisabled) {
      dispatch(integrationActions.fetchSuggestions());
    }
  }, [dispatch, importedItems, userUid, suggestionsDisabled]);

  useInterval(
    () => {
      if (userUid && !suggestionsDisabled) {
        dispatch(integrationActions.fetchSuggestions());
      }
    },
    suggestionsDisabled ? null : 60_000
  );

  if (suggestionsDisabled || !suggestedItems?.length) return <></>;

  return <div className={twMerge('font-ABCDiatype text-sm px-8', className)}>{suggestedItems?.map(item => <SuggestedItem key={item.id} item={item} />)}</div>;
}
