import CanvasIntegration from 'components/settings/Integrations/canvas';
import React from 'react';
import { useSelector } from 'store';

import { useCanvasData } from './hooks/useCanvasData';

export function CanvasImport() {
  const user = useSelector(state => state.auth.user);
  const { canvasData, setCanvasData } = useCanvasData();

  return user?.uid ? (
    <div className="p-2 pb-5 h-full overflow-y-auto">
      <div className="w-fit mx-auto">
        <CanvasIntegration user={user} canvasData={canvasData} setCanvasData={setCanvasData} />
      </div>
    </div>
  ) : null;
}
