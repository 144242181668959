import React from 'react';

export function CardViewIcon(props: $TSFixMe) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 12.1358C3.33325 11.2476 4.05325 10.5276 4.94142 10.5276H7.86536C8.75353 10.5276 9.47353 11.2476 9.47353 12.1358V15.0597C9.47353 15.9479 8.75353 16.6679 7.86536 16.6679H4.94142C4.05325 16.6679 3.33325 15.9479 3.33325 15.0597V12.1358ZM4.94142 11.7946C4.75302 11.7946 4.60029 11.9474 4.60029 12.1358V15.0597C4.60029 15.2481 4.75302 15.4008 4.94142 15.4008H7.86536C8.05376 15.4008 8.20649 15.2481 8.20649 15.0597V12.1358C8.20649 11.9474 8.05376 11.7946 7.86536 11.7946H4.94142Z"
        fill="#587393"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 4.94142C3.33325 4.05325 4.05325 3.33325 4.94142 3.33325H7.86536C8.75353 3.33325 9.47353 4.05325 9.47353 4.94142V7.86536C9.47353 8.75353 8.75353 9.47353 7.86536 9.47353H4.94142C4.05325 9.47353 3.33325 8.75353 3.33325 7.86536V4.94142ZM4.94142 4.60029C4.75302 4.60029 4.60029 4.75302 4.60029 4.94142V7.86536C4.60029 8.05376 4.75302 8.20649 4.94142 8.20649H7.86536C8.05376 8.20649 8.20649 8.05376 8.20649 7.86536V4.94142C8.20649 4.75302 8.05376 4.60029 7.86536 4.60029H4.94142Z"
        fill="#587393"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5277 12.1358C10.5277 11.2476 11.2477 10.5276 12.1359 10.5276H15.0598C15.948 10.5276 16.668 11.2476 16.668 12.1358V15.0597C16.668 15.9479 15.948 16.6679 15.0598 16.6679H12.1359C11.2477 16.6679 10.5277 15.9479 10.5277 15.0597V12.1358ZM12.1359 11.7946C11.9475 11.7946 11.7948 11.9474 11.7948 12.1358V15.0597C11.7948 15.2481 11.9475 15.4008 12.1359 15.4008H15.0598C15.2482 15.4008 15.4009 15.2481 15.4009 15.0597V12.1358C15.4009 11.9474 15.2482 11.7946 15.0598 11.7946H12.1359Z"
        fill="#587393"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5277 4.94142C10.5277 4.05325 11.2477 3.33325 12.1359 3.33325H15.0598C15.948 3.33325 16.668 4.05325 16.668 4.94142V7.86536C16.668 8.75353 15.948 9.47353 15.0598 9.47353H12.1359C11.2477 9.47353 10.5277 8.75353 10.5277 7.86536V4.94142ZM12.1359 4.60029C11.9475 4.60029 11.7948 4.75302 11.7948 4.94142V7.86536C11.7948 8.05376 11.9475 8.20649 12.1359 8.20649H15.0598C15.2482 8.20649 15.4009 8.05376 15.4009 7.86536V4.94142C15.4009 4.75302 15.2482 4.60029 15.0598 4.60029H12.1359Z"
        fill="#587393"
      />
    </svg>
  );
}

export function ListViewIcon(props: $TSFixMe) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16671 3.3335C3.70647 3.3335 3.33337 3.70659 3.33337 4.16683C3.33337 4.62707 3.70647 5.00016 4.16671 5.00016H15.8334C16.2936 5.00016 16.6667 4.62707 16.6667 4.16683C16.6667 3.70659 16.2936 3.3335 15.8334 3.3335H4.16671ZM4.16671 7.22239C3.70647 7.22239 3.33337 7.59548 3.33337 8.05572C3.33337 8.51596 3.70647 8.88905 4.16671 8.88905H15.8334C16.2936 8.88905 16.6667 8.51596 16.6667 8.05572C16.6667 7.59548 16.2936 7.22239 15.8334 7.22239H4.16671ZM3.33337 11.9446C3.33337 11.4844 3.70647 11.1113 4.16671 11.1113H15.8334C16.2936 11.1113 16.6667 11.4844 16.6667 11.9446C16.6667 12.4048 16.2936 12.7779 15.8334 12.7779H4.16671C3.70647 12.7779 3.33337 12.4048 3.33337 11.9446ZM4.16671 15.0002C3.70647 15.0002 3.33337 15.3733 3.33337 15.8335C3.33337 16.2937 3.70647 16.6668 4.16671 16.6668H15.8334C16.2936 16.6668 16.6667 16.2937 16.6667 15.8335C16.6667 15.3733 16.2936 15.0002 15.8334 15.0002H4.16671Z"
        fill="#587393"
      />
    </svg>
  );
}
