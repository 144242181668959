import React from 'react';

export default function XlsxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="56" viewBox="0 0 42 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.608594 5.16667C0.608594 2.92761 2.42371 1.1125 4.66276 1.1125H23.7298C24.805 1.1125 25.8362 1.53963 26.5965 2.29994L40.1962 15.8996C40.9565 16.6599 41.3836 17.6911 41.3836 18.7663V51.8333C41.3836 54.0724 39.5685 55.8875 37.3294 55.8875H4.66276C2.42371 55.8875 0.608594 54.0724 0.608594 51.8333V5.16667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M26.454 49.4941H24.8906L27.0256 46.6008L25.0656 43.8008H26.6756L27.4573 44.9908C27.574 45.1658 27.679 45.3408 27.7956 45.5624C27.9123 45.3408 28.029 45.1658 28.1456 44.9908L28.9623 43.8008H30.5256L28.5073 46.5541L30.584 49.4941H28.9623L28.0756 48.1174C27.9823 47.9774 27.854 47.7674 27.7373 47.5808C27.6323 47.7674 27.5156 47.9658 27.4106 48.1174L26.454 49.4941Z"
        fill="#34C759"
      />
      <path
        d="M22.4762 49.7033C20.8662 49.7033 19.8746 48.8633 19.7812 47.5333H21.2279C21.2629 48.2099 21.6829 48.6416 22.4413 48.6416C23.0829 48.6416 23.4912 48.3383 23.4912 47.9066C23.4912 47.5216 23.1646 47.3466 22.5579 47.2299L21.8696 47.0899C20.6796 46.8566 20.0029 46.2966 20.0029 45.3516C20.0029 44.3599 20.9362 43.6133 22.3363 43.6133C23.8646 43.6133 24.8212 44.4066 24.8562 45.5966H23.4329C23.3862 45.0366 22.9779 44.6749 22.3363 44.6749C21.8112 44.6749 21.4029 44.8849 21.4029 45.2933C21.4029 45.6316 21.6246 45.8066 22.3479 45.9466L23.0362 46.0866C24.1562 46.3083 24.8912 46.8216 24.8912 47.8249C24.8912 48.9099 23.9462 49.7033 22.4762 49.7033Z"
        fill="#34C759"
      />
      <path d="M17.5664 41.3281H18.9897V49.4948H17.5664V41.3281Z" fill="#34C759" />
      <path
        d="M12.9071 49.4941H11.3438L13.4788 46.6008L11.5187 43.8008H13.1287L13.9104 44.9908C14.0271 45.1658 14.1321 45.3408 14.2487 45.5624C14.3654 45.3408 14.4821 45.1658 14.5987 44.9908L15.4154 43.8008H16.9788L14.9604 46.5541L17.0371 49.4941H15.4154L14.5288 48.1174C14.4354 47.9774 14.3071 47.7674 14.1904 47.5808C14.0854 47.7674 13.9687 47.9658 13.8637 48.1174L12.9071 49.4941Z"
        fill="#34C759"
      />
      <path
        d="M26.2727 12.1667V1.97871L40.5148 16.2208H30.3268C28.0878 16.2208 26.2727 14.4057 26.2727 12.1667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M10.1133 32.4321H20.0226V36.6693H21.9935V32.4321H31.8937V30.6298H21.9935V26.3754H31.8937V24.5731H21.9935V20.3359H20.0226V24.5731H10.1133V26.3754H20.0226V30.6298H10.1133V32.4321ZM13.2208 36.6693C12.2064 36.6693 11.4333 36.4209 10.9016 35.9241C10.3761 35.4331 10.1133 34.7139 10.1133 33.7665V23.2387C10.1133 22.2913 10.3761 21.5721 10.9016 21.0811C11.4333 20.5843 12.2064 20.3359 13.2208 20.3359H28.7953C29.8098 20.3359 30.5798 20.5843 31.1054 21.0811C31.6309 21.5721 31.8937 22.2913 31.8937 23.2387V33.7665C31.8937 34.7081 31.6309 35.4273 31.1054 35.9241C30.5798 36.4209 29.8098 36.6693 28.7953 36.6693H13.2208Z"
        fill="#34C759"
      />
    </svg>
  );
}
