import React from 'react';
export default function Edge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 23.481C4 15.673 10.557 4 24.014 4C37.452 4 44 13.923 44 20.905C44 26.509 39.571 30.637 34.008 30.637C29.763 30.637 27.249 29.229 27.249 28.107C27.249 27.381 29.004 26.27 29.004 24.125C29.004 21.56 27.018 18.988 23.959 18.988C21.025 18.988 18.985 21.43 18.985 23.893C18.985 29.845 24.615 35.537 32.916 35.537C37.875 35.537 39.675 33.709 40.319 33.709C40.515 33.709 40.966 33.789 40.966 34.312C40.966 34.812 35.48 43.998 24.026 43.998C12.632 44 4 35.023 4 23.481Z"
        fill="#1DE9B6"
      />
      <path
        d="M4 23.481C4 15.673 11.615 11.441 16.73 11.441C30.169 11.441 34.007 13.924 34.007 20.906C34.007 26.51 29.004 26.272 29.004 24.127C29.004 21.562 27.018 18.99 23.959 18.99C21.025 18.99 18.985 21.432 18.985 23.895C18.985 29.847 24.615 35.539 32.916 35.539C37.875 35.539 39.675 33.711 40.319 33.711C40.515 33.711 40.966 33.791 40.966 34.314C40.966 34.814 35.48 44 24.026 44C12.632 44 4 35.023 4 23.481Z"
        fill="url(#paint0_radial_8769_75076)"
      />
      <path
        d="M18.985 23.8939C18.985 21.5959 20.76 19.3189 23.38 19.0229C19.276 19.3719 14.582 23.3979 14.582 30.8539C14.582 38.8169 21.446 44.3189 28.525 43.4819C36.911 41.5549 40.966 34.7419 40.966 34.3139C40.966 33.7909 40.515 33.7109 40.319 33.7109C39.676 33.7109 37.875 35.5389 32.916 35.5389C24.615 35.5389 18.985 29.8469 18.985 23.8939Z"
        fill="url(#paint1_radial_8769_75076)"
      />
      <path
        d="M4 23.481C4 15.673 10.557 4 24.014 4C37.452 4 44 13.923 44 20.905C44 26.509 39.571 30.637 34.008 30.637C29.763 30.637 27.249 29.229 27.249 28.107C27.249 27.381 29.004 26.27 29.004 24.125C29.004 21.56 26.928 13.447 16.376 13.447C13.442 13.448 5.256 14.745 4 23.481Z"
        fill="url(#paint2_radial_8769_75076)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_8769_75076"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.993 32.851) scale(21.348 23.66)"
        >
          <stop offset="0.652" stopColor="#0083DE" />
          <stop offset="0.822" stopColor="#006AAC" />
          <stop offset="0.944" stopColor="#005A8C" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_8769_75076"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.082 29.31) scale(16.478 16.5241)"
        >
          <stop offset="0.69" stopColor="#05509B" />
          <stop offset="0.717" stopColor="#054E98" />
          <stop offset="1" stopColor="#033E78" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8769_75076"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.648 16.0564) rotate(180) scale(61.532 92.8149)"
        >
          <stop stopColor="#5EEB69" />
          <stop offset="0.214" stopColor="#4BC958" />
          <stop offset="0.225" stopColor="#4AC862" />
          <stop offset="0.272" stopColor="#47C68B" />
          <stop offset="0.323" stopColor="#44C4AE" />
          <stop offset="0.376" stopColor="#41C2CB" />
          <stop offset="0.433" stopColor="#3FC1E1" />
          <stop offset="0.495" stopColor="#3EC0F1" />
          <stop offset="0.565" stopColor="#3DBFFA" />
          <stop offset="0.664" stopColor="#3DBFFD" />
        </radialGradient>
      </defs>
    </svg>
  );
}
