import React, { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import InsideRight from 'assets/icons/inside';
import { IIntegrationFolder, IntegrationFileType, IntegrationService } from 'interfaces/integrations';

import { IntegrationTitles, ROOT_FOLDER_ID, SHARED_FOLDER } from '../constants';

interface BreadcrumbProps {
  folder: IIntegrationFolder;
  onClick?: (folderId: string | null) => void;
}

function Breadcrumb({ folder, onClick }: BreadcrumbProps) {
  const isRootFolder = folder.id === ROOT_FOLDER_ID;

  const handleClick = useCallback(() => {
    onClick?.(folder.id !== ROOT_FOLDER_ID ? folder.id : null);
  }, [onClick, folder.id]);

  if (!onClick) {
    return <span className="whitespace-nowrap text-glass-700">{folder.name}</span>;
  }

  return (
    <>
      <span className={twMerge('whitespace-nowrap cursor-pointer text-glass-500', !isRootFolder && 'overflow-hidden text-ellipsis')} onClick={handleClick}>
        {folder.name}
      </span>
      <span>
        <InsideRight />
      </span>
    </>
  );
}

interface BreadcrumbsProps {
  service: IntegrationService;
  path: string[];
  folders: IIntegrationFolder[];
  onClick: (folderId: string | null) => void;
}

export function Breadcrumbs({ service, path, folders, onClick }: BreadcrumbsProps) {
  const foldersMap = useMemo<Record<string, IIntegrationFolder>>(
    () =>
      folders.reduce(
        (map, folder) => ({
          ...map,
          [folder.id]: folder
        }),
        {
          [ROOT_FOLDER_ID]: {
            id: ROOT_FOLDER_ID,
            name: IntegrationTitles[service].name,
            directory: null,
            createdTime: null,
            type: IntegrationFileType.FOLDER
          },
          [SHARED_FOLDER.id]: SHARED_FOLDER
        }
      ),
    [folders, service]
  );
  const breadcrumbs = useMemo<IIntegrationFolder[]>(() => [foldersMap[ROOT_FOLDER_ID], ...path.map(folderId => foldersMap[folderId])], [path, foldersMap]);

  return (
    <div className="flex items-center gap-2 text-xl font-semibold font-ABCDiatype">
      {breadcrumbs.slice(0, -1).map(folder => (
        <Breadcrumb key={folder.id} folder={folder} onClick={onClick} />
      ))}
      <Breadcrumb folder={breadcrumbs[breadcrumbs.length - 1]} />
    </div>
  );
}
