import React from 'react';

export default function TxtIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="56" viewBox="0 0 42 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.604688 5.16667C0.604688 2.92761 2.4198 1.1125 4.65885 1.1125H23.7259C24.8011 1.1125 25.8323 1.53963 26.5926 2.29994L40.1922 15.8996C40.9526 16.6599 41.3797 17.6911 41.3797 18.7663V51.8333C41.3797 54.0724 39.5646 55.8875 37.3255 55.8875H4.65886C2.4198 55.8875 0.604688 54.0724 0.604688 51.8333V5.16667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M26.857 49.6095C25.6437 49.6095 25.002 49.0028 25.002 47.7311V44.9428H23.707V43.8111H25.002V41.7578H26.4254V43.8111H27.8254V44.9428H26.4254V47.5211C26.4254 48.1161 26.6587 48.4661 27.2654 48.4661C27.4054 48.4661 27.557 48.4545 27.697 48.4311V49.5045C27.557 49.5511 27.2187 49.6095 26.857 49.6095Z"
        fill="#5856D6"
      />
      <path
        d="M19.6688 49.5019H18.1055L20.2405 46.6086L18.2805 43.8086H19.8905L20.6721 44.9986C20.7888 45.1736 20.8938 45.3486 21.0105 45.5703C21.1271 45.3486 21.2438 45.1736 21.3605 44.9986L22.1771 43.8086H23.7405L21.7221 46.5619L23.7988 49.5019H22.1771L21.2905 48.1253C21.1971 47.9853 21.0688 47.7753 20.9521 47.5886C20.8471 47.7753 20.7305 47.9736 20.6255 48.1253L19.6688 49.5019Z"
        fill="#5856D6"
      />
      <path
        d="M17.1656 49.6095C15.9523 49.6095 15.3106 49.0028 15.3106 47.7311V44.9428H14.0156V43.8111H15.3106V41.7578H16.734V43.8111H18.134V44.9428H16.734V47.5211C16.734 48.1161 16.9673 48.4661 17.574 48.4661C17.714 48.4661 17.8656 48.4545 18.0056 48.4311V49.5045C17.8656 49.5511 17.5273 49.6095 17.1656 49.6095Z"
        fill="#5856D6"
      />
      <path
        d="M26.2688 12.1667V1.97871L40.5109 16.2208H30.3229C28.0839 16.2208 26.2688 14.4057 26.2688 12.1667Z"
        fill="#F2F2F7"
        stroke="#E5E5EA"
        strokeWidth="1.225"
      />
      <path
        d="M30.1688 19.1641L30.3229 24.322H29.0476C28.8421 23.3309 28.5712 22.5811 28.2348 22.0726C27.9078 21.5556 27.4267 21.1979 26.7914 20.9997C26.1561 20.8015 25.2779 20.6765 24.1568 20.6248L23.4701 20.599V34.7541C23.4701 35.1764 23.5261 35.5082 23.6382 35.7495C23.7503 35.9822 23.9792 36.1631 24.3249 36.2924C24.6706 36.4131 25.1985 36.5122 25.9085 36.5897V37.8307H16.0707V36.5897C16.7807 36.5122 17.3086 36.4131 17.6542 36.2924C17.9999 36.1631 18.2242 35.9822 18.3269 35.7495C18.439 35.5082 18.4951 35.1764 18.4951 34.7541V20.599L17.8224 20.6248C16.7013 20.6765 15.8231 20.8015 15.1878 20.9997C14.5525 21.1979 14.0667 21.5513 13.7303 22.0597C13.4033 22.5682 13.1371 23.3223 12.9315 24.322H11.6562L11.8104 19.1641H30.1688Z"
        fill="#5856D6"
      />
    </svg>
  );
}
