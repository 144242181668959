import React from 'react';

export default function Folder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={14} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.825.825A1.672 1.672 0 006.64.333H2.333C1.416.333.675 1.083.675 2L.667 12c0 .917.75 1.667 1.666 1.667h13.334c.916 0 1.666-.75 1.666-1.667V3.667c0-.917-.75-1.667-1.666-1.667H9L7.825.825z"
        fill="#587393"
      />
    </svg>
  );
}
