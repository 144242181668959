import React from 'react';
const RenameIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5 13.333l-3.333 3.333H17.5v-3.333h-5zM10.05 5.991l-7.55 7.55v3.125h3.125l7.55-7.55-3.125-3.125zM4.933 15h-.766v-.767L10.05 8.35l.767.766L4.933 15zM15.592 6.7a.83.83 0 000-1.175l-1.95-1.95a.834.834 0 00-1.175 0L10.942 5.1l3.125 3.125L15.592 6.7z"
        fill="#587393"
      />
    </svg>
  );
};

export default RenameIcon;
