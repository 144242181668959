import React from 'react';
export default function Chrome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8769_75081)">
        <path
          d="M24 33.9956C29.5228 33.9956 34 29.5185 34 23.9956C34 18.4728 29.5228 13.9956 24 13.9956C18.4772 13.9956 14 18.4728 14 23.9956C14 29.5185 18.4772 33.9956 24 33.9956Z"
          fill="white"
        />
        <path
          d="M24.0001 14H41.3177C39.5629 10.9595 37.0385 8.43462 33.9983 6.67921C30.9581 4.9238 27.5093 3.99976 23.9987 4C20.4882 4.00024 17.0395 4.92477 13.9996 6.6806C10.9596 8.43644 8.43557 10.9617 6.68115 14.0025L15.34 29L15.3477 28.998C14.4669 27.4791 14.0021 25.7548 14.0001 23.9989C13.9981 22.2431 14.4591 20.5177 15.3365 18.9968C16.2139 17.4759 17.4768 16.2131 18.9978 15.3359C20.5188 14.4586 22.2442 13.9979 24.0001 14V14Z"
          fill="url(#paint0_linear_8769_75081)"
        />
        <path
          d="M23.9999 31.9167C28.3722 31.9167 31.9166 28.3723 31.9166 24C31.9166 19.6278 28.3722 16.0834 23.9999 16.0834C19.6277 16.0834 16.0833 19.6278 16.0833 24C16.0833 28.3723 19.6277 31.9167 23.9999 31.9167Z"
          fill="#1A73E8"
        />
        <path
          d="M32.6595 29.0024L24.0007 44C27.5112 44.0005 30.96 43.0767 34.0003 41.3215C37.0406 39.5663 39.5652 37.0416 41.3203 34.0012C43.0754 30.9608 43.999 27.512 43.9984 24.0014C43.9978 20.4908 43.0729 17.0423 41.3167 14.0026H23.9992L23.9971 14.0103C25.7529 14.0069 27.4787 14.4665 29.0003 15.3427C30.5219 16.2189 31.7856 17.4808 32.6641 19.0011C33.5425 20.5215 34.0046 22.2465 34.0038 24.0024C34.003 25.7582 33.5393 27.4829 32.6595 29.0024V29.0024Z"
          fill="url(#paint1_linear_8769_75081)"
        />
        <path
          d="M15.3406 29.0026L6.68173 14.005C4.926 17.045 4.0016 20.4937 4.00146 24.0043C4.00133 27.5148 4.92548 30.9636 6.68099 34.0037C8.43649 37.0438 10.9615 39.5682 14.002 41.323C17.0426 43.0777 20.4916 44.001 24.0021 44L32.661 29.0024L32.6554 28.9968C31.7804 30.5191 30.5195 31.7838 28.9998 32.6634C27.4802 33.543 25.7555 34.0065 23.9997 34.0071C22.2438 34.0077 20.5188 33.5454 18.9986 32.6668C17.4784 31.7882 16.2166 30.5243 15.3406 29.0026V29.0026Z"
          fill="url(#paint2_linear_8769_75081)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_8769_75081" x1="6.68115" y1="16.5" x2="41.3177" y2="16.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D93025" />
          <stop offset="1" stopColor="#EA4335" />
        </linearGradient>
        <linearGradient id="paint1_linear_8769_75081" x1="21.2683" y1="43.7326" x2="38.5867" y2="13.7364" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCC934" />
          <stop offset="1" stopColor="#FBBC04" />
        </linearGradient>
        <linearGradient id="paint2_linear_8769_75081" x1="26.1651" y1="42.7513" x2="8.84681" y2="12.755" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1E8E3E" />
          <stop offset="1" stopColor="#34A853" />
        </linearGradient>
        <clipPath id="clip0_8769_75081">
          <rect width="40" height="40" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
