import React from 'react';
export default function ScanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={16}
        height={20}
      >
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#6877DA"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M.5 1.667C.5.747 1.246 0 2.167 0h6.81c.441 0 .865.176 1.178.488l4.857 4.857c.312.313.488.737.488 1.179v11.81c0 .92-.746 1.666-1.667 1.666H2.167C1.247 20 .5 19.254.5 18.333V1.667z"
          fill="#5856D6"
        />
        <path opacity={0.24} d="M9.667 0L15.5 5.833h-4.167c-.92 0-1.666-.746-1.666-1.666V0z" fill="#fff" />
      </g>
      <g clipPath="url(#clip0_13431_10478)">
        <path
          d="M4.25 10a.418.418 0 01-.417-.417v-1.25c0-.229.188-.417.417-.417H5.5c.229 0 .417.188.417.417 0 .23-.188.417-.417.417h-.833v.833c0 .23-.188.417-.417.417zm5.833-1.667c0 .23.188.417.417.417h.833v.833c0 .23.188.417.417.417.229 0 .416-.188.416-.417v-1.25a.418.418 0 00-.416-.417H10.5a.418.418 0 00-.417.417zM4.25 15a.418.418 0 00-.417.416v1.25c0 .23.188.417.417.417H5.5c.229 0 .417-.187.417-.416a.418.418 0 00-.417-.417h-.833v-.834A.418.418 0 004.25 15zm5.833 1.666c0 .23.188.417.417.417h1.25c.229 0 .416-.187.416-.416v-1.25A.418.418 0 0011.75 15a.418.418 0 00-.417.416v.834H10.5a.418.418 0 00-.417.416zM10.916 15a.836.836 0 01-.833.833H5.917A.836.836 0 015.083 15v-5c0-.459.375-.834.834-.834h4.166c.458 0 .833.375.833.834v5zM6.75 11.25c0 .229.187.416.417.416h1.666c.23 0 .417-.187.417-.416a.418.418 0 00-.417-.417H7.167a.418.418 0 00-.417.417zm0 1.25c0 .229.187.416.417.416h1.666c.23 0 .417-.187.417-.416a.418.418 0 00-.417-.417H7.167a.418.418 0 00-.417.417zm0 1.25c0 .229.187.416.417.416h1.666c.23 0 .417-.187.417-.416a.418.418 0 00-.417-.417H7.167a.418.418 0 00-.417.417z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_13431_10478">
          <path fill="#fff" transform="translate(3 7.5)" d="M0 0H10V10H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
