import { ViewType } from 'interfaces';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'store';

import EmptyFolderIcon from 'assets/icons/empty';
import NoResultsIcon from 'assets/icons/no-results';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IIntegrationFolder, IntegrationService } from 'interfaces/integrations';

import { Breadcrumbs } from './elements/Breadcrumbs';
import { FileTypeFilter } from './elements/filters/FileTypeFilter';
import { SortFilter } from './elements/filters/SortFilter';
import { ViewTypeFilter } from './elements/filters/ViewTypeFilter';
import { InifiniteLoader } from './elements/InifiniteLoader';
import { ItemsGrid } from './elements/ItemsGrid';
import { ItemsList, ItemsListHeader } from './elements/ItemsList';
import { Search } from './elements/Search';
import { useItems } from './hooks/useItems';

interface IntegrationViewProps {
  service: IntegrationService;
}

export function IntegrationView({ service }: IntegrationViewProps) {
  const { t } = useTranslation('common');
  const viewType = useSelector(state => state.library.viewType) ?? ViewType.GRID;

  const [folderId, setFolderId] = useState<string | null>(null);
  const [foldersPath, setFoldersPath] = useState<string[]>([]);
  const [search, setSearch] = useState<string | undefined>();

  const { items, folders, isLoading, loadItems, hasMore } = useItems(service, folderId, search);
  const [otherFolders, setOtherFolders] = useState<IIntegrationFolder[]>([]);
  const allLoadedFolders = useMemo(() => uniqBy([...folders, ...otherFolders], 'id'), [folders, otherFolders]);

  const isEmpty = !isLoading && !items.length;

  const handleFolderClick = useCallback(
    (folderId: string | null) => {
      const folder = folderId && folders.find(f => f.id === folderId);

      if (folder) {
        setOtherFolders(otherFolders => uniqBy([folder, ...otherFolders], 'id'));
      }

      setFolderId(folderId);
      setFoldersPath(path => {
        if (!folderId) return [];

        const folderIndex = path.indexOf(folderId);
        return folderIndex === -1 ? [...path, folderId] : path.slice(0, folderIndex + 1);
      });
      setSearch(undefined);
    },
    [folders]
  );

  const ListComponent = viewType === ViewType.GRID ? ItemsGrid : ItemsList;

  return (
    <div className="flex flex-col min-h-0 flex-1">
      <div className="flex justify-between pt-5 pb-2 mx-5 items-center">
        <Search value={search} onChange={setSearch} disabledText={t('Search is coming in next few weeks')} />
        <div className="flex gap-3 items-center">
          <FileTypeFilter />
          <SortFilter />
          <ViewTypeFilter />
        </div>
      </div>
      <div className="py-3 mx-5">
        <Breadcrumbs service={service} path={search ? [] : foldersPath} folders={allLoadedFolders} onClick={handleFolderClick} />
      </div>
      {viewType === ViewType.LIST && (
        <div className="px-5">
          <ItemsListHeader />
        </div>
      )}
      {(!isEmpty || hasMore) && (
        <InifiniteLoader className="w-full flex-1 min-h-0 overflow-y-auto px-5 pb-4" hasMore={hasMore} isLoading={isLoading} onLoadMore={loadItems}>
          {({ width, height }) => (
            <ListComponent
              service={service}
              isLoading={isLoading}
              items={items}
              onFolderChange={handleFolderClick}
              viewportHeight={height}
              viewportWidth={width}
            />
          )}
        </InifiniteLoader>
      )}
      {isEmpty && !hasMore && !!search && (
        <div className="flex flex-col gap-4 py-16 items-center min-h-0 overflow-y-auto my-auto">
          <NoResultsIcon className="w-40 h-40 shrink-0" />
          <p className="text-glass-500 text-center text-base">
            {t('No results found.')}
            <br />
            {t('Try different keywords or check your spelling.')}
          </p>
        </div>
      )}
      {isEmpty && !hasMore && !search && (
        <div className="flex flex-col gap-4 py-16 items-center min-h-0 overflow-y-auto my-auto">
          <EmptyFolderIcon className="w-40 h-40 shrink-0" />
          <p className="text-glass-500">{t('This folder is empty')}</p>
        </div>
      )}
    </div>
  );
}
