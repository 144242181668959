import React from 'react';
export default function Brave(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M41 13L42 17L37.91 33.35C37.32 35.7 35.9 37.76 33.9 39.14L25.71 44.82C25.2 45.18 24.6 45.35 24 45.35C23.4 45.35 22.8 45.18 22.29 44.82L14.1 39.14C12.1 37.76 10.68 35.7 10.09 33.35L6 17L7 13L6 11L9.25 7.75C10.3 6.7 11.85 6.31 13.27 6.76C13.31 6.77 13.34 6.78 13.37 6.79L14 7L18 3H30L34 7L34.65 6.78C35.48 6.5 36.35 6.51 37.15 6.78C37.73 6.97 38.28 7.29 38.73 7.73C38.74 7.74 38.74 7.74 38.75 7.75L42 11L41 13Z"
        fill="#FF651F"
      />
      <path
        d="M38.7299 7.73L32.9999 11L23.9999 13L14.9999 10L12.9299 7.93C12.3699 7.37 11.5199 7.19 10.7799 7.49L8.66992 8.33L9.24992 7.75C10.2999 6.7 11.8499 6.31 13.2699 6.76C13.3099 6.77 13.3399 6.78 13.3699 6.79L13.9999 7L17.9999 3H29.9999L33.9999 7L34.6499 6.78C35.4799 6.5 36.3499 6.51 37.1499 6.78C37.7299 6.97 38.2799 7.29 38.7299 7.73Z"
        fill="#F4592B"
      />
      <path
        d="M32.51 23.49C32.21 23.79 32.13 24.26 32.32 24.64L32.66 25.32C32.88 25.77 33 26.26 33 26.76C33 27.56 32.71 28.33 32.17 28.92L31.51 29.66C31.19 29.87 30.79 29.89 30.47 29.71L25.24 26.83C24.65 26.43 24.64 25.56 25.23 25.17L29.14 22.51C29.62 22.23 29.77 21.62 29.49 21.14L27.59 17.98C27.28 17.46 27.45 17.24 28 17L34 14H29L26 14.75C25.45 14.89 25.13 15.45 25.28 15.99L26.74 21.08C26.88 21.59 26.6 22.13 26.09 22.3L24.62 22.79C24.41 22.86 24.21 22.9 24 22.9C23.79 22.9 23.58 22.86 23.37 22.79L21.91 22.3C21.4 22.13 21.12 21.59 21.26 21.08L22.72 15.99C22.87 15.45 22.55 14.89 22 14.75L19 14H14L20 17C20.55 17.24 20.72 17.46 20.41 17.98L18.51 21.14C18.23 21.62 18.38 22.23 18.86 22.51L22.77 25.17C23.36 25.56 23.35 26.43 22.76 26.83L17.53 29.71C17.21 29.89 16.81 29.87 16.49 29.66L15.83 28.92C15.29 28.33 15 27.56 15 26.76C15 26.26 15.12 25.77 15.34 25.32L15.68 24.64C15.87 24.26 15.79 23.79 15.49 23.49L11.4 18.66C10.57 17.67 10.46 16.25 11.14 15.15L14.54 9.61005C14.81 9.25005 15.29 9.12005 15.71 9.28005L18.33 10.33C18.81 10.52 19.32 10.62 19.82 10.62C20.43 10.62 21.05 10.48 21.61 10.2C22.36 9.82005 23.18 9.63005 24 9.63005C24.82 9.63005 25.64 9.82005 26.39 10.2C27.42 10.71 28.61 10.76 29.67 10.33L32.29 9.28005C32.71 9.12005 33.19 9.25005 33.46 9.61005L36.86 15.15C37.54 16.25 37.43 17.67 36.6 18.66L32.51 23.49Z"
        fill="white"
      />
      <path
        d="M29.5101 32.4901L24.7101 36.2901C24.5201 36.4801 24.2601 36.5801 24.0001 36.5801C23.7401 36.5801 23.4801 36.4801 23.2901 36.2901L18.4901 32.4901C18.2501 32.2501 18.3201 31.8401 18.6201 31.6901L23.5501 29.2201C23.6901 29.1501 23.8401 29.1201 24.0001 29.1201C24.1601 29.1201 24.3101 29.1501 24.4501 29.2201L29.3801 31.6901C29.6801 31.8401 29.7501 32.2501 29.5101 32.4901Z"
        fill="white"
      />
      <path
        d="M41 13L42 17L37.91 33.35C37.32 35.7 35.9 37.76 33.9 39.14L25.71 44.82C25.2 45.18 24.6 45.35 24 45.35V10.36L25 12H32V10L37.15 6.78003C37.74 6.97003 38.3 7.30003 38.75 7.75003L42 11L41 13Z"
        fill="#ED4D01"
      />
      <path
        d="M32.51 23.49C32.21 23.79 32.13 24.26 32.32 24.64L32.66 25.32C32.88 25.77 33 26.26 33 26.76C33 27.56 32.71 28.33 32.17 28.92L31.51 29.66C31.19 29.87 30.79 29.89 30.47 29.71L25.24 26.83C24.65 26.43 24.64 25.56 25.23 25.17L29.14 22.51C29.62 22.23 29.77 21.62 29.49 21.14L27.59 17.98C27.28 17.46 27.45 17.24 28 17L34 14H29L26 14.75C25.45 14.89 25.13 15.45 25.28 15.99L26.74 21.08C26.88 21.59 26.6 22.13 26.09 22.3L24.62 22.79C24.41 22.86 24.21 22.9 24 22.9V9.63005C24.82 9.63005 25.64 9.82005 26.39 10.2C27.42 10.71 28.61 10.76 29.67 10.33L32.29 9.28005C32.71 9.12005 33.19 9.25005 33.46 9.61005L36.86 15.15C37.54 16.25 37.43 17.67 36.6 18.66L32.51 23.49Z"
        fill="#F5F5F5"
      />
      <path
        d="M29.51 32.4901L24.71 36.2901C24.52 36.4801 24.26 36.5801 24 36.5801V29.1201C24.16 29.1201 24.31 29.1501 24.45 29.2201L29.38 31.6901C29.68 31.8401 29.75 32.2501 29.51 32.4901Z"
        fill="#F5F5F5"
      />
    </svg>
  );
}
